import { Card } from "components/ui/card";
import React from "react";
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import BusinessSuiteSetting from "./business-suite-left-side-content/BusinessSuiteSetting";
export default function BusinessSuiteContentLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="flex">
      <div className="sticky top-[80px] left-0 h-[calc(100vh-80px)] z-0">
        <BusinessSuiteSetting />
      </div>
      <div className="flex justify-center w-full p-4 bg-gray-100 z-0">
        <ScrollArea className="">
          <Card className="w-[400px] md:w-[600px] lg:w-[600px] min-h-[calc(100vh-120px)]">
            {children}
          </Card>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </div>
    </div>
  );
}
