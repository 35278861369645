import { createSlice } from "@reduxjs/toolkit";

interface BusinessSuiteSlice {
  userShops: any;
  userShopProfile: any;
  isSaveSetting: boolean;
}

const initialState: BusinessSuiteSlice = {
  userShops: null,
  userShopProfile: null,
  isSaveSetting: false,
};

const businessSuiteSlice = createSlice({
  name: "businessSuite",
  initialState,
  reducers: {
    setUserShops(state, action) {
      state.userShops = action.payload;
    },
    setUserShopProfile(state, action) {
      state.userShopProfile = action.payload;
    },
    setIsSaveSetting(state, action) {
      state.isSaveSetting = action.payload;
    },
  },
});

export const { setUserShops, setUserShopProfile, setIsSaveSetting } =
  businessSuiteSlice.actions;

const businessSuiteReducer = businessSuiteSlice.reducer;
export default businessSuiteReducer;
