import { ChevronDown, ChevronUp, ZoomInIcon, ZoomOutIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Service } from "utils/services";

export default function PhotoPostContent({
  post,
  isAlbum,
  scaleAlbum,
}: {
  post: any;
  isAlbum?: boolean;
  scaleAlbum?: number;
}) {
  const [isPortrait, setIsPortrait] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>();
  const [alt, setAlt] = useState<string>();
  const [scale, setScale] = useState(1);
  const maxZoom = 1.8;
  const minZoom = 1;

  const handleZoomIn = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    if (scale < maxZoom) {
      setScale((prev) => Math.min(prev + 0.1, maxZoom));
    }
  };

  const handleZoomOut = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    if (scale > minZoom) {
      setScale((prev) => Math.max(prev - 0.1, minZoom));
    }
  };

  useEffect(() => {
    setImageSrc(post?.attachment_media_image_file_src ?? "null");
    setAlt(post?.attachment_thumbnail_image_file_src ?? "null");
    let width = post?.attachment_media_width ?? 16;
    let height = post?.attachment_media_height ?? 9;

    if (height > width) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }
    if (imageSrc != "" && imageSrc != "null") {
      if (Service.applicationMode.isDebug()) {
        setImageSrc(
          post?.attachment_media_image_file_src.replace("https", "http")
        );
      }
    }
    if (alt != "" && alt != "null") {
      if (Service.applicationMode.isDebug()) {
        setAlt(
          post?.attachment_thumbnail_image_file_src.replace("https", "http")
        );
      }
    }
  }, [post]);
  return (
    <>
      <div className="flex justify-center items-center h-[60vh] lg:h-screen">
        <img
          src={imageSrc}
          alt={alt}
          style={{
            transform: `scale(${scaleAlbum ?? scale}) `,
          }}
          className={`${
            isPortrait
              ? ` lg:max-w-[${post?.attachment_media_width}px] object-cover lg:h-screen h-[60vh]`
              : "  object-cover rounded-md lg:h-[60vh] "
          }`}
        />
      </div>

      {isAlbum ? (
        <></>
      ) : (
        <>
          <div className="absolute max-sm:top-3 max-sm:right-3 top-5 right-8 flex space-x-2  z-50 select-none">
            <ZoomInIcon
              onClick={handleZoomIn}
              className={`w-8 max-sm:w-6 max-sm:h-6 h-8 bg-gray-600 text-white opacity-70 rounded-full p-1 transition-opacity duration-200 cursor-pointer ${
                scale >= maxZoom
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:opacity-100"
              }`}
            />
            <ZoomOutIcon
              onClick={handleZoomOut}
              className={`w-8 h-8 max-sm:w-6 max-sm:h-6 bg-gray-600 text-white opacity-70 rounded-full p-1 transition-opacity duration-200 cursor-pointer ${
                scale <= minZoom
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:opacity-100 cursor-pointer"
              }`}
            />
          </div>
        </>
      )}
    </>
  );
}
