import { zodResolver } from "@hookform/resolvers/zod";
import { singup } from "api-function/signupApi";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { Eye, EyeOff, KeyRound, Loader, Phone, User } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AppLog } from "services";
import { requestSubmitUpdateOneSignal } from "utils/oneSignalService";
import { z } from "zod";

interface SubmitProps {
  onSubmit: (value: any) => void;
}
export default function FormSigUp({ onSubmit }: SubmitProps) {
  const [showPassword, setShowPassword] = useState("password");

  const [errorState, setErrorState] = useState<string>("");
  const [loading, setLoading] = useState<boolean>();

  const toggleShowPassword = () => {
    if (showPassword === "text") {
      setShowPassword("password");
    } else {
      setShowPassword("text");
    }
  };
  const FormSchema = z.object({
    name: z.string().min(1, {
      message: "Name number is required*",
    }),
    phone: z.string().min(1, {
      message: "Phone number is required*",
    }),
    password: z.string().min(1, {
      message: "Password is required*",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      phone: "",
      password: "",
    },
  });
  const onSigUp = async (data: z.infer<typeof FormSchema>) => {
    try {
      setLoading(true);
      const dataForm = {
        phone_no: data.phone,
        password: data.password,
        full_name: data.name,
        app_key: "LyCqMkT2uxL1JAy9UofOmhCnBeJCEblWYOrfHWCL",
        country_code: "+855",
        signature: "",
      };

      await singup({ data: dataForm }).then((result) => {
        if (result.status === "success") {
          AppLog({ message: `succes ${JSON.stringify(result)}` });
          requestSubmitUpdateOneSignal(result["verification"]["token"]);
          onSubmit(result);
        } else {
          console.log(`error ${result.msg}`);
          setErrorState(result.msg);
        }
        setLoading(false);
      });
    } catch (err) {
      AppLog({ message: `onSignup ${err}` });
    }
  };
  return (
    <Form {...form}>
      <form
        className="text-start space-y-4"
        onSubmit={form.handleSubmit(onSigUp)}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  <div
                    className={`absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none`}
                  >
                    <User />
                  </div>
                  <Input
                    type="text"
                    className="rounded-xl pl-12 text-black"
                    placeholder="Name"
                    {...field}
                  />
                </div>
              </FormControl>
              <div className="text-red-600">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  <div
                    className={`absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none`}
                  >
                    <Phone />
                  </div>
                  <Input
                    type="text"
                    className="rounded-xl pl-12 text-black"
                    placeholder="Phone No."
                    {...field}
                  />
                </div>
              </FormControl>
              <div className="text-red-600">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem className="">
              <FormControl>
                <div className="relative">
                  <div
                    className={`absolute left-0 top-2 start-0 ps-3.5 pointer-events-none`}
                  >
                    <KeyRound />
                  </div>
                  <Input
                    type={`${showPassword}`}
                    className="rounded-xl pl-12 text-black"
                    placeholder="Password"
                    {...field}
                  />
                  {showPassword === "password" ? (
                    <div className="flex">
                      <div onClick={() => toggleShowPassword()}>
                        <Eye
                          className={`absolute right-3 top-2 cursor-pointer`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex">
                      <div onClick={() => toggleShowPassword()}>
                        <EyeOff
                          className={`absolute right-3 top-2 cursor-pointer `}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormControl>
              <div className="text-red-600">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />{" "}
        <div className="text-red-600">{errorState}</div>
        <div className="w-full flex flex-col">
          <Button className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white">
            {loading ? <Loader className="animate-spin" /> : "Register"}
          </Button>
        </div>
      </form>
    </Form>
  );
}
