const MarketPlaceIcon = ({
  color = "currentColor",
  width = "24px",
  height = "24px",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={width}
      height={height}
      viewBox="0 0 92.000000 92.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,92.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M145 805 c-22 -21 -25 -33 -25 -95 0 -70 -1 -73 -46 -132 -41 -53 -45 -63 -36 -84 13 -27 57 -42 81 -27 9 5 34 47 55 91 l40 82 44 0 45 0 -8 -32 c-18 -85 -18 -108 0 -128 23 -26 60 -25 85 0 16 16 20 33 20 90 l0 70 60 0 60 0 0 -70 c0 -57 4 -74 20 -90 25 -25 62 -26 85 0 18 20 18 43 0 128 l-7 32 44 0 44 0 40 -82 c21 -44 46 -86 55 -91 24 -15 68 0 81 27 9 21 5 31 -36 84 -45 59 -46 62 -46 132 0 62 -3 74 -25 95 l-24 25 -291 0 -291 0 -24 -25z m95 -71 c0 -19 -5 -24 -24 -24 -29 0 -40 24 -20 44 20 20 44 9 44 -20z m120 21 c18 -21 5 -47 -22 -43 -26 3 -37 36 -16 49 18 12 24 11 38 -6z" />
        <path d="M120 285 c0 -126 9 -159 51 -181 41 -21 537 -21 578 0 42 22 51 55 51 181 l0 115 -30 0 -30 0 0 -109 c0 -86 -3 -113 -16 -125 -23 -24 -505 -24 -528 0 -13 12 -16 39 -16 125 l0 109 -30 0 -30 0 0 -115z" />
      </g>
    </svg>
  );
};

export default MarketPlaceIcon;
