import Layout from "layout/layout";
import DownLoadApp from "pages/download/DownLoadApp";
import WelcomePage from "pages/welcome/welcome-page/WelcomePage";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export default function NewsFeed() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  return (
    <>
      <Layout>{isAuthenticated ? <DownLoadApp /> : <WelcomePage />}</Layout>
    </>
  );
}
