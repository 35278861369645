import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import InputHideShow from "../../components/app/function/InputHideShow";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Button } from "components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import { useAppSelector } from "store/store";
import { useState } from "react";
import { Loader } from "lucide-react";
import { StatusMsg } from "models/returnMsg";
import { AppLog } from "services";
import { Snackbar } from "components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { useNavigate } from "react-router-dom";

export default function DeleteAccount() {
  const { token } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const FormSchema = z.object({
    password: z.string().min(1, {
      message: "Current password is required*",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsOpen(false);
    setLoading(true);
    try {
      let url: string = "";
      url = RouteApi.deleteAccount;
      url += `token=${token}`;

      const dataFormValue = {
        password: data.password,
      };

      await RequestApi(url, { data: dataFormValue })
        .then((responses) => {
          if (responses.status === "success") {
            navigate(RouteUrl.deletePageStatus);
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });
      setLoading(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
    }
  };

  const handleCheck = () => {
    setIsOpen(true);
  };

  return (
    <Layout>
      <SettingContentLayout>
        <div className="h-[calc(100vh-160px)]">
          <h1 className="text-lg font-semibold">Delete Account</h1>
          <p className="text-muted-foreground text-sm">
            You are deleting account, you have only 30days to recover your
            account back, otherwise, your data will be deleted!
          </p>
          <div className="grid grid-cols-1 space-y-2 mt-16">
            <Form {...form}>
              <form className="text-start space-y-4">
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputHideShow placeholder="Password" {...field} />
                      </FormControl>
                      <div className="text-red-600">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />

                <div className="w-full flex flex-col">
                  <AlertDialog open={isOpen}>
                    <AlertDialogTrigger asChild>
                      <Button
                        onClick={form.handleSubmit(handleCheck)}
                        className="bg-blue-600 hover:bg-blue-500 rounded-xl text-white"
                      >
                        Continue
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          Are you absolutely sure to delete your account?
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                          You are deleting account, you have only 30days to
                          recover your account back, otherwise, your data will
                          be deleted!
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => setIsOpen(false)}>
                          Cancel
                        </AlertDialogCancel>
                        <Button
                          className="text-white  bg-blue-600 hover:bg-blue-500"
                          onClick={form.handleSubmit(onSubmit)}
                        >
                          Continue
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </SettingContentLayout>
    </Layout>
  );
}
