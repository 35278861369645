import { profileVerified } from "assets";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "components/app/dialog/dialog";
import ImageUrl from "components/app/image-url/ImageUrl";
import SearchBar from "components/app/search-bar/SearchBar";
import { Button } from "components/ui/button";
import { DialogTitle } from "components/ui/dialog";
import { Label } from "components/ui/label";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import { Separator } from "components/ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "components/ui/sheet";
import { Skeleton } from "components/ui/skeleton";
import { Settings } from "lucide-react";
import { StatusMsg } from "models/returnMsg";
import SettingShopDetail from "pages/business-suite/setting-shop-detail/SettingShopDetail";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppLog } from "services";
import { setIsSaveSetting, setUserShops } from "store/businessSuiteSlice";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi, RouteUrl } from "utils";

export default function BusinessSuiteSetting() {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shopId = searchParams.get("id");
  const navigate = useNavigate();
  const [loadingShops, setLoadingShops] = useState<boolean>(false);
  const { token } = useAppSelector((state) => state.auth);
  const [selectedShop, setSelectedShop] = useState<any>();
  const [searchTextShop, setSearchTextShop] = useState<string>("");
  const [userShopSelectedProfile, setUserShopSelectedProfile] =
    useState<any>(null);
  const { userShops } = useAppSelector((state) => state.businessSuite);
  const { userShopProfile } = useAppSelector((state) => state.businessSuite);

  let filteredShops = userShops?.filter((user: any) =>
    user.shop_name
      .toString()
      .toLowerCase()
      .includes(searchTextShop.toLowerCase())
  );

  const navLink = [
    {
      name: "Home",
      url: RouteUrl.businessSuite,
      icon: <i className="fa-solid fa-house fa-lg" />,
    },
  ];

  function handleClickNavLink(link: string) {
    if (location.pathname !== link) {
      navigate(link);
    }
  }

  const fetchGetUserShopProfile = async () => {
    try {
      setLoadingShops(true);
      let url: string = "";
      url = RouteApi.getShopUser;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            dispatch(setUserShops(responses["user_shops"]));
          }
          setLoadingShops(false);
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });

      setLoadingShops(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoadingShops(false);
    }
  };

  useEffect(() => {
    fetchGetUserShopProfile();
  }, []);

  // update userProfile when user change profile
  let updateProfile;

  useEffect(() => {
    updateProfile = userShopProfile;
    setUserShopSelectedProfile(updateProfile);
  }, [userShopProfile]);

  useEffect(() => {
    if (!shopId && userShops) {
      navigate(`${RouteUrl.businessSuite}?id=${userShops[0].shop_id}`);
    }
  }, [shopId, userShops]);

  useEffect(() => {
    if (userShops && shopId) {
      const filteredProfile = userShops.find(
        (element: any) => element.shop_id === shopId
      );
      setUserShopSelectedProfile(filteredProfile);
    }
  }, [userShops, shopId]);

  useEffect(() => {
    if (userShopSelectedProfile) {
      if (shopId !== userShopSelectedProfile.shop_id) {
        navigate(
          `${RouteUrl.businessSuite}?id=${userShopSelectedProfile.shop_id}`
        );
      }
    }
  }, [shopId, userShops, userShopSelectedProfile, navigate]);

  return (
    <div className="w-[300px] lg:w-[400px]">
      <div className="p-4 space-y-2 flex flex-col justify-between h-[calc(100vh-80px)]">
        <ScrollArea className="h-full">
          {loadingShops ? (
            <>
              <Skeleton className="w-full h-[60px] rounded-xl" />
            </>
          ) : (
            <>
              {userShops && userShopSelectedProfile ? (
                <>
                  {/* Shop Profile Asset */}
                  <Dialog>
                    <DialogTrigger
                      onClick={() => setSelectedShop(shopId)}
                      className="flex w-full"
                    >
                      <div className="flex items-center w-full border p-2 rounded-xl">
                        <ImageUrl
                          src={userShopSelectedProfile.picture}
                          className="w-[40px] h-[40px] rounded-full"
                          alt=""
                        />
                        <div className="flex flex-col items-start justify-start ms-2">
                          <h1 className="font-semibold line-clamp-1 text-start">
                            {userShopSelectedProfile.shop_name}
                          </h1>
                          <p className="text-sm text-muted-foreground line-clamp-1 text-start">
                            {userShopSelectedProfile.description}
                          </p>
                        </div>
                      </div>
                    </DialogTrigger>
                    <DialogContent className="" btnClose="">
                      <DialogTitle className="text-center font-semibold">
                        Shop Profile
                      </DialogTitle>
                      <Separator className="" />
                      <div>
                        <SearchBar
                          value={searchTextShop}
                          onValueChange={(value) => setSearchTextShop(value)}
                        />
                      </div>
                      <ScrollArea className="h-96">
                        <RadioGroup defaultValue={userShops[0].shop_id}>
                          {filteredShops?.map((user: any, index: number) => {
                            return (
                              <DialogClose asChild key={user.shop_id}>
                                <Label
                                  htmlFor={`${index}`}
                                  onClick={() => {
                                    setSelectedShop(user.shop_id);
                                    setUserShopSelectedProfile(user);
                                  }}
                                  className="flex justify-between items-center p-2 border me-4 rounded-xl hover:bg-gray-100 cursor-pointer"
                                >
                                  <div className="flex items-center gap-1">
                                    <ImageUrl
                                      className="rounded-full object-cover border"
                                      src={user.picture}
                                      style={{ width: 40, height: 40 }}
                                      alt="user"
                                    />
                                    <div className="flex flex-col items-start justify-center ms-2 space-y-1">
                                      <div className="flex">
                                        <h1 className="font-semibold line-clamp-1 text-start">
                                          {user?.shop_name}
                                        </h1>
                                        {user?.is_verified === "Yes" ? (
                                          <>
                                            <div className="flex items-center justify-center pt-0.5 ml-1">
                                              <img
                                                src={profileVerified}
                                                style={{
                                                  width: 12,
                                                  height: 12,
                                                }}
                                                alt=""
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <p className="text-gray-600 font-normal line-clamp-1 text-start">
                                        {user?.description}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <RadioGroupItem
                                      checked={user.shop_id === selectedShop}
                                      value={selectedShop}
                                      id={`${index}`}
                                    />
                                  </div>
                                </Label>
                              </DialogClose>
                            );
                          })}
                        </RadioGroup>
                      </ScrollArea>
                    </DialogContent>
                  </Dialog>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <h1 className="font-semibold text-lg">Business Suite</h1>
          {loadingShops ? (
            <>
              <Skeleton className="w-full h-[40px] rounded-xl" />
            </>
          ) : (
            <>
              {navLink.map((link, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => handleClickNavLink(link.url)}
                    className={`p-2 rounded-xl cursor-pointer w-full ${
                      location.pathname === link.url
                        ? "bg-slate-500 hover:bg-slate-400 text-white"
                        : "hover:bg-slate-100"
                    }`}
                  >
                    <ul>
                      <li className="flex items-center space-x-2">
                        {link.icon}
                        <p className="text-md">{link.name}</p>
                      </li>
                    </ul>
                  </button>
                );
              })}
            </>
          )}
          <ScrollBar orientation="vertical" />
        </ScrollArea>
        <div>
          <Sheet>
            <SheetTrigger asChild>
              <button
                onClick={() => dispatch(setIsSaveSetting(false))}
                className={`p-2 rounded-xl cursor-pointer w-full hover:bg-slate-100`}
              >
                <ul>
                  <li className="flex items-center space-x-2">
                    <Settings />
                    <p className="text-md">Setting</p>
                  </li>
                </ul>
              </button>
            </SheetTrigger>
            <SheetContent className="flex flex-col h-full justify-between p-3">
              <SheetHeader>
                <SheetTitle>Setting</SheetTitle>
                <SheetDescription>
                  Make changes to your setting here. Click save when you're
                  done.
                </SheetDescription>
              </SheetHeader>
              <ScrollArea className="h-full">
                <SettingShopDetail shopId={shopId} />
                <ScrollBar orientation="vertical" />
              </ScrollArea>
              <SheetFooter>
                <SheetClose asChild>
                  <Button
                    type="submit"
                    onClick={() => dispatch(setIsSaveSetting(true))}
                  >
                    Save
                  </Button>
                </SheetClose>
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </div>
  );
}
