// src/store/slices/counterSlice.ts

import { AppRecord, AppRecordStatus } from "models/appRecord";
import { createSlice } from "@reduxjs/toolkit";
// import { AppRecord, AppRecordStatus } from "../../../models/appRecord";

interface ProfileSlice {
  postsState: AppRecord;
  sharesState: AppRecord;
  lovesState: AppRecord;
  likesState: AppRecord;
  followerUserState: AppRecord;
  followingUserState: AppRecord;
  friendUserState: AppRecord;
  user: any;
  posts: any;
  loves: any;
  likes: any;
  shares: any;
  friendUsers: any;
  followerUsers: any;
  followingUsers: any;
  userCoverPictures: any[];
}

const initialState: ProfileSlice = {
  postsState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },

  sharesState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },

  lovesState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },

  likesState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },

  // State for SubNavTapContent

  friendUserState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },

  followerUserState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },
  followingUserState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },

  user: {},
  friendUsers: null,
  followerUsers: null,
  followingUsers: null,
  posts: null,
  shares: null,
  loves: null,
  likes: null,
  userCoverPictures: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setPostLoading(state, action) {
      state.postsState = action.payload;
    },

    setSharesLoading(state, action) {
      state.sharesState = action.payload;
    },

    setLovesLoading(state, action) {
      state.lovesState = action.payload;
    },

    setLikesLoading(state, action) {
      state.likesState = action.payload;
    },

    setFriendLoading(state, action) {
      state.friendUserState = action.payload;
    },

    setFollowerLoading(state, action) {
      state.followerUserState = action.payload;
    },

    setFollowingLoading(state, action) {
      state.followingUserState = action.payload;
    },

    setUserCoverPictures(state, action) {
      state.userCoverPictures = action.payload;
    },
    setPosts(state, action) {
      state.posts = action.payload;
    },

    setShares(state, action) {
      state.shares = action.payload;
    },

    setLoves(state, action) {
      state.loves = action.payload;
    },

    setLikes(state, action) {
      state.likes = action.payload;
    },

    setUser(state, action) {
      state.user = action.payload;
    },

    // SubNavTapContent
    setFriendUsers(state, action) {
      state.friendUsers = action.payload;
    },
    setFollowerUsers(state, action) {
      state.followerUsers = action.payload;
    },
    setFollowingUsers(state, action) {
      state.followingUsers = action.payload;
    },
    clearState() {
      return initialState;
    },
  },
});

export const {
  setPostLoading,
  setSharesLoading,
  setLovesLoading,
  setLikesLoading,
  setFriendLoading,
  setFollowerLoading,
  setFollowingLoading,
  setFollowingUsers,
  setUserCoverPictures,
  setPosts,
  setUser,
  setFriendUsers,
  setFollowerUsers,
  setShares,
  setLoves,
  setLikes,
  clearState,
} = profileSlice.actions;

const profileReducer = profileSlice.reducer;
export default profileReducer;
