const NewsFeedIcon = ({
  color = "currentColor",
  width = "24px",
  height = "24px",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={width}
      height={height}
      viewBox="0 0 92.000000 92.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,92.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M176 806 c-60 -36 -86 -87 -86 -168 0 -66 1 -68 25 -68 23 0 25 4 25 48 0 56 24 108 63 136 27 20 42 21 256 21 327 0 316 11 316 -315 0 -199 -2 -229 -18 -253 -27 -41 -61 -58 -127 -64 -55 -5 -60 -8 -60 -29 0 -23 3 -24 67 -24 82 0 137 29 171 88 21 38 22 50 22 282 0 239 0 244 -24 284 -13 23 -42 51 -64 64 -38 21 -50 22 -283 22 -239 0 -245 -1 -283 -24z" />
        <path d="M161 506 c-17 -21 1 -46 33 -46 129 0 266 -137 266 -266 0 -12 7 -27 16 -34 12 -11 18 -10 31 3 14 14 14 24 4 73 -31 155 -137 255 -296 280 -31 4 -44 2 -54 -10z" />
        <path d="M144 356 c-11 -28 3 -43 45 -50 57 -9 100 -50 116 -111 10 -42 16 -50 36 -50 67 0 3 156 -81 200 -16 8 -46 17 -69 21 -32 5 -42 3 -47 -10z" />
        <path d="M136 199 c-30 -43 20 -93 63 -63 25 18 27 46 5 68 -22 22 -50 20 -68 -5z" />
      </g>
    </svg>
  );
};

export default NewsFeedIcon;
