import { ScrollArea, ScrollBar } from "components/ui/scroll-area";

import {
  ArrowRightLeft,
  Globe,
  KeyRound,
  MapPin,
  Music2,
  Settings,
  ShieldCheck,
  Smartphone,
  Trash2,
} from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteUrl } from "utils";

export default function SettingLeftSideContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const navLink = [
    { name: "Setting & Security", url: "/setting", icon: <Settings /> },
    {
      name: "Block Accounts",
      url: RouteUrl.blockAccount,
      icon: <i className="fa-solid fa-user-slash fa-lg" />,
    },
    {
      name: "Boring Accounts",
      url: RouteUrl.boringAccount,
      icon: <i className="fa-solid fa-user-minus fa-lg" />,
    },
    // { name: "Sound Libraries", url: RouteUrl.soundLibraries, icon: <Music2 /> },
    {
      name: "Interesting",
      url: RouteUrl.interesting,
      icon: <i className="fa-regular fa-rectangle-list fa-lg" />,
    },
    // {
    //   name: "Request Verificatoin Account",
    //   url: RouteUrl.requestVerificationAccount,
    //   icon: <ShieldCheck />,
    // },
    {
      name: "Change Password",
      url: RouteUrl.changePassword,
      icon: <KeyRound />,
    },
    // { name: "Ship To Address", url: "", icon: <MapPin /> },
    { name: "Delete Account", url: RouteUrl.deleteAccount, icon: <Trash2 /> },
    {
      name: "Desactivate Account",
      url: RouteUrl.desActivateAccount,
      icon: <i className="fa-solid fa-person-circle-minus fa-lg" />,
    },
    {
      name: "Switch Account",
      url: RouteUrl.switchAccount,
      icon: <ArrowRightLeft />,
    },
    {
      name: "Logged Devices",
      url: RouteUrl.loggedDevices,
      icon: <Smartphone />,
    },
    { name: "Language", url: "", icon: <Globe /> },
  ];

  function handleClickNavLink(link: string) {
    if (location.pathname !== link) {
      navigate(link);
    }
  }

  return (
    <ScrollArea className="w-[300px] lg:w-[400px] border-r-2 h-[calc(100vh-80px)]">
      <div className="p-4 space-y-2">
        <h1 className="font-semibold text-lg">Account</h1>
        {navLink.map((link, index) => {
          return (
            <button
              key={index}
              onClick={() => handleClickNavLink(link.url)}
              className={`p-2 rounded-xl cursor-pointer w-full ${
                location.pathname === link.url
                  ? "bg-slate-500 hover:bg-slate-400 text-white"
                  : "hover:bg-slate-100"
              }`}
            >
              <ul>
                <li className="flex items-center space-x-2">
                  {link.icon}
                  <p className="text-md">{link.name}</p>
                </li>
              </ul>
            </button>
          );
        })}
      </div>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
}
