import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import InputHideShow from "../../components/app/function/InputHideShow";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Button } from "components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import { useAppSelector } from "store/store";
import { useState } from "react";
import { Loader } from "lucide-react";
import { StatusMsg } from "models/returnMsg";
import { AppLog } from "services";
import { Snackbar } from "components";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
  const { token } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const FormSchema = z
    .object({
      currentPassword: z.string().min(1, {
        message: "Current password is required*",
      }),
      newPassword: z.string().min(1, {
        message: "New password is required*",
      }),
      retypeNewPassword: z.string().min(1, {
        message: "Re-type new password is required*",
      }),
    })
    .refine((data) => data.newPassword === data.retypeNewPassword, {
      message: "Re-type password do not match new password*",
      path: ["retypeNewPassword"], // Error will be assigned to `retypeNewPassword` field
    });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      retypeNewPassword: "",
    },
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setLoading(true);
    try {
      let url: string = "";
      url = RouteApi.submitChangePassword;
      url += `token=${token}`;

      const dataFormValue = {
        old_password: data.currentPassword,
        new_password: data.newPassword,
        confirm_password: data.retypeNewPassword,
      };

      await RequestApi(url, { data: dataFormValue })
        .then((responses) => {
          if (responses.status === "success") {
            navigate(RouteUrl.changePasswordPageStatus);
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });
      setLoading(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
    }
  };

  return (
    <Layout>
      <SettingContentLayout>
        <div className="">
          <h1 className="text-lg font-semibold">Change Password</h1>
          <p className="text-muted-foreground text-sm">
            Update your password to keep your account secure and protect your
            personal information.
          </p>
          <div className="grid grid-cols-1 space-y-2 mt-16">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="text-start space-y-4"
              >
                <FormField
                  control={form.control}
                  name="currentPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputHideShow
                          placeholder="Current password"
                          {...field}
                        />
                      </FormControl>
                      <div className="text-red-600">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="newPassword"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormControl>
                        <InputHideShow placeholder="New Password" {...field} />
                      </FormControl>
                      <div className="text-red-600">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="retypeNewPassword"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormControl>
                        <InputHideShow
                          placeholder="Re-type new password"
                          {...field}
                        />
                      </FormControl>
                      <div className="text-red-600">
                        <FormMessage />
                      </div>
                    </FormItem>
                  )}
                />

                <div className="w-full flex flex-col">
                  <Button className=" bg-blue-600 hover:bg-blue-500 rounded-xl text-white">
                    {loading ? <Loader className="animate-spin" /> : "Change"}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </SettingContentLayout>
    </Layout>
  );
}
