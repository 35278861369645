import OneSignal from "react-onesignal";
import { AppLog } from "services";
import { RequestApi } from "./requestApi";
import { RouteApi } from "./routeApi";

import { StatusMsg } from "models/returnMsg";

export const initOneSignal = async (): Promise<void> => {
  try {
    if (typeof window !== "undefined") {
      await OneSignal.init({
        appId: "8173e12f-15c3-4137-8dfe-6cc3c05413d5",

        notifyButton: {
          enable: true,
        },
      });
      await OneSignal.Notifications.requestPermission();
      await OneSignal.User.PushSubscription.optIn().then(() => {
        console.log("token", OneSignal.User.PushSubscription.token);
      });
      console.log(
        `OneSignal initialized ${OneSignal.User.PushSubscription.id}`
      );
    } else {
      console.log("Else initialized");
    }
  } catch (error) {
    console.log(`Error ${error}`);
  }
};
export const requestSubmitUpdateOneSignal = async (token?: string) => {
  try {
    var url = RouteApi.submitUpdateOneSignal;
    if (token === null) {
      const token = localStorage.getItem("jwt");
      url += `token=${token}`;
    } else {
      // call when  signup
      url += `token=${token}`;
    }
    const data = {
      one_signal_id: OneSignal.User.PushSubscription.id,
      one_signal_token: OneSignal.User.PushSubscription.token,
      location_data: "",
      location_latitude: "",
      location_longitude: "",
      ip_address: "",
    };
    const uid = OneSignal.User.PushSubscription.id;
    if (uid != null) {
      OneSignal.login(uid);
      OneSignal.User.addAlias("external_id", uid);
    }
    const result = await RequestApi(url, { data: data });
    if (result["status"] === "success") {
      AppLog({
        message: "success update oneSignal",
        status: StatusMsg.success,
      });
    }
    AppLog({
      message: "false update oneSignal",
      status: StatusMsg.warning,
    });
  } catch (error) {
    AppLog({ message: `Error update oneSignal ${error}` });
  }
};
