import HoverVideoPlayer from "react-hover-video-player";
import { cn } from "lib/utils";
import { formatSecondToHour } from "utils/formatTimeSecondToHour";
import { Badge } from "components/ui/badge";
import ImageUrlThumbnail from "../image-url/ImageUrlThumbnail";
import { useNavigate } from "react-router-dom";

export default function VideoHoverPlay({
  post,
  videoClassName,
}: {
  post: any;
  videoClassName?: string;
}) {
  const navigate = useNavigate();
  let attachment_media_height =
    post?.post_attachments[0]?.attachment_media_height;
  let attachment_media_width =
    post?.post_attachments[0]?.attachment_media_width;
  attachment_media_height = attachment_media_height ?? 16;
  attachment_media_width = attachment_media_width ?? 9;
  if (
    attachment_media_height < 360 &&
    attachment_media_width > attachment_media_height
  ) {
    attachment_media_height = 9;
    attachment_media_width = 16;
  }

  return (
    <HoverVideoPlayer
      preload="none"
      key={post?.post_attachments[0].id}
      hoverOverlayWrapperClassName="text-white font-sans absolute top-0 left-0 w-full h-full flex rounded-xl"
      style={{
        aspectRatio: `${attachment_media_width} / ${attachment_media_height}`,
        alignContent: "center",
      }}
      loop
      className={cn(
        `w-full h-full rounded-xl flex cursor-pointer`,
        videoClassName
      )}
      videoClassName="rounded-xl"
      videoSrc={post?.post_attachments[0].attachment_media_image_file_src}
      pausedOverlay={
        <div>
          <ImageUrlThumbnail
            thumbnail={
              post?.post_attachments[0]?.attachment_thumbnail_image_file_src
            }
            src={post?.post_attachments[0]?.attachment_media_picture_file_src}
            alt=""
            className={`absolute w-full h-full top-0 left-0 object-cover rounded-xl`}
            // style={{
            //   aspectRatio: `${
            //     post?.post_attachments[0]?.attachment_media_width ?? 9
            //   } / ${post?.post_attachments[0]?.attachment_media_height ?? 16}`,
            //   overflow: "hidden",
            // }}
          />
          <div className="absolute w-full h-full top-2 left-2">
            <Badge className="bg-gray-600 text-white opacity-70">
              {formatSecondToHour(post?.video_duration)}
            </Badge>
          </div>
        </div>
      }
    />
  );
}
