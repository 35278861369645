import { Link } from "react-router-dom";

type Links = {
  id: string;
  label: string;
  ref: React.RefObject<HTMLDivElement>;
};
interface DropdownProps {
  isOpen: boolean;
  links: Links[];

  closeDropDown: () => void;
  toggleDropdown: () => void; // Add this line
}
export default function DropDownMenuTermOfService({
  isOpen,
  links,
  toggleDropdown,
  closeDropDown,
}: DropdownProps) {
  return (
    <>
      <button
        type="button"
        className="inline-flex text-base item-center focus:outline-none"
        onClick={isOpen ? closeDropDown : toggleDropdown}
      >
        Select content here
        <svg
          className={`w-5 mt-1  ml-2 transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06 0L10 10.88l3.71-3.67a.75.75 0 111.06 1.06l-4.24 4.2a.75.75 0 01-1.06 0l-4.24-4.2a.75.75 0 010-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </>
  );
}
