import {
  setLoading,
  setSelectedProductGroupCode,
  setShopMenu,
  setShopMenu2,
  setUserShop,
} from "store/menuSlice";

import { RouteApi } from "utils";
import { LoaderCircle, X } from "lucide-react";
import { AppRecord, AppRecordStatus } from "models/appRecord";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/store";
import { RequestApi } from "utils";
import { Snackbar } from "components";
import { AppLog } from "services";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "components/app/dialog/dialog";
import { Card } from "components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import {
  Link,
  Navigate,
  redirect,
  useLocation,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import ImageUrl from "components/app/image-url/ImageUrl";
import {
  backgroundMenu,
  iconNoProduct,
  qrFlipFlopAndroid,
  qrFlipFlopIOS,
} from "assets";
import LoadingPage from "assets/icons/loading-page";
import SearchParamsNotFound from "components/app/search-params-not-found/SearchParamsNotFound";
import { type CarouselApi } from "components/ui/carousel";

const ShopMenuPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shopId = searchParams.get("id");
  const [displayGrid, setDisplayGrid] = useState<string>("grid-cols-2");
  const [showAlert, setShowAlert] = useState(true);
  const [time, setTime] = useState(10);
  const [searchShopID, setSearchShopID] = useState<boolean>(false);
  const userAgent = navigator.userAgent;
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  const {
    menuState,
    userShop,
    userShopProductGroups,
    selectedProductGroupCode,
  } = useAppSelector((state) => state.menu);

  const fetchShopMenu = async ({
    isRefresh = false,
    shopId = "",
  }: { isRefresh?: boolean; shopId?: string } = {}) => {
    try {
      let url: string = "";

      if (isRefresh) {
        url = RouteApi.getHomeMarketplaceQrMenu;
        url += `shop_id=${shopId}`;
      }

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            dispatch(setUserShop(responses["user_shop"]));
            const groups = [...responses["user_shop_product_groups"]];

            const updatedGroups = groups.map((group) => {
              const stateProductOfGroup: AppRecord = {
                currentPage: 1,
                currentIndex: 0,
                nextPageUrl: group["user_shop_products"]["next_page_url"] ?? "",
                totalRecord: 0,
                loadingStatus:
                  group["user_shop_products"]["next_page_url"] != null
                    ? AppRecordStatus.loaded
                    : AppRecordStatus.noLoadMore,
              };

              return { ...group, product_state: stateProductOfGroup };
            });
            dispatch(setShopMenu(updatedGroups));
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
            setSearchShopID(true);
          }
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });
      dispatch(
        setLoading({ ...menuState, loadingStatus: AppRecordStatus.loaded })
      );
    } catch (ex: any) {
      dispatch(
        setLoading({ ...menuState, loadingStatus: AppRecordStatus.loaded })
      );
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const fetchMoreProduct = async (group: any) => {
    try {
      let url: string = (group["product_state"] as AppRecord).nextPageUrl;
      if (url !== "") {
        await RequestApi(url)
          .then((responses) => {
            if (responses.status === "success") {
              const stateProductOfGroup: AppRecord = {
                currentPage: 1,
                currentIndex: 0,
                nextPageUrl:
                  responses["user_shop_product_groups"][0][
                    "user_shop_products"
                  ]["next_page_url"] ?? "",
                totalRecord: 0,
                loadingStatus:
                  responses["user_shop_product_groups"][0][
                    "user_shop_products"
                  ]["next_page_url"] != null
                    ? AppRecordStatus.loaded
                    : AppRecordStatus.noLoadMore,
              };

              const tmpProductGroups = userShopProductGroups.map((group) =>
                group.id === group["id"]
                  ? {
                      ...group,
                      user_shop_products: {
                        ...group.user_shop_products,
                        data: [
                          ...group.user_shop_products.data,
                          ...responses["user_shop_product_groups"][0][
                            "user_shop_products"
                          ]["data"],
                        ],
                      },
                      product_state: stateProductOfGroup,
                    }
                  : group
              );

              dispatch(setShopMenu2(tmpProductGroups));
            } else {
              Snackbar({
                message: responses["msg"],
                status: StatusMsg.getType(responses["status"]),
              });
            }
          })
          .catch((err) => {
            Snackbar({
              message: err.toString(),
              status: StatusMsg.failed,
            });
          });
      }
    } catch (ex: any) {
      dispatch(
        setLoading({ ...menuState, loadingStatus: AppRecordStatus.loaded })
      );
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    }
  };

  useEffect(() => {
    if (shopId) {
      fetchShopMenu({ isRefresh: true, shopId });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Set a timer to hide the alert after 10 seconds
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 10000); // 10000ms = 10s

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
    api.scrollTo(count, true);
  }, [api, count]);

  const handleClickModal = (index: number) => {
    setCount(index);
  };

  const qrDetecting = () => {
    // IOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl width={100} height={100} src={qrFlipFlopIOS} alt="logo" />
          </div>

          <Link to="https://apps.apple.com/us/app/flipflop-social-network/id6465576581">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
        </div>
      );
    }
    // ANDROID
    else if (/android/i.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl
              width={100}
              height={100}
              src={qrFlipFlopAndroid}
              alt="logo"
            />
          </div>
          <Link to="https://play.google.com/store/apps/details?id=com.maxx4business.flip_flop&hl=en">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
        </div>
      );
    }
    // Other Device
    else {
      return (
        <div className="mt-2 w-full">
          <div className="flex justify-evenly w-full mb-2">
            <div>
              <p className="font-semibold text-sm">App Store</p>
              <ImageUrl
                width={100}
                height={100}
                src={qrFlipFlopIOS}
                alt="ios-qr"
              />
            </div>
            <div>
              <p className="font-semibold text-sm">Google Play</p>
              <ImageUrl
                width={100}
                height={100}
                src={qrFlipFlopAndroid}
                alt="android-qr"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const productsContent = () => {
    if (!userShop) return <></>;

    const group = userShopProductGroups.find(
      (group) =>
        group["product_group_id"].toString() === selectedProductGroupCode
    );

    if (group != null) {
      return (
        <>
          <div>
            <InfiniteScroll
              className={`relative grid pb-11 ${displayGrid} lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2`}
              dataLength={group["user_shop_products"]["data"].length}
              next={() => fetchMoreProduct(group)}
              hasMore={
                (group["product_state"] as AppRecord).nextPageUrl === ""
                  ? false
                  : true
              }
              loader={
                <div className="absolute bottom-2 left-0 w-full flex justify-center">
                  <LoaderCircle
                    size={30}
                    className="animate-spin text-yellow-400"
                  />
                </div>
              }
              endMessage={
                <p className="absolute bottom-0 left-0 w-full text-center">
                  <b>Yay! you have seen all items.</b>
                </p>
              }
            >
              {group["user_shop_products"]["data"].map(
                (product: any, productIndex: number) => (
                  <Dialog key={product["id"]}>
                    <DialogTrigger>
                      <Card onClick={() => handleClickModal(productIndex)}>
                        <div className="relative">
                          <ImageUrl
                            src={product?.picture}
                            className="bg-slate-400 w-full object-cover rounded-lg"
                            alt="menu"
                          />
                          {/* <div className="absolute bottom-3 bg-yellow-400 p-1 right-3 rounded-full cursor-pointer">
                          <Plus className="text-white" />
                        </div> */}
                        </div>
                        <div className="p-2 h-[80px] flex flex-col justify-between">
                          <p className="font-semibold line-clamp-2 text-sm">
                            {product?.local_description ?? ""}
                          </p>
                          <p className="font-bold text-red-600">
                            {userShop?.currency_code ?? "USD"}{" "}
                            {product?.unit_price}
                          </p>
                        </div>
                      </Card>
                    </DialogTrigger>
                    <DialogContent
                      btnClose="bg-yellow-400 text-white w-[30px] h-[30px] rounded-full"
                      className="w-full p-0 gap-0 dialog-menu-display"
                    >
                      <DialogTitle className="hidden">Menu</DialogTitle>
                      <DialogDescription className="hidden">
                        This is menu dialog
                      </DialogDescription>
                      <Carousel setApi={setApi} className="w-full ">
                        <CarouselContent className="w-full ml-0">
                          {group["user_shop_products"]["data"].map(
                            (item: any, index: number) => (
                              <CarouselItem
                                key={index}
                                className="w-full relative pb-[75px] pl-0"
                              >
                                <div className="w-full h-full">
                                  <ImageUrl
                                    src={item?.picture}
                                    className="w-full object-cover rounded-t-none sm:rounded-t-lg"
                                    alt="menu"
                                  />
                                </div>
                                <div className="absolute w-full h-[70px] px-4 my-2 bottom-0 flex flex-col items-center text-center py-2">
                                  <div>
                                    <p className="font-semibold line-clamp-2 text-sm">
                                      {item["local_description"] ?? ""}
                                    </p>
                                    <p className="font-bold text-red-600">
                                      {userShop["currency_code"] ?? "USD"}{" "}
                                      {item["unit_price"]}
                                    </p>
                                  </div>
                                </div>
                              </CarouselItem>
                            )
                          )}
                        </CarouselContent>
                      </Carousel>
                    </DialogContent>
                  </Dialog>
                )
              )}
            </InfiniteScroll>
          </div>
        </>
      );
    } else return <></>;
  };

  return (
    <>
      {!shopId || shopId === "" || searchShopID ? (
        <SearchParamsNotFound params="id" />
      ) : (
        <>
          {menuState.loadingStatus === AppRecordStatus.loading ? (
            <LoadingPage />
          ) : (
            <>
              <div className="relative p-4 flex flex-col justify-between min-h-screen">
                <AlertDialog open={showAlert}>
                  <AlertDialogContent>
                    <AlertDialogHeader className="relative">
                      <X
                        onClick={() => setShowAlert(false)}
                        className="absolute -top-2 -right-2 cursor-pointer"
                      />
                      <AlertDialogTitle className="text-center font-moullight">
                        សូមស្វាគមន៍
                      </AlertDialogTitle>
                      <AlertDialogTitle className="text-center font-bold">
                        Welcome
                      </AlertDialogTitle>
                      <div className="w-full flex flex-col justify-center items-center text-center">
                        <div className="w-full flex justify-center">
                          <ImageUrl
                            width={80}
                            height={80}
                            src={userShop?.thumbnail ?? iconNoProduct}
                            alt="logo"
                          />
                        </div>
                        <p className="font-semibold text-2xl">
                          {userShop?.shop_name ?? ""}
                        </p>
                        <AlertDialogDescription className="line-clamp-3">
                          {userShop?.description}
                        </AlertDialogDescription>

                        <Separator className="my-1" />

                        <div className="mt-2 text-center">
                          <p className="">
                            សូមទាញយកកមម្មវិធីបណ្តាញសង្គម FlipFlop
                          </p>
                          <p>ដើម្បីទទួលប្រូម៉ូសិនថ្មីពីហាងរបស់យើងខ្ញុំ!</p>
                          <p className="mt-1">
                            Download FlipFlop Social Media and follow us to
                            receive new promotions!
                          </p>

                          <AlertDialogDescription className="mt-1">
                            Scan QR code below to download
                          </AlertDialogDescription>

                          <div className="">{qrDetecting()}</div>
                        </div>
                      </div>
                      {showAlert && (
                        <AlertDialogDescription className="alert z-50 mt-6 text-center">
                          This screen will disappear in{" "}
                          {`${time % 60}`.padStart(2, "0")} seconds.
                        </AlertDialogDescription>
                      )}
                    </AlertDialogHeader>
                  </AlertDialogContent>
                </AlertDialog>
                <div
                  style={{ backgroundImage: `url("${backgroundMenu}")` }}
                  className={`fixed top-0 left-0 bg-cover h-full w-full`}
                />

                {/* Header */}
                <div className="h-[60px] w-full fixed top-0 left-0 z-10 px-4 overflow-hidden">
                  <ScrollArea className="whitespace-nowrap rounded-md grid grid-cols-1 py-3">
                    <div className="flex">
                      {userShopProductGroups.length > 0 &&
                        userShopProductGroups.map((group) => (
                          <div
                            onClick={() => {
                              dispatch(
                                setSelectedProductGroupCode(
                                  group["product_group_id"]
                                )
                              );
                              scrollToTop();
                            }}
                            key={group["id"]}
                            className={`me-2 py-1 px-2 rounded-lg cursor-pointer ${
                              group["product_group_id"].toString() ===
                              selectedProductGroupCode
                                ? "bg-yellow-400 text-white"
                                : "bg-white text-black"
                            }`}
                          >{`${group["local_description"]}`}</div>
                        ))}
                      <ScrollBar orientation="horizontal" />
                    </div>
                  </ScrollArea>
                </div>

                {/* Product Contain*/}
                <div className="h-full overflow-auto mt-12 mb-2">
                  <div className="h-full">{productsContent()}</div>
                </div>

                {/* Footer */}
                <div className="h-[40px]">
                  <div className="fixed lg:hidden bottom-0 left-0 z-10 flex justify-between items-center w-full pb-4 pt-2 bg-background px-4">
                    <div className="flex">
                      <div className="">
                        <Button
                          onClick={() => setDisplayGrid("grid-cols-1")}
                          className={`rounded-r-none border-r-0 ${
                            displayGrid === "grid-cols-1" ? "bg-yellow-400" : ""
                          }`}
                          variant="outline"
                        >
                          1
                        </Button>
                        <Button
                          onClick={() => setDisplayGrid("grid-cols-2")}
                          className={`rounded-none ${
                            displayGrid === "grid-cols-2" ? "bg-yellow-400" : ""
                          }`}
                          variant="outline"
                        >
                          4
                        </Button>
                        <Button
                          onClick={() => setDisplayGrid("grid-cols-3")}
                          className={`rounded-l-none border-l-0 ${
                            displayGrid === "grid-cols-3" ? "bg-yellow-400" : ""
                          }`}
                          variant="outline"
                        >
                          9
                        </Button>
                      </div>
                      {/* <Button className="ms-2" variant="outline">
              1/3
            </Button> */}
                    </div>
                    {/* <Button className="">
            <ShoppingCart />
          </Button> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ShopMenuPage;
