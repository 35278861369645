export class PostReaction {
  private constructor(public readonly value: string) {}
  static readonly like = new PostReaction("Like");
  static readonly favorite = new PostReaction("Favorite");
  static readonly share = new PostReaction("Share");

  static readonly view = new PostReaction("View");
  static readonly unLike = new PostReaction("UnLike");
  static readonly save = new PostReaction("Save");
  static readonly send = new PostReaction("Send");
  static readonly repost = new PostReaction("Repost");
  static readonly scrollBack = new PostReaction("Scroll Back");
  static readonly comment = new PostReaction("Comment");
  static readonly shop = new PostReaction("Shop");
}
