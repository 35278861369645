import { zodResolver } from "@hookform/resolvers/zod";
import { Snackbar } from "components";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { Eye, EyeOff, KeyRound, Loader } from "lucide-react";
import { StatusMsg } from "models/returnMsg";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AppLog } from "services";
import { RequestApi, RouteApi } from "utils";
import { z } from "zod";

export default function ResetForm(token: any) {
  const [showPassword, setShowPassword] = useState("password");
  const [showRePassword, setShowRePassword] = useState("password");
  const [errorState, setErrorState] = useState<string>("");
  const [loading, setLoading] = useState<boolean>();

  const toggleShowPassword = () => {
    if (showPassword === "text") {
      setShowPassword("password");
    } else {
      setShowPassword("text");
    }
  };
  const toggleReShowPassword = () => {
    if (showRePassword === "text") {
      setShowRePassword("password");
    } else {
      setShowRePassword("text");
    }
  };
  const FormSchema = z.object({
    password: z.string().min(1, {
      message: "Password is required*",
    }),
    confirmPassword: z.string().min(1, {
      message: "Password is required*",
    }),
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const requestSubmitResetPassword = async (
    data: z.infer<typeof FormSchema>
  ) => {
    setErrorState("");
    setLoading(true);
    await RequestApi(RouteApi.submitResetPassword, {
      data: {
        new_password: data.password,
        confirm_password: data.confirmPassword,
        token: token,
      },
    }).then((result) => {
      if (result.status === "success") {
        const { token, user } = result;
        localStorage.setItem("jwt", token);
        localStorage.setItem("user", JSON.stringify(user));
        console.log("Reset successful:", { token, user });
        window.location.href = "/";
      } else {
        setErrorState(result.msg);
        Snackbar({
          message: `${result.msg}`,
          status: StatusMsg.getType(result.status),
        });
        setLoading(false);
      }
      setLoading(false);
    });
    try {
    } catch (err) {
      AppLog({ message: `requestSubmitResetPassword ${err}` });
      setLoading(false);
    }
  };
  return (
    <>
      <Form {...form}>
        <form
          className="text-start space-y-4"
          onSubmit={form.handleSubmit(requestSubmitResetPassword)}
        >
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="">
                <FormControl>
                  <div className="relative">
                    <div
                      className={`absolute left-0 top-2 start-0 ps-3.5 pointer-events-none`}
                    >
                      <KeyRound />
                    </div>
                    <Input
                      type={`${showPassword}`}
                      className="rounded-xl pl-12 text-black"
                      placeholder="New Password"
                      {...field}
                    />
                    {showPassword === "password" ? (
                      <div className="flex">
                        <div onClick={() => toggleShowPassword()}>
                          <Eye
                            className={`absolute right-3 top-2 cursor-pointer`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex">
                        <div onClick={() => toggleShowPassword()}>
                          <EyeOff
                            className={`absolute right-3 top-2 cursor-pointer `}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FormControl>
                <div className="text-red-600">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />{" "}
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="">
                <FormControl>
                  <div className="relative">
                    <div
                      className={`absolute left-0 top-2 start-0 ps-3.5 pointer-events-none`}
                    >
                      <KeyRound />
                    </div>
                    <Input
                      type={`${showRePassword}`}
                      className="rounded-xl pl-12 text-black"
                      placeholder="Confirm password"
                      {...field}
                    />
                    {showRePassword === "password" ? (
                      <div className="flex">
                        <div onClick={() => toggleReShowPassword()}>
                          <Eye
                            className={`absolute right-3 top-2 cursor-pointer`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex">
                        <div onClick={() => toggleReShowPassword()}>
                          <EyeOff
                            className={`absolute right-3 top-2 cursor-pointer `}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </FormControl>
                <div className="text-red-600">
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <div className="text-red-600">{errorState}</div>
          <div className="w-full flex flex-col">
            <Button className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white">
              {loading ? <Loader className="animate-spin" /> : "Save"}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
