import { useState, useEffect } from "react";
import { Label } from "components/ui/label";
import { Switch } from "components/ui/switch";

interface DataItem {
  id: string;
  icon: React.ReactNode;
  label: string;
  isCheck: boolean;
}

interface SwitchValueProps {
  value: string;
  disabled?: boolean;
  onChange: (value: any) => void;
  datas: DataItem[];
  isToggleClose?: boolean;
}

export default function SwitchValueWithLabelAndSameAPIKey({
  value,
  datas,
  disabled,
  onChange,
  isToggleClose = false,
}: SwitchValueProps) {
  const [selectedId, setSelectedId] = useState(value);
  const [data, setData] = useState<DataItem[]>(datas);

  useEffect(() => {
    setSelectedId(value);
  }, [value]);

  const handleToggle = (id: string) => {
    if (selectedId === id) {
      if (isToggleClose === true) {
        // Unselect the current option
        setSelectedId("");
        setData((prevData) =>
          prevData.map((item) => ({ ...item, isCheck: false }))
        );
        onChange("");
      } else {
        // Unselect the current option
        setSelectedId(id);
        setData((prevData) =>
          prevData.map((item) => ({ ...item, isCheck: false }))
        );
        if (selectedId !== id) {
          onChange(id);
        }
      }
    } else {
      // Select a new option
      setSelectedId(id);
      setData((prevData) =>
        prevData.map((item) => ({
          ...item,
          isCheck: item.id === id,
        }))
      );
      if (selectedId !== id) {
        onChange(id);
      }
    }
  };

  return (
    <>
      {data.map((element) => (
        <div
          key={element.id}
          className="flex justify-between items-center w-full hover:bg-slate-100 p-2 rounded-md"
        >
          <Label className="font-medium flex items-center space-x-2">
            <div>{element.icon}</div>
            <p>{element.label}</p>
          </Label>
          <Switch
            checked={selectedId === element.id}
            onClick={() => handleToggle(element.id)}
            disabled={disabled}
          />
        </div>
      ))}
    </>
  );
}
