import ImageUrl from "components/app/image-url/ImageUrl";
import { logoFlipFlop } from "assets";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";

import AlertLoginPage from "./AlertLoginPage";
import { Link, Mail, Smartphone } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { RouteUrl } from "utils";

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <SinglePageCenter>
      <div
        id="login"
        className="flex justify-center items-center px-4 relative"
      >
        <div className="text-center">
          <div className="pb-10 flex justify-center">
            <ImageUrl width={200} height={200} src={logoFlipFlop} alt="logo" />
          </div>
          <h1 className="text-[20px] font-bold pb-2">Welcome to FlipFlop</h1>
          <AlertLoginPage />
          <p className=" text-left">
            Not on FlipFlop yet? {""}
            <Link to="#" className="w-full text-blue-500">
              Sign up
            </Link>
          </p>
          <div className="flex w-full mt-5">
            Forgot Password with
            <Mail
              onClick={() => navigate(RouteUrl.forgotPasswordEmail)}
              className="ml-5 mr-5 text-blue-500"
            />{" "}
            OR
            <Smartphone
              onClick={() => navigate(RouteUrl.forgotPasswordPhone)}
              className="ml-5 text-blue-500"
            />
          </div>
        </div>
      </div>
    </SinglePageCenter>
  );
};

export default LoginPage;

