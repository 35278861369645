import { Snackbar } from "components";
import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { Button } from "components/ui/button";
import { Loader } from "lucide-react";

type GroupedCategories = {
  [category: string]: string[]; // Each category name is a string key with an array of codes as its value
};

export default function Interesting() {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const { token } = useAppSelector((state) => state.auth);
  const [groups, setGroups] = useState<any>([]);
  const [selectedCodes, setSelectedCodes] = useState<string[]>([]);
  const [userProductGroup, setuserProductGroup] = useState<[] | null>(null);
  const selectedCodesString = selectedCodes.join("|");

  const fetchCategory = async () => {
    try {
      let url: string = "";
      setLoadingSubmit(true);

      url = RouteApi.getCategory;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            setGroups(responses["groups"]);
            setuserProductGroup(responses["user_product_groups"]);
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });
      setLoadingSubmit(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoadingSubmit(false);
    }
  };

  const onSubmitUserSetting = async () => {
    try {
      let url: string = "";
      setLoadingSubmit(true);

      url = RouteApi.submitUpdateUserInterest;
      url += `token=${token}`;

      const data = { categories: selectedCodesString };

      await RequestApi(url, { data })
        .then((responses) => {
          if (responses.status === "success") {
            // dispatch(setRecordUserSetting(responses["user"]));
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }

          setLoadingSubmit(false);
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });

      setLoadingSubmit(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });

      setLoadingSubmit(false);
    }
  };
  useEffect(() => {
    fetchCategory();
  }, []); // Run on mount

  // Group categories by category name
  const groupedCategories: GroupedCategories = groups.reduce(
    (acc: any, item: any) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item.code);
      return acc;
    },
    {}
  );

  const handleClick = (code: string) => {
    setSelectedCodes((prevCodes) => {
      const updatedCodes = prevCodes.includes(code)
        ? prevCodes.filter((c) => c !== code) // Remove code if already selected
        : [...prevCodes, code]; // Add code if not already selected
      return updatedCodes;
    });
  };

  useEffect(() => {
    if (userProductGroup) {
      const filterProductGroup = userProductGroup.map(
        (element: any) => element.group_name
      );

      // Set selectedCodes with existing and new group_name values, avoiding duplicates
      setSelectedCodes((prevCodes) => [...prevCodes, ...filterProductGroup]);
    }
  }, [userProductGroup]);

  return (
    <Layout>
      <SettingContentLayout>
        <div className="">
          <h1 className="text-lg font-semibold">Let's Select Your Interests</h1>
          <p className="text-muted-foreground text-sm">
            Choose your interest to get better content recommendations.
          </p>

          <div className="mt-3 min-h-[calc(100vh-160px)]">
            {Object.entries(groupedCategories).map(([category, codes]) => (
              <div key={category}>
                <h1 className="font-semibold mt-3">{category}</h1>
                <div className="mt-2 flex gap-2 flex-wrap">
                  {codes.map((code, index) => (
                    <button
                      key={index}
                      onClick={() => handleClick(code)}
                      className={`px-4 py-2 rounded-lg ${
                        selectedCodes.includes(code)
                          ? "bg-primary text-white"
                          : "bg-gray-200 text-black"
                      }`}
                    >
                      {code}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div>
            <Button
              onClick={onSubmitUserSetting}
              className="w-full text-white mt-3 bg-blue-600 hover:bg-blue-500"
            >
              {loadingSubmit ? <Loader className="animate-spin" /> : "Save"}
            </Button>
          </div>
        </div>
      </SettingContentLayout>
    </Layout>
  );
}
