// src/store/slices/counterSlice.ts

import { AppRecord, AppRecordStatus } from "models/appRecord";
import { createSlice } from "@reduxjs/toolkit";

// import { AppRecord, AppRecordStatus } from "../../../models/appRecord";

interface ProfilePostContentsPageViewSlice {
  postsState: AppRecord;
  commentState: AppRecord;
  comments: any[];
  posts: any[];
  post: any;
  prePostId: string;
  currentIndex: number;
}

const initialState: ProfilePostContentsPageViewSlice = {
  postsState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },
  commentState: {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  },
  currentIndex: 0,
  prePostId: "",
  comments: [],
  posts: [],
  post: {},
};

const profilePostContentsPageViewSlice = createSlice({
  name: "profilePostContentsPageView",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.postsState = action.payload;
    },
    setCommentLoading(state, action) {
      state.commentState = action.payload;
    },
    setCurrentIdex(state, action) {
      state.currentIndex = action.payload;
    },
    setPosts(state, action) {
      state.posts = action.payload;
    },
    setPost(state, action) {
      state.post = action.payload;
      state.prePostId = action.payload.post_id;
      state.comments = action.payload.comments.data;
    },
    setComments(state, action) {
      state.comments = action.payload;
    },
  },
});

export const {
  setLoading,
  setPosts,
  setPost,
  setCurrentIdex,
  setComments,
  setCommentLoading,
} = profilePostContentsPageViewSlice.actions;

const profilePostContentsPageViewReducer =
  profilePostContentsPageViewSlice.reducer;
export default profilePostContentsPageViewReducer;
