import { qrFlipFlopAndroid, qrFlipFlopIOS } from "assets";
import ImageUrl from "components/app/image-url/ImageUrl";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function DownLoadApp() {
  const userAgent = navigator.userAgent;
  const [user, setUser] = useState<any>(localStorage.getItem("user"));
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);
  const qrDetecting = () => {
    // IOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl width={100} height={100} src={qrFlipFlopIOS} alt="logo" />
          </div>

          <Link to="https://apps.apple.com/us/app/flipflop-social-network/id6465576581">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
        </div>
      );
    }
    // ANDROID
    else if (/android/i.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl
              width={100}
              height={100}
              src={qrFlipFlopAndroid}
              alt="logo"
            />
          </div>
          <Link to="https://play.google.com/store/apps/details?id=com.maxx4business.flip_flop&hl=en">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
        </div>
      );
    }
    // Other Device
    else {
      return (
        <div className="mt-2 w-full">
          <div className="flex justify-evenly w-full mb-2">
            <div>
              <p className="font-semibold text-sm">App Store</p>
              <ImageUrl
                width={100}
                height={100}
                src={qrFlipFlopIOS}
                alt="ios-qr"
              />
            </div>
            <div>
              <p className="font-semibold text-sm">Google Play</p>
              <ImageUrl
                width={100}
                height={100}
                src={qrFlipFlopAndroid}
                alt="android-qr"
              />
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <SinglePageCenter>
        <div className="w-full flex flex-col justify-center items-center text-center">
          <div className="w-full flex justify-center mb-2">
            <ImageUrlProfile
              isLinkProfile={false}
              width={120}
              height={120}
              className="rounded-full border drop-shadow-lg"
              user={user}
              alt="logo"
            />
          </div>
          <p className="font-semibold text-2xl">{user?.name}</p>
          <p className="line-clamp-3 text-sm text-muted-foreground">
            {user?.bio}
          </p>
          <div className="container">
            <Separator className="my-1" />
          </div>

          <div className="mt-2 text-center">
            {user?.user_type === "Page" ? (
              <>
                <p className="">សូមទាញយកកមម្មវិធីបណ្តាញសង្គម FlipFlop</p>
                <p>ដើម្បីមើលមាតិការថ្មីៗពីគេហទំព័ររបស់យើងខ្ញុំ!</p>
                <p className="mt-1">
                  Download FlipFlop Social Media and follow us to view our
                  page's content!
                </p>
              </>
            ) : (
              <>
                <p className="">សូមទាញយកកមម្មវិធីបណ្តាញសង្គម FlipFlop</p>
                <p>ដើម្បីទទួលមើលមាតិការថ្មីៗរបស់ខ្ញុំ!</p>
                <p className="mt-1">
                  Download FlipFlop Social Media and follow us to view my
                  content!
                </p>
              </>
            )}

            <p className="mt-1 text-sm text-muted-foreground">
              Scan QR code below to download
            </p>

            <div className="">{qrDetecting()}</div>
          </div>
        </div>
      </SinglePageCenter>
    </>
  );
}
