import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { profileVerified } from "assets";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import LinkifyText from "components/app/linkify/LinkifyText";
import { commentTimeAgo } from "utils/formatTimeSecondToHour";
import { Textarea } from "components/ui/textarea";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import {
  faFaceSmileBeam,
  faHeart as outlineHeart,
} from "@fortawesome/free-regular-svg-icons";
import { useEffect, useRef, useState } from "react";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import { SendHorizontalIcon } from "lucide-react";
interface CommentProps {
  comment: any;
  index: number;
  submitReply: ({
    replyId,
    replyText,
  }: {
    replyId: string;
    replyText: string;
    headerId: string;
  }) => void;
  fetchSubComments?: (commentId: string) => void;
  onClikcReact: (comment: any) => void;
  children?: React.ReactNode;
  isSubComment?: boolean;
}
export default function Comment({
  comment,
  index,
  submitReply,
  fetchSubComments,
  onClikcReact,
  children,
  isSubComment,
}: CommentProps) {
  const [replyingTo, setReplyingTo] = useState<number | null>(null);
  const [replyText, setReplyText] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const onEmojiClick = (emojiData: EmojiClickData) => {
    setReplyText((prev) => prev + emojiData.emoji);
    console.log("reply");
    setShowEmojiPicker(false);
  };
  const handleReplyClick = (commentId: number) => {
    console.log("comment_id", commentId);

    setReplyingTo(commentId); // Set the comment we're replying to
  };
  const handleSubmitReply = ({
    replyId,
    headerId,
  }: {
    replyId: string;
    headerId: string;
  }) => {
    submitReply({ replyId: replyId, replyText: replyText, headerId: headerId });
    setReplyText("");
    setReplyingTo(null);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    console.log("id", comment.reply_comment_id.toString());
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior (e.g., adding a newline)
      if (replyText) {
        handleSubmitReply({
          replyId: fetchSubComments
            ? comment.id.toString()
            : comment.reply_comment_id.toString(),
          headerId: comment.reply_comment_id.toString(),
        });
      }
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="flex flex-col mt-2 mb-2">
      <div
        key={index}
        className="flex flex-row space-x-2 w-full items-start justify-start"
      >
        <ImageUrlProfile
          isLinkProfile={true}
          user={comment.sender}
          className={`cursor-pointer rounded-full ${
            isSubComment ? " w-7 h-7" : " w-7 h-7"
          }`}
          alt="logo"
        />
        <div className="flex flex-col space-y-1 w-full">
          <div className=" flex flex-col bg-gray-200 rounded-lg px-2 py-1   self-start w-auto">
            <p className="flex flex-row font-medium items-center text-sm">
              {`${comment.sender.name}`}
              {comment.sender?.is_verified === "Yes" ? (
                <>
                  <img
                    src={profileVerified}
                    alt=""
                    className="ml-1"
                    style={{
                      width: 10,
                      height: 10,
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </p>
            <LinkifyText key={comment.id} text={comment.comment} />
          </div>
          <div className="flex flex-row space-x-3 items-center">
            <p className="font-medium text-xs ">
              {commentTimeAgo(comment.comment_datetime)}
            </p>
            <p
              className="cursor-pointer font-medium text-xs"
              onClick={() => handleReplyClick(comment.id)}
            >
              Reply
            </p>
            <FontAwesomeIcon
              onClick={() => onClikcReact(comment)}
              icon={
                comment["is_like"]?.toString() === "1"
                  ? solidHeart
                  : outlineHeart
              }
              size="1x"
              className={` ${
                comment["is_like"]?.toString() === "1"
                  ? "heart-icon text-red-600 heart-fill"
                  : "heart-outline outline-black"
              }`}
            />
            {comment.no_of_reply_comment > 0 && !comment["sub_comments"] ? (
              <p
                className=" cursor-pointer text-yellow-500 font-medium text-xs"
                onClick={() => fetchSubComments!(comment.id.toString())}
              >{`View ${comment.no_of_reply_comment} ${
                comment.no_of_reply_comment === 1 ? "reply" : "replies"
              } `}</p>
            ) : (
              <></>
            )}
          </div>
          {replyingTo === comment.id && (
            <div className=" flex flex-col space-y-2  ">
              <div className="border p-2 relative rounded-md w-full bg-white">
                <div className="flex flex-col">
                  <Textarea
                    value={replyText}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setReplyText(e.target.value)}
                    className="border border-none   focus-visible:ring-0 focus-visible:ring-offset-0 w-full min-h-[10px] px-0 py-0 rounded-none "
                    placeholder="Write your reply..."
                  />
                  <div className="flex justify-between items-center">
                    <div className="flex space-x-2">
                      <FontAwesomeIcon
                        icon={faFaceSmileBeam}
                        className="text-gray-500 cursor-pointer"
                        onClick={() => setShowEmojiPicker((prev) => !prev)}
                      />
                      {/* Add more icons for stickers or other options as needed */}
                    </div>
                    <SendHorizontalIcon
                      className={`cursor-pointer ${
                        replyText ? "text-yellow-400" : "text-gray-500"
                      }`}
                      onClick={
                        !replyText
                          ? () => {}
                          : () =>
                              handleSubmitReply({
                                replyId: fetchSubComments
                                  ? comment.id.toString()
                                  : comment.reply_comment_id.toString(),
                                headerId: comment.reply_comment_id.toString(),
                              })
                      }
                    />
                  </div>
                </div>
                {showEmojiPicker && (
                  <div
                    ref={emojiPickerRef}
                    className={`absolute   top-16 left-5 `}
                    spellCheck={false}
                  >
                    <EmojiPicker
                      reactionsDefaultOpen={false}
                      onEmojiClick={onEmojiClick}
                      emojiStyle={EmojiStyle.FACEBOOK}
                      searchDisabled={true}
                      skinTonesDisabled={true}
                    />
                  </div>
                )}{" "}
              </div>
            </div>
          )}
          {children ? children : <></>}
        </div>
      </div>
    </div>
  );
}
