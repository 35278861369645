export enum AppOTPFunction {
  activationSignup,
  resetPassword,
  verifyPhone,
  verifyEmail,
  verifyLogin,
}
export class AppOTPProvider {
  private constructor(public readonly value: string) {}
  static readonly sms = new AppOTPProvider("sms");
  static readonly email = new AppOTPProvider("Email");
  static readonly flipflop = new AppOTPProvider("FlipFlop");
}
