import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import { useEffect, useState } from "react";
import { cn } from "lib/utils";
import ImageUrlThumbnail from "../image-url/ImageUrlThumbnail";

export default function CarouselWithIndex({
  item,
  imgClassName,
  indexClassName,
  contentClassName,
}: {
  item: any;
  imgClassName?: string;
  indexClassName?: string;
  contentClassName?: string;
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  const getMaxDimensions = (attachments: any[]) => {
    let maxWidth = 0;
    let maxHeight = 0;

    attachments.forEach((attachment) => {
      const width = attachment?.attachment_media_width ?? 16;
      const height = attachment?.attachment_media_height ?? 9;

      if (width > maxWidth) {
        maxWidth = width;
      }

      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    return { maxWidth, maxHeight };
  };

  const { maxWidth, maxHeight } = getMaxDimensions(
    item?.post_attachments ?? []
  );

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <Carousel setApi={setApi} className="relative rounded-xl">
      <CarouselContent className={cn("", contentClassName)}>
        {item?.post_attachments.map((post: any, index: number) => {
          return (
            <CarouselItem
              className="flex items-center rounded-xl cursor-pointer"
              key={index}
            >
              <ImageUrlThumbnail
                thumbnail={post?.attachment_thumbnail_image_file_src}
                style={{
                  aspectRatio: `${maxWidth} / ${maxHeight}`,
                }}
                src={post?.attachment_media_image_file_src}
                alt={post?.media_title}
                className={cn("", imgClassName)}
              />
            </CarouselItem>
          );
        })}
      </CarouselContent>

      <div
        className={cn(
          "font-semibold text-xs bg-gray-600 text-white opacity-70 p-2 absolute top-2 left-2 rounded-full",
          indexClassName
        )}
      >
        {current}/{count}
      </div>
    </Carousel>
  );
}
