import {
  backgroundMenu,
  logoFlipFlop,
  noneProfile,
  pageIcon,
  profileVerified,
} from "assets";
import FollowingIcon from "assets/icons/following-icon";
import HomeIcon from "assets/icons/home-icon";
import MarketPlaceIcon from "assets/icons/market-place-icon";
import NewsFeedIcon from "assets/icons/news-feed-icon";
import { Snackbar } from "components";
import ImageUrl from "components/app/image-url/ImageUrl";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import SearchBar from "components/app/search-bar/SearchBar";
import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Separator } from "components/ui/separator";
import { Skeleton } from "components/ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import {
  Bell,
  BriefcaseBusiness,
  CornerDownRight,
  Ellipsis,
  Flag,
  MessageCircle,
  Plus,
  Settings,
} from "lucide-react";
import { StatusMsg } from "models/returnMsg";
import AlertWellcome from "pages/auth/alert/AlertWellcome";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppLog } from "services";
import { logout } from "store/authSlice";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi, RouteUrl } from "utils";

export default function Header() {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [loadingShop, setLoadingShop] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postID = searchParams.get("post_id");
  const [user, setUser] = useState<any>(localStorage.getItem("user"));
  const [userShop, setUserShop] = useState<any>(null);
  const [userPage, setUserPage] = useState<any>(null);
  const { token } = useAppSelector((state) => state.auth);
  const [isOpenProfile, setIsOpenProfile] = useState<boolean>(false);

  // const navLink = [
  //   { name: "Explore", href: "/explore" },
  //   { name: "Following", href: "/following" },
  //   { name: "News Feed", href: "/news-feed" },
  //   { name: "Marketplaces", href: "/marketplaces" },
  // ];

  const navLink = [
    { name: "Home", href: "/", icons: <HomeIcon width="22px" /> },
    {
      name: "Following",
      href: "/following",
      icons: <FollowingIcon width="22px" />,
    },
    {
      name: "News Feed",
      href: "/news-feed",
      icons: <NewsFeedIcon width="22px" />,
    },
    {
      name: "Market Places",
      href: "/marketplaces",
      icons: <MarketPlaceIcon width="22px" />,
    },
  ];

  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const handleShowAlert = (link: string) => {
    if (!isAuthenticated && link !== "/") {
      setShowAlert(true);
    } else {
      navigate(`${link}`);
    }
  };
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const fetchGetUserPageProfile = async () => {
    try {
      setLoadingPage(true);
      let url: string = "";
      url = RouteApi.getPageUser;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            setUserPage(responses["pages"]);
          } else {
            // Snackbar({
            //   message: responses["msg"],
            //   status: StatusMsg.getType(responses["status"]),
            // });
          }
          setLoadingPage(false);
        })
        .catch((err) => {
          // Snackbar({
          //   message: err.toString(),
          //   status: StatusMsg.failed,
          // });
        });

      setLoadingPage(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoadingPage(false);
    }
  };

  const fetchGetUserShopProfile = async () => {
    try {
      setLoadingShop(true);
      let url: string = "";
      url = RouteApi.getShopUser;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            setUserShop(responses["user_shops"]);
          } else {
            // Snackbar({
            //   message: responses["msg"],
            //   status: StatusMsg.getType(responses["status"]),
            // });
          }
          setLoadingShop(false);
        })
        .catch((err) => {
          // Snackbar({
          //   message: err.toString(),
          //   status: StatusMsg.failed,
          // });
        });

      setLoadingShop(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoadingShop(false);
    }
  };

  const onClickProfileAvatar = () => {
    if (isAuthenticated) {
      if (!userPage && !userShop) {
        fetchGetUserPageProfile();
        fetchGetUserShopProfile();
      }
    }
  };

  return (
    <header
      id="header-public-profile-post"
      className="w-full shadow-md bg-white fixed top-0 left-0 z-50"
    >
      <div className="mx-auto flex items-center py-2 px-4 h-[80px]">
        <div className="flex items-center space-x-2 font-medium">
          <Link
            to={`/`}
            className="hover:bg-gray-200 rounded-full w-12 h-12 p-2"
          >
            <img src={logoFlipFlop} alt="logo" />
          </Link>
          {navLink.map((link) => {
            return (
              <div
                key={link.name}
                onClick={() => handleShowAlert(link.href)}
                className={`${
                  location.pathname === link.href
                    ? "bg-gray-700 text-white"
                    : ""
                } rounded-3xl px-3 py-3 cursor-pointer hover:bg-gray-200`}
              >
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>{link.icons}</TooltipTrigger>
                    <TooltipContent>
                      <p>Notification</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            );
          })}
        </div>

        <div className="flex-1 mx-6">
          <SearchBar value="" onValueChange={() => ""} />
        </div>

        <div className="flex items-center">
          {/* ==== Notification ==== */}
          <Popover>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <PopoverTrigger
                    className="z-50 hover:bg-gray-200 rounded-full w-12 h-12 p-3"
                    asChild
                  >
                    <Bell className="cursor-pointer" />
                  </PopoverTrigger>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Notification</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <PopoverContent className="w-[500px]">
              <div className="">
                <div className="space-y-2">
                  <h4 className="font-medium text-center">Notification</h4>
                  <h1 className="hidden font-semibold text-lg">Seen</h1>
                  <div className="hidden mt-2 hover:bg-gray-200 rounded-xl p-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="">
                          <ImageUrl
                            className="w-[72px] h-[72px] object-contain"
                            src={backgroundMenu}
                            alt="pic"
                          />
                        </div>
                        <p>Pins inspired by you</p>
                      </div>
                      <p>4mon</p>
                    </div>
                  </div>
                </div>
              </div>
            </PopoverContent>
          </Popover>

          {/* ==== Message ==== */}
          <Popover>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger
                  className="hover:bg-gray-200 rounded-full w-12 h-12 p-3"
                  asChild
                >
                  <PopoverTrigger asChild>
                    <MessageCircle className="cursor-pointer" />
                  </PopoverTrigger>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Messages</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <PopoverContent className="w-80">
              <div className="grid gap-4">
                <div className="space-y-2">
                  <h4 className="font-medium">Message</h4>
                  <p className="text-sm text-muted-foreground hidden">
                    Set the dimensions for the layer.
                  </p>
                </div>
              </div>
            </PopoverContent>
          </Popover>

          {/* ==== Profile ==== */}
          {isAuthenticated ? (
            <Popover>
              <PopoverTrigger
                onClick={() => {
                  onClickProfileAvatar();
                  setIsOpenProfile(true);
                }}
                className="hover:bg-gray-200 rounded-full w-12 h-12 p-2"
              >
                <ImageUrlProfile
                  isLinkProfile={false}
                  user={user}
                  className="cursor-pointer rounded-full"
                  alt="logo"
                />
              </PopoverTrigger>

              <PopoverContent className="w-[300px] focus:bg-white">
                <div className="">
                  <div className="">
                    <div className="space-y-1">
                      <h1 className="font-semibold">Accounts & Pages</h1>

                      {loadingPage && (
                        <>
                          {[...Array(1)].map((_, index) => {
                            return (
                              <div
                                key={index}
                                className="flex items-center p-2"
                              >
                                <Skeleton className="w-8 h-8 rounded-full" />
                                <Skeleton className="w-40 h-6 ml-2" />
                              </div>
                            );
                          })}
                        </>
                      )}
                      {userPage?.data.length > 0 ? (
                        <>
                          {userPage?.data
                            ?.slice(0, 2)
                            .map((page: any, indexPage: number) => {
                              return (
                                <div
                                  key={indexPage}
                                  onClick={() => {
                                    setIsOpenProfile(false);
                                    navigate(`/profile?id=${page.id}&posts`);
                                  }}
                                  className="flex flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg"
                                >
                                  <div className="flex items-center">
                                    <ImageUrlProfile
                                      isLinkProfile={false}
                                      user={page}
                                      className="w-8 h-8 rounded-full border"
                                    />
                                    <div className="flex flex-col justify-center items-start ml-2">
                                      <div className="flex items-center justify-between">
                                        <h4 className="font-medium line-clamp-1">
                                          {page.name}
                                        </h4>
                                        {user?.is_verified === "Yes" ? (
                                          <>
                                            <img
                                              src={profileVerified}
                                              alt=""
                                              className="ml-1"
                                              style={{
                                                width: 16,
                                                height: 16,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <h5 className="text-muted-foreground text-xs line-clamp-1">
                                        {page.bio}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="flex items-center justify-start flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg">
                        <div className="w-8 h-8 rounded-full border flex justify-center items-center bg-gray-200">
                          <Ellipsis />
                        </div>
                        <p className="ml-2 font-medium ">See more pages</p>
                      </div>

                      <div className="flex items-center justify-start flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg">
                        <div className="w-8 h-8 rounded-full border flex justify-center items-center bg-gray-200">
                          <Plus />
                        </div>
                        <p className="ml-2 font-medium">Create your page</p>
                      </div>

                      <h1 className="font-semibold">Shops</h1>

                      {userShop?.length > 0 ? (
                        <>
                          {userShop
                            ?.slice(0, 2)
                            .map((shop: any, indexShop: number) => {
                              return (
                                <div
                                  key={indexShop}
                                  onClick={() => {
                                    setIsOpenProfile(false);
                                    navigate(
                                      `${RouteUrl.businessSuite}?id=${shop.shop_id}`
                                    );
                                  }}
                                  className="flex flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg"
                                >
                                  <div className="flex items-center">
                                    <ImageUrl
                                      src={shop?.thumbnail}
                                      className="w-8 h-8 rounded-full border"
                                    />
                                    <div className="flex flex-col justify-center items-start ml-2">
                                      <div className="flex items-center justify-between">
                                        <h4 className="font-medium line-clamp-1">
                                          {shop?.shop_name}
                                        </h4>
                                        {user?.is_verified === "Yes" ? (
                                          <>
                                            <img
                                              src={profileVerified}
                                              alt=""
                                              className="ml-1"
                                              style={{
                                                width: 16,
                                                height: 16,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <h5 className="text-muted-foreground text-xs line-clamp-1">
                                        {shop.shop_category}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="flex items-center justify-start flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg">
                        <div className="w-8 h-8 rounded-full border flex justify-center items-center bg-gray-200">
                          <Ellipsis />
                        </div>
                        <p className="ml-2 font-medium ">See more shops</p>
                      </div>

                      <div className="flex items-center justify-start flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg">
                        <div className="w-8 h-8 rounded-full border flex justify-center items-center bg-gray-200">
                          <Plus />
                        </div>
                        <p className="ml-2 font-medium">Create your shop</p>
                      </div>

                      <Separator className="" />
                      <div
                        onClick={() => navigate(RouteUrl.setting)}
                        className="flex flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg"
                      >
                        <Settings className="" />
                        <p className="pl-2 font-medium">Setting & Account</p>
                      </div>

                      <div
                        onClick={() => {
                          navigate(RouteUrl.businessSuite);
                        }}
                        className="flex flex-row cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg"
                      >
                        <BriefcaseBusiness className="" />
                        <p className="pl-2 font-medium">Business Suite</p>
                      </div>

                      <div
                        className="flex flex-row text-red-500 text-muted-foreground cursor-pointer hover:bg-slate-200 w-full p-2 rounded-lg"
                        onClick={() => {
                          dispatch(logout());
                          window.location.href = "/";
                        }}
                      >
                        <CornerDownRight className="" />
                        <p className="pl-2 font-medium">Logout</p>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          ) : (
            <div
              onClick={() => setShowAlert(true)}
              className="hover:bg-gray-200 rounded-full w-12 h-12 p-2"
            >
              <ImageUrlProfile
                isLinkProfile={false}
                user={user}
                className="cursor-pointer rounded-full"
                alt="logo"
              />
            </div>
          )}
        </div>
      </div>
      <AlertWellcome showAlert={showAlert} setShowAlert={setShowAlert} />
    </header>
  );
}
