export const storeUser = ({ user }: { user: any }) => {
  const storageUser = localStorage.getItem("users");
  let users: any[] = [];
  if (storageUser) {
    users = JSON.parse(storageUser);
  }

  const index = users.findIndex((item) => item.id === user.id);

  if (index !== -1) {
    users[index] = user;
  } else {
    users.push(user);
  }
  localStorage.setItem("users", JSON.stringify(users));
  localStorage.setItem("user", JSON.stringify(user));
};
