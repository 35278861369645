import React, { useState, useEffect } from "react";
import { Howl } from "howler";
import { Volume2, VolumeOffIcon } from "lucide-react";
import useVideoPlayer from "../video-post-content/useVideoPlayer";
import { music } from "assets";
import Marquee from "components/marquee/Marquee";
import { Service } from "utils/services";

interface AudioPlayerProps {
  src: string;
  title?: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, title = "" }) => {
  const [sound, setSound] = useState<Howl | null>(null);
  const { volume, setVolume, isMuted, setIsMuted } = useVideoPlayer();
  const [showVolumeInput, setShowVolumeInput] = useState(false);
  const [isPlaying, setisPlaying] = useState(false);
  useEffect(() => {
    const newSound = new Howl({
      src: [
        Service.applicationMode.isDebug() ? src.replace("https", "http") : src,
      ],
      html5: true,
      autoplay: true,
      mute: isMuted,
      loop: true,
      volume: volume,
      onplay: () => {
        setisPlaying(true);
      },
      onplayerror: () => {
        console.log("----error ");
      },
      onload: () => {
        console.log("----load", sound);
      },
    });

    setSound(newSound);

    // Cleanup function to unload the sound when the component unmounts or the src changes
    return () => {
      newSound.unload();
    };
  }, [src]);
  const toggleMute = () => {
    if (sound) {
      if (!sound.playing()) {
        sound.play();
        if (isMuted) {
          setIsMuted(false);
          if (sound) {
            sound.mute(!isMuted);
          }
        }
      } else {
        setIsMuted((prevMute) => !prevMute);
        if (sound) {
          sound.mute(!isMuted);
        }

        if (isMuted && volume === 0) {
          setVolume(0.5);
        }
      }
    }
  };
  const onSeekVolume = (volume: number) => {
    if (sound) {
      if (!sound.playing()) {
        sound.play();
      }
      setVolume(volume);
      sound?.volume(volume);
      if (volume === 0) {
        setIsMuted(true);
        sound?.mute(true);
      } else {
        setIsMuted(false);
        sound?.mute(false);
      }
    } else {
    }
  };
  return (
    <div className="flex flex-row audio-player space-x-2 items-center">
      <div
        className="flex items-center space-x-2"
        onClick={() => {
          if (!isPlaying) {
            sound?.play();
          }
        }}
        onMouseMove={() => {
          if (!isPlaying) {
          } else {
            setShowVolumeInput(true);
          }
        }}
        onMouseLeave={() => setShowVolumeInput(false)}
      >
        <button className="text-white" onClick={toggleMute}>
          {isMuted || !isPlaying ? (
            <VolumeOffIcon className="rounded-full bg-opacity-10 bg-black p-1 max-sm:size-6 size-8" />
          ) : (
            <Volume2 className="rounded-full bg-opacity-10 bg-black p-1 max-sm:size-6 size-8" />
          )}
        </button>

        {showVolumeInput && (
          <div className=" bg-black size-5 rounded-2xl bg-opacity-10 text-white max-sm:w-24 w-28 p-2 items-center flex pt-4 pb-4">
            <input
              type="range"
              min={0}
              max={1}
              step={0.01}
              value={volume}
              onChange={(e) => onSeekVolume(parseFloat(e.target.value))}
              className="max-sm:w-20 w-24 "
            />
          </div>
        )}
      </div>
      <div className="flex items-center bg-opacity-10 bg-black size-5 rounded-2xl text-white  w-full text-sm font-normal pt-4 pb-4 max-w-48">
        <img
          src={music}
          className="filter invert brightness-0  max-sm:size-6 size-8 p-2"
        />
        <Marquee text={title} />
      </div>
    </div>
  );
};
export default AudioPlayer;
