import { noneProfile } from "assets";
import { useState, useEffect } from "react";
import { Service } from "utils/services";

export default function ImageUrlCoverProfile({
  src,
  className,
  alt = "",
  style,
}: {
  src: string;
  className?: string;
  alt?: string;
  style?: React.CSSProperties;
}) {
  const [imageSrc, setImageSrc] = useState<string>("");

  useEffect(() => {
    if (!src || src === "") {
      setImageSrc(src);
    } else {
      let updatedSrc = src;
      if (Service.applicationMode.isDebug()) {
        updatedSrc = src.replace("https", "http");
      }
      setImageSrc(updatedSrc);
    }
  }, [src]); // Dependency array to update when `src` changes

  const handleError = () => {
    // Fallback to thumbnail if image fails to load
    setImageSrc(noneProfile);
  };

  return (
    <img
      style={style}
      className={className}
      src={imageSrc}
      alt={alt}
      onError={handleError} // Fallback on error
    />
  );
}
