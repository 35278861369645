import React, { useState } from "react";
import {
  FiSearch,
  FiMoreVertical,
  FiMenu,
  FiPaperclip,
  FiSmile,
  FiArrowLeft,
} from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import "tailwindcss/tailwind.css";

// Sample data for chats and messages
const chats = Array.from({ length: 15 }, (_, i) => ({
  id: i + 1,
  name: `User ${i + 1}`,
  lastMessage: "This is a sample message...",
  time: "02:28 PM",
  profilePic: "https://via.placeholder.com/40",
  messages: [
    { id: 1, content: `Message 1 from User ${i + 1}`, time: "02:30 PM" },
    { id: 2, content: `Message 2 from User ${i + 1}`, time: "02:31 PM" },
    { id: 3, content: `Message 3 from User ${i + 1}`, time: "02:32 PM" },
  ],
}));

const TelegramMockup: React.FC = () => {
  const [activeChat, setActiveChat] = useState<any | null>(null);
  const [isMobileView, setIsMobileView] = useState(false);

  const handleChatClick = (chat: any) => {
    setActiveChat(chat);
    setIsMobileView(true); // On mobile, switch to chat window when a chat is clicked
  };

  const handleBackClick = () => {
    setIsMobileView(false); // Go back to chat list on mobile
  };

  const ChatList = () => (
    <div
      className={`flex flex-col bg-gray-900 text-white w-full md:w-1/3 lg:w-1/4 h-full ${
        isMobileView ? "hidden md:flex" : "flex"
      }`}
    >
      <div className="p-4 sticky top-0 bg-gray-900 z-10">
        <div className="flex items-center justify-between gap-3">
          <FiMenu className="text-2xl cursor-pointer" />
          <h2 className="font-bold">Chats</h2>
          <FiMoreVertical className="text-2xl cursor-pointer" />
        </div>
        <div className="mt-4 flex gap-2 items-center rounded-lg px-3 py-2 bg-gray-800 h-12">
          {" "}
          {/* Increased height for search */}
          <FiSearch />
          <input
            className="flex-1 bg-transparent outline-none text-white"
            type="text"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="flex-1 overflow-y-auto px-4 space-y-4 pb-4 chat-list-hover">
        {chats.map((chat) => (
          <div
            key={chat.id}
            className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer ${
              activeChat?.id === chat.id ? "bg-purple-600" : "bg-gray-900"
            }`}
            onClick={() => handleChatClick(chat)}
          >
            <img
              src={chat.profilePic}
              alt="profile"
              className="w-10 h-10 rounded-full"
            />
            <div className="flex flex-col flex-1">
              <h3 className="font-semibold">{chat.name}</h3>
              <p className="truncate text-gray-400">{chat.lastMessage}</p>
            </div>
            <span className="text-sm text-gray-500">{chat.time}</span>
          </div>
        ))}
      </div>
    </div>
  );

  const ChatWindow = () => (
    <div
      className={`flex flex-col justify-between h-full text-white w-full md:w-2/3 lg:w-3/4 bg-gray-800 ${
        !isMobileView && "hidden md:flex"
      }`}
    >
      {activeChat ? (
        <>
          <div className="p-4 bg-gray-900 flex items-center justify-between sticky top-0 z-10">
            {isMobileView && (
              <button onClick={handleBackClick} className="text-2xl mr-4">
                <FiArrowLeft />
              </button>
            )}
            <h2 className="font-bold">{activeChat.name}</h2>
            <FiMoreVertical className="text-2xl cursor-pointer" />
          </div>
          <div className="flex-1 p-4 overflow-y-auto">
            {activeChat.messages.map((message: any) => (
              <div key={message.id} className="mb-4">
                <div className="text-gray-400">{message.content}</div>
                <div className="text-xs text-gray-500">{message.time}</div>
              </div>
            ))}
          </div>
          <div className="flex items-center p-4 bg-gray-900">
            <button className="text-2xl text-gray-400">
              <FiSmile />
            </button>
            <button className="ml-2 text-2xl text-gray-400">
              <FiPaperclip />
            </button>
            <input
              type="text"
              placeholder="Message"
              className="flex-1 p-2 ml-2 text-black rounded-lg outline-none"
            />
            <button className="ml-4 text-3xl text-blue-500">
              <FaTelegramPlane />
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-1 items-center justify-center">
          <p className="text-center text-gray-400">No chat selected</p>
        </div>
      )}
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row h-screen w-screen">
      <ChatList />
      <ChatWindow />
    </div>
  );
};

export default TelegramMockup;
