import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import { Snackbar } from "components";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { useDispatch } from "react-redux";
import SearchBar from "components/app/search-bar/SearchBar";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import { Button } from "components/ui/button";
import ImageUrl from "components/app/image-url/ImageUrl";
import { logoFlipFlop } from "assets";

export default function SoundLibraries() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useAppSelector((state) => state.auth);

  const fetchGetProfile = async ({
    isRefresh = false,
  }: {
    isRefresh?: boolean;
  }) => {
    try {
      if (isRefresh) {
        setLoading(true);
      }
      let url: string = "";

      // url = RouteApi.getUserSetting;
      // url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            console.log("response", responses);
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });

      setLoading(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetProfile({ isRefresh: true });
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <SettingContentLayout>
        {!loading && (
          <div className="h-[calc(100vh-160px)]">
            <div className="flex justify-between items-center">
              <h1 className="text-lg font-semibold">Sound Libraries</h1>
              <SearchBar value="" onValueChange={() => console.log("search")} />
            </div>

            <div className="mt-6 grid justify-items-center grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {[...Array(5)].map((_, index) => {
                return (
                  <div
                    key={index}
                    className="relative w-[180px] flex flex-col items-start justify-center p-2 px-4 rounded-lg cursor-pointer group"
                  >
                    {/* Pseudo-element for background gradient */}
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-900 opacity-0 rounded-lg transition-opacity duration-300 group-hover:opacity-20"></div>

                    {/* Content goes here */}
                    <div className="flex justify-center w-full">
                      <img
                        src={logoFlipFlop}
                        className="w-[120px] h-[120px] rounded-full relative" // Use relative positioning to ensure the img is above the gradient
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col justify-center items-start -space-y-0.5 ms-2 font-sans relative">
                      <h1 className="font-semibold line-clamp-2">Username</h1>
                      <p className="text-sm text-muted-foreground line-clamp-2">
                        Description
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </SettingContentLayout>
    </Layout>
  );
}
