import { StatusMsg } from "models/returnMsg";
import { AppLog } from "services";
import { RequestApi, RouteApi } from "utils";

export async function requestSubmitFollowingUser({
  userId,
}: {
  userId: string;
}) {
  try {
    const token = localStorage.getItem("jwt");
    let url = RouteApi.submitFollowingUser;
    url += `token=${token}`;
    const result = await RequestApi(url, {
      data: { user_id: userId },
    });
    return result;
  } catch (err) {
    AppLog({ message: ` catch on signup ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
export async function requestSubmitUnFollowinguUser({
  userId,
}: {
  userId: string;
}) {
  try {
    const token = localStorage.getItem("jwt");
    let url = RouteApi.submitUnFollowingUser;
    url += `token=${token}`;
    const result = await RequestApi(url, {
      data: { user_id: userId },
    });
    return result;
  } catch (err) {
    AppLog({ message: `catch on signup ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
