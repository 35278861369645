import ImageUrl from "components/app/image-url/ImageUrl";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { useLocation } from "react-router-dom";
import ResetForm from "./ResetForm";

export default function ResetPasswordPage() {
  const location = useLocation();
  const { token, user } = location.state || {};
  return (
    <SinglePageCenter>
      <div className="flex justify-center items-center px-4 relative">
        <div className="text-center">
          <div className="pb-5 flex justify-center">
            <ImageUrlProfile
              isLinkProfile={false}
              user={user}
              className="rounded-full object-cover border shadow-md shadow-gray-600"
              alt="user"
              style={{ width: 100, height: 100 }}
            />
          </div>
          <h1 className="font-semibold text-2xl">{user.name}</h1>
          <p className="pt-5 pb-5">
            Input the new password and confirmation password.
          </p>
          <ResetForm token={token} />
        </div>
      </div>
    </SinglePageCenter>
  );
}
