import { Input } from "components/ui/input";
import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
} from "react";

interface TextInputProps {
  id?: number | string;
  maxLength?: number;
  minLength?: number;
  value?: string | number | readonly string[];
  placeholder?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
}
export default function TextInput({
  value,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  className,
  disabled,
  maxLength,
  minLength,
}: TextInputProps) {
  return (
    <Input
      type="text"
      value={value}
      minLength={minLength}
      maxLength={maxLength}
      className={className}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      disabled={disabled}
    />
  );
}
