import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RouteUrl } from "utils";
import LoginPage from "pages/auth/login/LoginPage";
import NotFoundPage from "./pages/page-not-found/NotFoundPage"; // Import the NotFoundPage component
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import ShopMenuPage from "./pages/shop-menu/ShopMenuPage";
import WelcomePage from "./pages/welcome/IndexPage";
import TermOfPrivacyPage from "./pages/term-of-privacy/TermOfPrivacyPage";
import TermOfServices from "pages/term-of-services/TermOfService";
import ViewShop from "pages/view-shop/ViewShop";
import ViewProduct from "pages/view-product/ViewProduct";
import ProfilePostContentsPageView from "pages/profile-post-contents-page-view/ProfilePostContentsPageView";
import Following from "pages/following/Following";
import NewsFeed from "pages/news-feed/NewsFeed";
import MarketPlaces from "pages/market-places/MarketPlaces";
import Chat from "pages/chat/Chat";
import { useEffect } from "react";
import SigUpPage from "pages/auth/sign-up/SignUpPage";
import ForgotPasswordPhonePage from "pages/auth/forgot-password-phone/ForgotPasswordPhonePage";
import ResetPasswordPage from "pages/auth/reset_password/ResetPasswordPage";
import ForgotPasswordEmailPage from "pages/auth/forgot-password-email/ForgotPasswordEmailPage";
import { initOneSignal } from "utils/oneSignalService";
import ChooseInterestCategory from "pages/user-interest/ChooseInterestCategory";
import Profile from "pages/profile/Profile";
import SettingAndSecurity from "pages/setting-and-account/SettingAndSecurity";
import BusinessSuite from "pages/business-suite/BusinessSuite";
import ChangePasswordPage from "pages/auth/change-password/ChangePasswordPage";
import BlockAccounts from "pages/setting-and-account/BlockAccounts";
import BoringAccounts from "pages/setting-and-account/BoringAccounts";
import LoggedDevices from "pages/setting-and-account/LoggedDevices";
import RequestVerificationAccount from "pages/setting-and-account/RequestVerificationAccount";
import SoundLibraries from "pages/setting-and-account/SoundLibraries";
import ChangePassword from "pages/setting-and-account/ChangePassword";
import DeleteAccount from "pages/setting-and-account/DeleteAccount";
import DisactivateAccount from "pages/setting-and-account/DisactivateAccount";
import DeleteAccountPageStatus from "pages/page-status/DeleteAccountPageStatus";
import DisactivateAccountPageStatus from "pages/page-status/DisactivateAccountPageStatus";
import ChangePasswordPageStatus from "pages/page-status/ChangePasswordPageStatus";
import Interesting from "pages/setting-and-account/Interesting";
import SwitchAccount from "pages/setting-and-account/SwitchAccount";

function App() {
  useEffect(() => {
    initOneSignal();
  }, []);

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route
            path={RouteUrl.forgotPasswordPhone}
            element={<ForgotPasswordPhonePage />}
          />
          <Route
            path={RouteUrl.forgotPasswordEmail}
            element={<ForgotPasswordEmailPage />}
          />
          <Route path={RouteUrl.signUp} element={<SigUpPage />} />
          <Route path={RouteUrl.login} element={<LoginPage />} />
          <Route
            path={RouteUrl.resetPassword}
            element={<ResetPasswordPage />}
          />
        </Route>
        {/*  Inteers */}
        <Route
          path={RouteUrl.interestingCategory}
          element={<ChooseInterestCategory />}
        />
        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route path={RouteUrl.setting} element={<SettingAndSecurity />} />
          <Route path={RouteUrl.businessSuite} element={<BusinessSuite />} />
          {/* <Route
            path={RouteUrl.changePassword}
            element={<ChangePasswordPage />}
          /> */}
          <Route path={RouteUrl.blockAccount} element={<BlockAccounts />} />
          <Route path={RouteUrl.boringAccount} element={<BoringAccounts />} />
          <Route path={RouteUrl.loggedDevices} element={<LoggedDevices />} />
          <Route
            path={RouteUrl.requestVerificationAccount}
            element={<RequestVerificationAccount />}
          />
          <Route path={RouteUrl.soundLibraries} element={<SoundLibraries />} />
          <Route path={RouteUrl.changePassword} element={<ChangePassword />} />
          <Route path={RouteUrl.deleteAccount} element={<DeleteAccount />} />
          <Route
            path={RouteUrl.desActivateAccount}
            element={<DisactivateAccount />}
          />
          <Route path={RouteUrl.interesting} element={<Interesting />} />
          <Route path={RouteUrl.switchAccount} element={<SwitchAccount />} />
        </Route>

        {/* Catch-all route for 404 */}
        <Route path="*" element={<NotFoundPage />} />

        {/* Public routes */}
        <Route path={RouteUrl.welcome} element={<WelcomePage />} />
        <Route
          path={RouteUrl.deletePageStatus}
          element={<DeleteAccountPageStatus />}
        />
        <Route
          path={RouteUrl.disactivatePageStatus}
          element={<DisactivateAccountPageStatus />}
        />
        <Route
          path={RouteUrl.changePasswordPageStatus}
          element={<ChangePasswordPageStatus />}
        />

        <Route path={RouteUrl.shopMenu} element={<ShopMenuPage />} />
        <Route path={RouteUrl.termOfPrivacy} element={<TermOfPrivacyPage />} />
        <Route path={RouteUrl.termOfServices} element={<TermOfServices />} />
        <Route path={RouteUrl.profile} element={<Profile />} />
        <Route path={RouteUrl.viewShop} element={<ViewShop />} />
        <Route path={RouteUrl.viewProduct} element={<ViewProduct />} />
        <Route path={RouteUrl.following} element={<Following />} />
        <Route path={RouteUrl.newsFeed} element={<NewsFeed />} />
        <Route path={RouteUrl.marketPlaces} element={<MarketPlaces />} />
        <Route
          path={RouteUrl.profilePostContentsPageView}
          element={<ProfilePostContentsPageView />}
        />
        <Route path={RouteUrl.chat} element={<Chat />} />
      </Routes>
    </Router>
  );
}

export default App;
