import React, { CSSProperties } from "react";
import AsyncSelect from "react-select/async";
import { NoticeProps } from "react-select";

const InputSelectAutoSuggest = ({
  value,
  onChange,
  datas,
}: {
  value: string;
  onChange: any;
  datas: any;
}) => {
  const LoadingMessage = (props: NoticeProps<false>) => {
    return (
      <div
        {...props.innerProps}
        style={props.getStyles("loadingMessage", props) as CSSProperties}
      >
        {props.children}
      </div>
    );
  };

  const filterColors = (inputValue: string) =>
    datas.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );

  const promiseOptions = (inputValue: string) =>
    new Promise<any>((resolve) => {
      resolve(filterColors(inputValue));
    });

  return (
    <AsyncSelect
      placeholder={value === "" ? "Select..." : value}
      onChange={onChange}
      cacheOptions
      defaultOptions
      classNamePrefix="input-select-auto-suggest"
      className=""
      options={datas}
      defaultValue={datas.find((item: any) => item.value === value) || null}
      loadOptions={promiseOptions}
      // styles={{
      //   loadingMessage: (base) => ({
      //     ...base,
      //     backgroundColor: "grey",
      //     color: "white",
      //   }),
      // }}
      // components={{ LoadingMessage }}
    />
  );
};

export default InputSelectAutoSuggest;
