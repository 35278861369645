export default function ChooseInterestCategory() {
  return (
    <>
      <div className="items-start text-center pt-8">
        <p className="font-bold text-3xl">Let's Select Your Interests </p>
        <p className="font-semibold text-2xl">
          Get better contents recommendation
        </p>
      </div>
    </>
  );
}
