import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";

export function SelectScrollable({
  onChange,
  value,
  datas,
}: {
  value: string;
  onChange: (value: string) => void;
  datas: [{ value: string; label: string }] | any;
}) {
  // const datas = [
  //   { value: "Group", label: "Group" },
  // ];

  return (
    <Select
      value={value}
      onValueChange={(value) => {
        onChange(value);
      }}
    >
      <SelectTrigger>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {datas.map((item: any, index: number) => {
            return (
              <SelectItem key={index} value={item.value}>
                {item.label}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
