// src/store/slices/counterSlice.ts

import { createSlice } from "@reduxjs/toolkit";
// import { AppRecord, AppRecordStatus } from "../../../models/appRecord";

interface WelcomePostSlice {
  posts: any[];
  selectedProductGroupCode: string;
}

const initialState: WelcomePostSlice = {
  posts: [],
  selectedProductGroupCode: "All",
};

const welcomePostSlice = createSlice({
  name: "welcomePost",
  initialState,
  reducers: {
    setPost(state, action) {
      state.posts = action.payload;
    },
    setProductGroupCode(state, action) {
      state.selectedProductGroupCode = action.payload;
    },
  },
});

export const { setPost, setProductGroupCode } = welcomePostSlice.actions;

const welcomePostReducer = welcomePostSlice.reducer;
export default welcomePostReducer;
