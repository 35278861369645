import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import { Snackbar } from "components";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import SearchBar from "components/app/search-bar/SearchBar";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import { Button } from "components/ui/button";
import { AppRecord, AppRecordStatus } from "models/appRecord";
import { LoaderCircle, X } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { profileVerified } from "assets";
import { Card } from "components/ui/card";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";

import {
  BrowserDeviceInfo,
  convertDeviceInfoToMap,
  getBrowserDeviceInfo,
} from "utils/deviceInfo";
import InputHideShow from "components/app/function/InputHideShow";
import { updateAuthToken } from "store/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function SwitchAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [accountLoading, setAccountLoading] = useState<any>(null);
  const [userAccount, setUserAccount] = useState<any>(null);
  const localUser: any = localStorage.getItem("user");
  const [currentUser, setCurrentUser] = useState<any>(JSON.parse(localUser));
  const { token } = useAppSelector((state) => state.auth);
  const [searchText, setSearchText] = useState<string>("");
  const [userDataClick, setUserDataClick] = useState<any>({});
  const [password, setPassword] = useState<string>("");
  const [isAlertTypingPassword, setIsAlertTypingPassword] =
    useState<boolean>(false);
  const [deviceInfo, setDeviceInfo] = useState<BrowserDeviceInfo | null>(null);

  const accountState = {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  };

  const fetchMoreGetSwitchAccount = async ({ next }: { next: string }) => {
    setAccountLoading({
      ...accountState,
      loadingStatus: AppRecordStatus.loadingMore,
    });

    await RequestApi(next).then((responses) => {
      if (responses.status === "success") {
        const stateRecord: AppRecord = {
          currentPage: 1,
          currentIndex: 0,
          nextPageUrl: responses["users"]["next_page_url"] ?? "",
          totalRecord: 0,
          loadingStatus:
            responses["users"]["next_page_url"] !== null
              ? AppRecordStatus.noLoadMore
              : AppRecordStatus.loaded,
        };

        const tmpPosts = [
          ...userAccount["data"],
          ...responses["users"]["data"],
        ];

        const newPost = { ...userAccount, data: tmpPosts };
        setUserAccount(newPost);
        setAccountLoading(stateRecord);
      }
    });
  };

  const fetchGetSwitchAccount = async () => {
    try {
      let url: string = "";

      url = RouteApi.getUserUser;
      url += `token=${token}`;

      const data = {
        search_text: searchText,
      };

      await RequestApi(url, { data })
        .then((responses) => {
          if (responses.status === "success") {
            const stateRecord: AppRecord = {
              currentPage: 1,
              currentIndex: 0,
              nextPageUrl: responses["users"]["next_page_url"] ?? "",
              totalRecord: 0,
              loadingStatus:
                responses["users"]["next_page_url"] != null
                  ? AppRecordStatus.loaded
                  : AppRecordStatus.noLoadMore,
            };

            const storeUsers = localStorage.getItem("users");
            const users = JSON.parse(storeUsers ?? "[]") ?? [];
            const perSonalUser = [];
            const responseUser = responses["users"]["data"];
            for (const storeUser of users) {
              if (storeUser["user_type"] === "Personal") {
                perSonalUser.push(storeUser);
              }
            }
            const tmpUser = [...perSonalUser, ...responseUser];

            const newUser = { ...responses["users"], data: tmpUser };
            setUserAccount(newUser);

            setAccountLoading(stateRecord);
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    }
  };

  const onSubmit = async ({ user }: { user: any }) => {
    try {
      let data;
      let url: string = "";
      // Personal
      if (user.user_type === "Personal") {
        url = RouteApi.switchLogin;
        url += `token=${token}&username=${user.email}&password=${password}`;

        data = {
          device_data:
            deviceInfo === null ? "" : convertDeviceInfoToMap(deviceInfo!),
        };
      } else if (user.user_type === "Page") {
        url = RouteApi.switchAccount;
        url += `token=${token}`;

        data = {
          user,
          device_data:
            deviceInfo === null ? "" : convertDeviceInfoToMap(deviceInfo!),
        };
      }

      await RequestApi(url, { data })
        .then((responses) => {
          if (responses.status === "success") {
            const { token, user } = responses;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("jwt", token);
            localStorage.setItem(
              "authentication_key",
              user["authentication_key"]
            );
            dispatch(updateAuthToken(token));
            navigate("/");
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    }
  };

  const handleClickSwitchAccount = ({ user }: { user: any }) => {
    if (currentUser.account_id !== user.account_id) {
      setUserDataClick(user);
      if (user.user_type === "Personal") {
        setIsAlertTypingPassword(true);
      } else if (user.user_type === "Page") {
        onSubmit({ user });
      }
    } else {
      toast.warning("You're currently logged in as this user");
    }
  };

  const handleClickPersonalSubmitForm = () => {
    onSubmit({ user: userDataClick });
  };

  useEffect(() => {
    if (searchText === "") {
      fetchGetSwitchAccount();
    } else {
      fetchGetSwitchAccount();
    }

    // eslint-disable-next-line
  }, [searchText]);

  useEffect(() => {
    setDeviceInfo(getBrowserDeviceInfo());
  }, []);

  return (
    <Layout>
      <SettingContentLayout>
        <div className="min-h-[calc(100vh-160px)]">
          <div>
            <h1 className="text-lg font-semibold">Swtich Accounts</h1>
            <p className="text-muted-foreground text-sm">
              Manage your blocked accounts to control who can interact with you.
            </p>
          </div>
          <div className="mt-4">
            <SearchBar
              name="SearhAccount"
              value={searchText}
              onValueChange={(result) => setSearchText(result)}
            />
          </div>

          {userAccount ? (
            <>
              <InfiniteScroll
                className={`relative`}
                dataLength={userAccount["data"]?.length}
                next={() =>
                  fetchMoreGetSwitchAccount({
                    next: accountLoading.nextPageUrl,
                  })
                }
                hasMore={accountLoading.nextPageUrl === "" ? false : true}
                loader={
                  <div className="absolute bottom-2 left-0 w-full flex justify-center">
                    <LoaderCircle
                      size={30}
                      className="animate-spin text-yellow-400"
                    />
                  </div>
                }
                endMessage={
                  <p className="absolute bottom-0 left-0 w-full text-center">
                    <b>Yay! you have seen all users account.</b>
                  </p>
                }
              >
                <div className="mt-6 grid grid-cols-3 gap-4 mb-16 ">
                  {userAccount?.data?.map((user: any) => {
                    return (
                      <Card key={user.id} className="rounded-md space-y-2">
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(`/profile?id=${user?.id}&feed`);
                          }}
                        >
                          <ImageUrlProfile
                            className="w-full object-cover h-[200px] rounded-t-md"
                            user={user}
                          />
                          {/* Name */}
                          <div className="p-2">
                            <div className="flex">
                              <h1 className="font-semibold text-lg line-clamp-1">
                                {user?.name}
                              </h1>
                              {user?.is_verified === "Yes" ? (
                                <>
                                  <div className="flex items-center justify-center pt-0.5 ml-1">
                                    <img
                                      src={profileVerified}
                                      style={{ width: 24, height: 24 }}
                                      alt=""
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <p
                              className={`line-clamp-1 ${
                                user?.bio === "" ? "invisible" : ""
                              }`}
                            >
                              {user?.bio !== "" ? user?.bio : "NoBio"}
                            </p>
                          </div>
                        </div>

                        {/* Follower Post Like */}
                        <div className="grid grid-cols-3 text-center">
                          <div className="">
                            <h1 className="font-semibold">
                              {user?.no_of_follower}
                            </h1>
                            <p className="text-xs">Followers</p>
                          </div>
                          <div className="">
                            <h1 className="font-semibold">
                              {user?.no_of_post}
                            </h1>
                            <p className="text-xs">Posts</p>
                          </div>
                          <div className="">
                            <h1 className="font-semibold">
                              {user?.no_of_like}
                            </h1>
                            <p className="text-xs">Likes</p>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 p-2">
                          <Button
                            onClick={() => handleClickSwitchAccount({ user })}
                            size={"sm"}
                            className="text-white"
                          >
                            Switch Account
                          </Button>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </InfiniteScroll>
            </>
          ) : (
            <></>
          )}
        </div>
      </SettingContentLayout>

      <AlertDialog open={isAlertTypingPassword}>
        <form>
          <AlertDialogContent>
            <AlertDialogHeader className="relative">
              <X
                onClick={() => setIsAlertTypingPassword(false)}
                className="absolute top-0 right-0 cursor-pointer"
              />
            </AlertDialogHeader>
            <div className="flex justify-center items-center flex-col space-y-2">
              <ImageUrlProfile
                className="rounded-full w-[100px] h-[100px]"
                user={userDataClick}
              />
              <p className="font-semibold text-xl">{userDataClick.name}</p>
              <p className=" text-sm pb-10 ">{userDataClick.bio}</p>

              <div className="w-full mt-2">
                <InputHideShow
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
            <AlertDialogFooter>
              <AlertDialogAction
                onClick={handleClickPersonalSubmitForm}
                className="text-white w-full"
              >
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </form>
      </AlertDialog>
    </Layout>
  );
}
