import axios, { AxiosError, AxiosResponse } from "axios";
import packageJson from "../../package.json";

interface RequestApiParams {
  data?: any;
  method?: string;
  headers?: Record<string, string>;
}

export const RequestApi = async (
  url: string,
  { data = "", method = "POST", headers }: RequestApiParams = {}
): Promise<any> => {
  let hearderRequest = {};
  let newData = { ...data, ...{ version_no: `${packageJson.version}` } };

  if (!headers) {
    const browserId = localStorage.getItem("authentication_key");
    if (browserId) {
      hearderRequest = {
        Authorization: `Bearer ${browserId}`,
      };
    }
  } else {
    hearderRequest = { ...headers };
  }
  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    ...hearderRequest,
  };
  try {
    const response: AxiosResponse = await axios({
      url, // Use the baseURL from environment variables
      data: newData,
      method,
      headers: headersList,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      return {
        success: false,
        message: axiosError.message,
        status: axiosError.response?.status,
        data: axiosError.response?.data,
      };
    }
    return {
      success: false,
      message: "An unexpected error occurred",
    };
  }
};

export const RequestApiDisableHead = async (
  url: string,
  { data = {}, method = "POST" }
) => {
  return axios({
    url,
    data,
    method,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
