import { logoFlipFlop } from "assets";
import ImageUrl from "components/app/image-url/ImageUrl";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { AppOTPFunction, AppOTPProvider } from "models/appOTPFunction";
import { useState } from "react";
import ForgotPasswordPhone from "./ForgotPasswordPhone";
import OtpPage from "../otp/OtpPage";
type dataForm = {
  otp_receiver: string;
  otp_provider_type: AppOTPProvider;
  otp_function: AppOTPFunction;
  token: string;
};

export default function ForgotPasswordPhonePage() {
  const [otpData, setOTPData] = useState<dataForm>();
  const [showAlert, setAlert] = useState(false);
  const onSignup = async (dataForm: any) => {
    console.log(JSON.stringify(dataForm));
    const dataOtp = {
      otp_provider_type: AppOTPProvider.sms,
      otp_receiver: dataForm["password_reset"]["email"],
      otp_function: AppOTPFunction.resetPassword,
      token: dataForm["password_reset"]["token"],
    };
    setOTPData(dataOtp);
    setAlert(true);
  };
  return (
    <SinglePageCenter>
      {showAlert ? (
        <>
          {" "}
          <OtpPage
            showAlert={true}
            setShowAlert={() => showAlert}
            dataForm={otpData}
          />
        </>
      ) : (
        <></>
      )}
      <div className="flex justify-center items-center px-4 relative">
        <div className="text-center">
          <div className="pb-10 flex justify-center">
            <ImageUrl width={200} height={200} src={logoFlipFlop} alt="logo" />
          </div>
          <h1 className="text-[20px] font-bold pb-2">Reset Password</h1>
          <p className="pb-8">
            Input your phone number to receive the OTP code .
          </p>
          <ForgotPasswordPhone onSubmit={(data) => onSignup(data)} />
        </div>
      </div>
    </SinglePageCenter>
  );
}
