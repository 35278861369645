const FollowingIcon = ({
  color = "currentColor",
  width = "24px",
  height = "24px",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={width}
      height={height}
      viewBox="0 0 92.000000 92.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,92.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M431 820 c-39 -9 -77 -35 -104 -72 -18 -24 -24 -66 -19 -140 1 -16 2 -40 2 -55 0 -16 7 -33 15 -39 8 -6 15 -17 15 -24 0 -7 10 -30 22 -51 18 -32 20 -45 12 -76 -8 -36 -15 -41 -109 -88 -76 -38 -104 -58 -119 -83 -35 -63 -41 -62 319 -62 310 0 325 1 325 19 0 37 -44 84 -105 113 -128 60 -129 61 -138 100 -7 30 -5 46 12 81 12 24 21 47 21 51 0 4 7 14 15 22 13 13 18 56 17 162 -1 48 -32 95 -70 107 -18 5 -35 17 -38 27 -6 19 -19 21 -73 8z" />
        <path d="M228 727 c-22 -5 -50 -23 -68 -43 -28 -32 -30 -40 -29 -105 1 -53 7 -81 25 -115 13 -25 24 -58 24 -74 0 -27 -7 -33 -69 -64 -78 -40 -113 -77 -109 -115 3 -23 8 -26 50 -29 44 -3 48 -1 63 28 15 28 87 90 106 90 5 0 33 12 64 26 43 21 55 31 55 49 0 25 -25 85 -53 126 -13 20 -16 46 -15 127 0 57 -1 104 -3 106 -2 1 -21 -2 -41 -7z" />
        <path d="M660 734 c-12 -4 -15 -22 -13 -99 3 -90 1 -98 -32 -164 -50 -98 -47 -113 20 -145 31 -14 59 -26 64 -26 19 0 91 -62 106 -90 15 -29 19 -31 63 -28 l47 3 -1 37 c-2 50 -16 66 -101 104 -70 32 -73 35 -73 68 0 19 5 38 10 41 6 3 10 14 10 24 0 10 6 24 14 30 17 15 21 140 5 175 -6 13 -26 31 -45 39 -19 9 -34 21 -34 27 0 10 -12 11 -40 4z" />
      </g>
    </svg>
  );
};

export default FollowingIcon;
