import { AppOTPFunction, AppOTPProvider } from "models/appOTPFunction";

import FormSigUp from "./FormSignUp";
import OtpPage from "../otp/OtpPage";
import { useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
type dataForm = {
  otp_receiver: string;
  otp_provider_type: AppOTPProvider;
  otp_function: AppOTPFunction;
  token: string;
};
interface SubmitProps {
  isback: boolean;
  onSubmit: (value: string) => void;
}
export default function AlertSigUpPage({ isback, onSubmit }: SubmitProps) {
  const [otpData, setOTPData] = useState<dataForm>();
  const [showAlert, setAlert] = useState(false);
  const onSignup = async (dataForm: any) => {
    const dataOtp = {
      otp_provider_type: AppOTPProvider.sms,
      otp_receiver: dataForm["verification"]["username"],
      otp_function: AppOTPFunction.activationSignup,
      token: dataForm["verification"]["token"],
    };
    onSubmit("otp");
    setOTPData(dataOtp);
    setAlert(true);
  };
  const FormSchema = z.object({
    password: z.string().min(1, {
      message: "Password is required*",
    }),
    confirmPassword: z.string().min(1, {
      message: "Password is required*",
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  return (
    <div className="flex justify-center items-center px-4 relative">
      <div className="text-center">
        {showAlert && isback ? (
          <>
            <OtpPage
              showAlert={false}
              setShowAlert={() => showAlert}
              dataForm={otpData}
            />
          </>
        ) : (
          <>
            <p className="font-normal pt-5 pb-5">
              Create your profile to start your journal with FlipFlop social
              media.
            </p>
            <FormSigUp onSubmit={(data) => onSignup(data)} />
          </>
        )}
      </div>
    </div>
  );
}
