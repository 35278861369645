import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import { Snackbar } from "components";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useRef, useState } from "react";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { AppRecord, AppRecordStatus } from "models/appRecord";
import { LoaderCircle, Trash2 } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { toast } from "react-toastify";
import moment from "moment";

export default function LoggedDevices() {
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceLoading, setDeviceLoading] = useState<any>(null);
  const [userDevice, setUserDevice] = useState<any>(null);
  const { token } = useAppSelector((state) => state.auth);

  const deviceState = {
    currentPage: 1,
    currentIndex: 0,
    nextPageUrl: "",
    totalRecord: 0,
    loadingStatus: AppRecordStatus.loading,
  };

  const fetchMoreUserDevice = async ({ next }: { next: string }) => {
    console.log("next", next);

    setDeviceLoading({
      ...deviceState,
      loadingStatus: AppRecordStatus.loadingMore,
    });

    await RequestApi(next).then((responses) => {
      if (responses.status === "success") {
        const stateRecord: AppRecord = {
          currentPage: 1,
          currentIndex: 0,
          nextPageUrl: responses["tokens"]["next_page_url"] ?? "",
          totalRecord: 0,
          loadingStatus:
            responses["tokens"]["next_page_url"] !== null
              ? AppRecordStatus.noLoadMore
              : AppRecordStatus.loaded,
        };

        const tmpPosts = [
          ...userDevice["data"],
          ...responses["tokens"]["data"],
        ];

        const newPost = { ...userDevice, data: tmpPosts };
        setUserDevice(newPost);
        setDeviceLoading(stateRecord);
      }
    });
  };

  const fetchUserDevice = async ({
    isRefresh = false,
  }: {
    isRefresh?: boolean;
  }) => {
    try {
      if (isRefresh) {
        setLoading(true);
      }
      let url: string = "";

      url = RouteApi.getUserDevice;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            const stateRecord: AppRecord = {
              currentPage: 1,
              currentIndex: 0,
              nextPageUrl: responses["tokens"]["next_page_url"] ?? "",
              totalRecord: 0,
              loadingStatus:
                responses["tokens"]["next_page_url"] != null
                  ? AppRecordStatus.loaded
                  : AppRecordStatus.noLoadMore,
            };
            console.log("stateRecord", stateRecord);

            setUserDevice(responses["tokens"]);
            setDeviceLoading(stateRecord);
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });

      setLoading(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDevice({ isRefresh: true });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (tokenDevice: string) => {
    try {
      let url: string = "";
      url = RouteApi.submitRemoveUserDevice;
      url += `token=${token}`;
      url += `&access_token=${tokenDevice}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            const tmpUserDevice = { ...userDevice };
            tmpUserDevice.data = tmpUserDevice.data.filter(
              (element: any) => element.token !== tokenDevice
            );
            setUserDevice(tmpUserDevice);
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });
      setLoading(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
    }
  };

  return (
    <Layout>
      <SettingContentLayout>
        {!loading && (
          <div className="">
            <div className="flex items-center">
              <div>
                <h1 className="text-lg font-semibold">Logged Devices</h1>
                <p className="text-muted-foreground text-sm">
                  View and manage the devices currently signed into your account
                  for better security and control.
                </p>
              </div>
            </div>
            {userDevice ? (
              <>
                <InfiniteScroll
                  className={`relative min-h-[calc(100vh-160px)]`}
                  dataLength={userDevice["data"]?.length}
                  next={() =>
                    fetchMoreUserDevice({ next: deviceLoading.nextPageUrl })
                  }
                  hasMore={deviceLoading.nextPageUrl === "" ? false : true}
                  loader={
                    <div className="absolute bottom-2 left-0 w-full flex justify-center">
                      <LoaderCircle
                        size={30}
                        className="animate-spin text-yellow-400"
                      />
                    </div>
                  }
                  endMessage={
                    <p className="absolute bottom-0 left-0 w-full text-center">
                      <b>Yay! you have seen all devices.</b>
                    </p>
                  }
                >
                  <div className="mt-6 grid grid-cols-1 md:grid-cols-1 gap-4 mb-16">
                    {userDevice?.data?.map((item: any) => {
                      return (
                        <div
                          key={item.id}
                          className={`flex justify-between items-center border p-3 rounded-xl `}
                        >
                          <div className="flex items-center ">
                            <FontAwesomeIcon icon={faMobileScreen} size="2x" />
                            <div className="flex flex-col justify-center items-start -space-y-0.5 ms-2">
                              <h1 className=" font-semibold line-clamp-2">
                                {item.device_model}
                              </h1>
                              <p className="text-sm line-clamp-2">
                                {item.ip_address}
                              </p>
                            </div>
                          </div>
                          <div className="flex space-x-2">
                            <p className="text-sm">
                              {moment(item.last_activity_datetime).fromNow()}
                            </p>
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                <Trash2
                                  className={`text-destructive cursor-pointer`}
                                />
                              </AlertDialogTrigger>
                              <AlertDialogContent>
                                <AlertDialogHeader>
                                  <AlertDialogTitle>
                                    Do you want to delete this device?
                                  </AlertDialogTitle>
                                  <AlertDialogDescription>
                                    You are processing disactivate account. Your
                                    account will automatic activate when login
                                    into FlipFlop again!
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                                  <AlertDialogAction
                                    onClick={() => {
                                      handleSubmit(item.token);
                                    }}
                                    className="text-white bg-primary"
                                  >
                                    Continue
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </SettingContentLayout>
    </Layout>
  );
}
