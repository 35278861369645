import { format } from "date-fns";
import { PostReaction } from "models/postReaction";
import { StatusMsg } from "models/returnMsg";
import { AppLog } from "services";
import { RequestApi, RouteApi } from "utils";
import { Service } from "utils/services";

export async function onPostReaction({
  content,
  reaction,
  isLike = false,
  isFavorite = false,
  attachment,
}: {
  content: any;
  reaction: PostReaction;
  isLike?: boolean;
  isFavorite: boolean;
  attachment: any;
}) {
  try {
    const token = localStorage.getItem("jwt");
    const user = JSON.parse(localStorage.getItem("user") ?? "");
    const dateFormat = "yyyy-MM-dd HH:mm:ss";
    const formattedDate = format(new Date(), dateFormat);
    let url = RouteApi.uploadData;
    url += `token=${token}`;
    const synchId = await Service.getSynchID();
    const now = new Date();
    const postReaction = {
      user_id: content["user_id"].toString(),
      reaction_user_id: user["id"].toString(),
      post_id: content["provider_post_id"].toString(),
      attachment_id:
        attachment.toString() === "null" && attachment.toString() !== ""
          ? attachment["attachment_id"].toString()
          : "",
      provider_reaction_id: synchId,
      reaction_datetime: format(now, dateFormat),
      reaction_type: reaction.value,
      reaction_status: "Yes",
      reaction_second: 0,
      send_to_user_id: "",
      share_to_user_id: "",
      share_to_provider: "",
      share_to_provider_account_id: "",
      created_by: user["name"]?.toString(),
      created_at: format(now, dateFormat),
      synch_unid: synchId,
      synch_by: user["name"]?.toString(),
      synch_status: "New",
      synch_at: format(now, dateFormat),
    };
    if (reaction === PostReaction.like) {
      postReaction["reaction_status"] = isLike ? "Yes" : "No";
      const dataDynamicUpload = {
        post_reactions: [postReaction],
      };
      const dataUploadStr = dataDynamicUpload;
      const result = await RequestApi(url, { data: dataUploadStr });
      return result;
    } else if (reaction === PostReaction.favorite) {
      postReaction["reaction_status"] = isFavorite ? "Yes" : "No";
      const dataDynamicUpload = {
        post_reactions: [postReaction],
      };
      const result = RequestApi(url, { data: dataDynamicUpload });
      return result;
    }
  } catch (err) {
    AppLog({ message: `catch on signup ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
export async function submitRepostContent({
  providerPostId,
  medaiTitle,
}: {
  providerPostId: string;
  medaiTitle: string;
}) {
  try {
    const token = localStorage.getItem("jwt");
    let url = RouteApi.submitRepost;
    url += `token=${token}`;
    const respose = await RequestApi(url, {
      data: { post_id: providerPostId, media_share_title: medaiTitle },
    });
    return respose;
  } catch (err) {
    AppLog({ message: `catch on signup ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
export async function getComments({
  postId,
  attachmentId,
  nextPage,
}: {
  postId: string;
  attachmentId: string;
  nextPage: string;
}) {
  try {
    const token = localStorage.getItem("jwt");
    let url = "";
    if (nextPage === "") {
      url = RouteApi.getComment;
      url += `token=${token}&post_id=${postId}&attachment_id=${attachmentId}&reply_comment_id=0&page=2`;
    } else {
      url = nextPage;
    }

    const respose = await RequestApi(url);
    return respose;
  } catch (err) {
    AppLog({ message: `catch get comment ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
export async function getSubComments({
  postId,
  attachmentId,
  replyCommentId,
}: {
  postId: string;
  attachmentId: string;
  replyCommentId: string;
}) {
  try {
    const token = localStorage.getItem("jwt");
    let url = "";
    url = RouteApi.getComment;
    url += `token=${token}&post_id=${postId}&attachment_id=${attachmentId}&reply_comment_id=${replyCommentId}`;

    const respose = await RequestApi(url);
    return respose;
  } catch (err) {
    AppLog({ message: `catch get comment ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}

export async function postSubmitComment({ comment }: { comment: any }) {
  try {
    const token = localStorage.getItem("jwt");
    let url = RouteApi.submitPostComment;
    url += `token=${token}`;

    return await RequestApi(url, { data: comment });
  } catch (err) {
    AppLog({ message: `catch post comment ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
export async function postReactionComment({ data }: { data: any }) {
  try {
    const token = localStorage.getItem("jwt");
    let url = RouteApi.submitReactioncomment;
    url += `token=${token}`;

    return await RequestApi(url, { data: data });
  } catch (err) {
    AppLog({ message: `catch postReactionComment ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
