import { Button } from "components/ui/button";
import { cn } from "lib/utils";

export default function BtnFriendStatus({
  friendStatus,
  userType,
  onClick,
  className,
  style,
}: {
  friendStatus: string;
  userType: string;
  onClick: () => void;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <>
      {friendStatus === "Owner" ? (
        <></>
      ) : (
        <>
          {userType === "Personal" ? (
            <Button
              style={style}
              className={cn(``, className)}
              variant="destructive"
              onClick={onClick}
            >
              {friendStatus === "Following"
                ? "Unfollow"
                : friendStatus === "Friend"
                ? "Unfriend"
                : friendStatus === "Follower"
                ? "Follow Back"
                : "Follow"}
            </Button>
          ) : (
            <Button
              style={style}
              className={cn(``, className)}
              variant="destructive"
              onClick={onClick}
            >
              {friendStatus === "Following"
                ? "Unfollow"
                : friendStatus === "Follower"
                ? "Follow Back"
                : "Follow"}
            </Button>
          )}
        </>
      )}
    </>
  );
}
