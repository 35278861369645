export class RouteUrl {
  static welcome = "/";
  static notFound = "*";
  static login = "/login";
  static forgotPasswordPhone = "/forgot-password-phone";
  static changePassword = "/change-password";
  static deleteAccount = "/delete-account";
  static resetPassword = "/reset-password";
  static interestingCategory = "/interesting-category";
  static forgotPasswordEmail = "/forgot-password-mail";
  static signUp = "/sign-up";
  static dashboard = "/dashboard";
  static shopMenu = "/shop/menu";
  static termOfPrivacy = "/term-of-privacy";
  static termOfServices = "/term-of-services";
  static publicProfile = "/profile";
  static viewShop = "/view-shop";
  static viewProduct = "/view-product";
  static profilePostContentsPageView = "/post";
  static following = "/following";
  static newsFeed = "/news-feed";
  static marketPlaces = "/marketplaces";
  static profile = "/profile";
  static setting = "/setting";
  static chat = "/chat";
  static businessSuite = "/shop-detail";
  static blockAccount = "/block-account";
  static boringAccount = "/boring-account";
  static loggedDevices = "/logged-devices";
  static requestVerificationAccount = "/request-verification-account";
  static soundLibraries = "/sound-libraries";
  static desActivateAccount = "/desactivate-account";
  static deletePageStatus = "/delete-page-status";
  static disactivatePageStatus = "/desactivate-page-status";
  static changePasswordPageStatus = "/change-password-page-status";
  static interesting = "/interesting";
  static switchAccount = "/switch-account";
}
