// src/utils/deviceInfo.ts

// Define an interface to type the device information
export interface BrowserDeviceInfo {
  userAgent: string;
  browser_type: string;
  language: string;
  deviceMemory?: string;
  hardwareConcurrency?: number;
  screenResolution: string;
  isMobile: boolean;
  model: string;
  brand: string;
  browser_id: string;
}
export const convertDeviceInfoToMap = (deviceInfo: BrowserDeviceInfo) => {
  return {
    userAgent: deviceInfo.userAgent,
    browser_type: deviceInfo.browser_type,

    language: deviceInfo.language,
    deviceMemory: deviceInfo.deviceMemory,
    hardwareConcurrency: deviceInfo.hardwareConcurrency,
    screenResolution: deviceInfo.screenResolution,
    isMobile: deviceInfo.isMobile,
    model: deviceInfo.model,
    brand: deviceInfo.brand,
    browser_id: deviceInfo.browser_id,
  };
};
// Function to get browser/device information
export function getBrowserDeviceInfo(): BrowserDeviceInfo {
  const userAgent = navigator.userAgent;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  let deviceModel = "Unknown Device";
  let deviceBrand = "Unknown Brand";
  const deviceId = localStorage.getItem("device_id");
  // Detect if the user is on a mobile device
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  console.log(navigator.userAgent);
  // Device detection logic
  if (/iPhone/.test(userAgent)) {
    deviceModel = "iPhone";
    deviceBrand = "Apple";
  } else if (/iPad/.test(userAgent)) {
    deviceModel = "iPad";
    deviceBrand = "Apple";
  } else if (/iPod/.test(userAgent)) {
    deviceModel = "iPod";
    deviceBrand = "Apple";
  } else if (/Android/.test(userAgent)) {
    if (/SM-G/.test(userAgent)) {
      deviceModel = "Samsung Galaxy Device"; // Example for Samsung Galaxy models
      deviceBrand = "Samsung";
    } else if (/Pixel/.test(userAgent)) {
      deviceModel = "Google Pixel";
      deviceBrand = "Google";
    } else if (/Oppo/.test(userAgent)) {
      deviceModel = "Oppo Device";
      deviceBrand = "Oppo";
    } else if (/Huawei/.test(userAgent)) {
      deviceModel = "Huawei Device";
      deviceBrand = "Huawei";
    } else {
      deviceModel = "Android Device";
      deviceBrand = "Unknown Brand";
    }
  } else if (/Windows NT/.test(userAgent)) {
    deviceModel = "Windows PC";
    deviceBrand = "Microsoft";
  } else if (/Macintosh/.test(userAgent)) {
    deviceModel = "Mac";
    deviceBrand = "Apple";
  } else if (/Linux/.test(userAgent)) {
    deviceModel = "Linux Device";
    deviceBrand = "Unknown Brand";
  } else if (/CrOS/.test(userAgent)) {
    deviceModel = "Chrome OS Device";
    deviceBrand = "Google";
  }
  return {
    userAgent: navigator.userAgent,
    browser_type:
      /Chrome/.test(userAgent) &&
      !/Edge/.test(userAgent) &&
      !/OPR/.test(userAgent)
        ? "Chrome"
        : /Safari/.test(userAgent) &&
          !/Chrome/.test(userAgent) &&
          !/Edge/.test(userAgent)
        ? "Safari"
        : /Firefox/.test(userAgent)
        ? "Firefox"
        : /Edg/.test(userAgent) || /Edge/.test(userAgent)
        ? "Edge"
        : "IE11 ",

    language: navigator.language,
    deviceMemory: (navigator as any).deviceMemory, // Type assertion for optional property
    hardwareConcurrency: (navigator as any).hardwareConcurrency, // Type assertion for optional property
    model: deviceModel,
    brand: deviceBrand,
    screenResolution,
    isMobile,
    browser_id: deviceId ?? "",
  };
}
