import { Helmet } from "react-helmet-async";

export default function HelmetHtml({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>      
    </Helmet>
  );
}
