import { io, Socket } from "socket.io-client";

const SOCKET_URL = "http://192.168.100.20:80"; // Replace with your backend URL

interface ServerToClientEvents {
  connect: () => void;
  disconnect: () => void;
  error: (message: string) => void;
}

interface ClientToServerEvents {
  joinRoom: (room: string) => void;
}

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  SOCKET_URL,
  {
    autoConnect: true,
    query: {
      userId: "336",
      token: localStorage.getItem("jwt"),
    },
    extraHeaders: {
      authorization: `Bearer ${localStorage.getItem("authentication_key")}`,
    },
  }
);

// Handle connection errors
socket.on("connect_error", (error) => {
  console.error("Connection error:", error.message); // Log the error message
});

// Handle general errors
socket.on("error", (message) => {
  console.error("Socket error:", message);
});

export default socket;
