const HomeIcon = ({
  color = "currentColor",
  width = "24px",
  height = "24px",
  fontSize = "24px",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={width}
      height={height}
      fontSize={fontSize}
      viewBox="0 0 96.000000 96.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path d="M310 755 c-91 -73 -161 -136 -172 -156 -16 -29 -18 -61 -18 -251 0 -184 2 -219 16 -232 12 -13 39 -16 124 -16 142 0 140 -2 140 139 0 70 4 111 12 119 16 16 120 16 136 0 8 -8 12 -49 12 -119 0 -141 -2 -139 140 -139 85 0 112 3 124 16 24 23 24 430 0 480 -17 36 -320 285 -346 283 -7 0 -83 -56 -168 -124z" />
      </g>
    </svg>
  );
};

export default HomeIcon;
