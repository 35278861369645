import { format } from "date-fns";
import { ApplicationMode, AppMode } from "models/appMode";

export class Service {
  static applicationMode: AppMode = new AppMode(
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? ApplicationMode.RELEASE
      : ApplicationMode.DEBUG
  );
  static getSynchID = async (): Promise<string> => {
    const length = 50;
    const chars =
      "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";
    let synchId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      synchId += chars[randomIndex];
    }

    // Format the current date and time using date-fns
    const strTime = format(new Date(), "yyyyMMddHHmmss");
    synchId = synchId.substring(0, length - strTime.length) + strTime;

    return synchId;
  };
  static generateUUID = (): string => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
}

