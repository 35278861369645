import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import { Snackbar } from "components";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { useDispatch } from "react-redux";
import SearchBar from "components/app/search-bar/SearchBar";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import { Button } from "components/ui/button";
import { ShieldCheck } from "lucide-react";

export default function RequestVerificationAccount() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchGetProfile = async ({
    isRefresh = false,
  }: {
    isRefresh?: boolean;
  }) => {
    try {
      if (isRefresh) {
        setLoading(true);
      }
      let url: string = "";

      // url = RouteApi.getUserSetting;
      // url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
          setLoading(false);
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });

      setLoading(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGetProfile({ isRefresh: true });
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <SettingContentLayout>
        {!loading && (
          <div className="h-[calc(100vh-160px)]">
            <h1 className="text-lg font-semibold">
              Request Verification Account
            </h1>
            <div className="h-[calc(100vh-170px)] flex justify-center items-center">
              <div className="">
                <div className="flex justify-center">
                  <ShieldCheck size={70} className="text-primary text-center" />
                </div>
                <p className="font-semibold">Your account has been verified</p>
              </div>
            </div>
          </div>
        )}
      </SettingContentLayout>
    </Layout>
  );
}
