import BookMarkReaction from "components/app/reaction-activities/BookMarkReaction";
import CommentReaction from "components/app/reaction-activities/CommentReaction";
import LikeReaction from "components/app/reaction-activities/LikeReaction";
import ShareReaction from "components/app/reaction-activities/ShareReaction";
import Layout from "layout/layout";
import AlertWellcome from "pages/auth/alert/AlertWellcome";
import DownLoadApp from "pages/download/DownLoadApp";
import WelcomePage from "pages/welcome/welcome-page/WelcomePage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

export default function Following() {
  const [showAlert, setShowAlert] = useState(false);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  useEffect(() => {
    if (!isAuthenticated) setShowAlert(true);
  }, []);

  return (
    <>
      <Layout>
        {isAuthenticated ? (
          // <>
          //   <p>Following Page</p>
          //   <LikeReaction />
          //   <BookMarkReaction />
          //   <ShareReaction />
          //   <CommentReaction />
          // </>
          <DownLoadApp />
        ) : (
          <AlertWellcome showAlert={showAlert} setShowAlert={setShowAlert} />
        )}
      </Layout>
    </>
  );
}
