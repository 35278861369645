const baseURL = process.env.REACT_APP_API_URL; // Access API URL from .env

export class RouteApi {
  // User
  static login = `${baseURL}/login?`;
  static signUp = `${baseURL}/signup?`;
  static deleteAccount = `${baseURL}/delete-account?`;
  static submitChangePassword = `${baseURL}/submit-change-password?`;
  static submitUpdateOneSignal = `${baseURL}/submit-update-one-signal?`;
  static activation = `${baseURL}/activation?`;
  static resetPassword = `${baseURL}/reset-password?`;
  static activationResetPassword = `${baseURL}/reset-password-activation?`;
  static submitResetPassword = `${baseURL}/submit-reset-password?`;
  static loginByFacebook = `${baseURL}/login-by-facebook?`;
  static loginByGmail = `${baseURL}/login-by-gmail?`;
  static submitFollowingUser = `${baseURL}/submit-following-user?`;
  static submitUnFollowingUser = `${baseURL}/submit-unfollowing-user?`;
  static getPageUser = `${baseURL}/get-page-user?`;
  static getShopUser = `${baseURL}/get-user-shop?`;
  static getAccountShopDetail = `${baseURL}/get-account-shop-detail?`;
  static submitUpdateShopSetting = `${baseURL}/submit-update-shop-setting?`;

  // settting & accounts
  static getUserSetting = `${baseURL}/get-user-setting?`;
  static submitUpdateUserSetting = `${baseURL}/submit-update-user-setting?`;
  static getUserDevice = `${baseURL}/get-user-device?`;
  static submitRemoveUserDevice = `${baseURL}/submit-remove-user-device?`;
  static disactivateAccount = `${baseURL}/disactivate-account?`;
  static getBlockUser = `${baseURL}/get-block-user?`;
  static submitBlockUser = `${baseURL}/submit-block-user?`;
  static submitRemoveBlockUser = `${baseURL}/submit-remove-block-user?`;
  static getBoringUser = `${baseURL}/get-boring-user?`;
  static submitBoringUser = `${baseURL}/submit-boring-user?`;
  static submitRemoveBoringUser = `${baseURL}/submit-remove-boring-user?`;
  static submitUpdateUserInterest = `${baseURL}/submit-update-user-interest?`;
  static getUserUser = `${baseURL}/get-user-user?`;
  static switchAccount = `${baseURL}/switch-account?`;
  static switchLogin = `${baseURL}/switch-login?`;

  // user category
  static getCategory = `${baseURL}/get-category?`;

  // Store
  static getHomeMarketplaceQrMenu = `${baseURL}/get-home-marketplace-qr-menu?`;
  static getHomeMarketPlaceQrMenuMore = `${baseURL}/get-home-marketplace-qr-menu-more?`;
  static getPublicProfile = `${baseURL}/get-public-profile?`;
  static getProfile = `${baseURL}/get-profile?`;
  static getProfilePost = `${baseURL}/get-profile-post?`;
  static getViewShop = `${baseURL}/get-public-shop-profile?`;
  static getPublicProduct = `${baseURL}/get-public-shop-product?`;
  static getProfilePostContentsPageView = `${baseURL}/get-public-profile-post?`;
  static getHomeProfilePost = `${baseURL}/get-home-profile-post?`;

  static getPostWeb = `${baseURL}/get-post-web?`;
  static getHomeAccount = `${baseURL}/get-home-account?`;
  static getPostWebGuest = `${baseURL}/get-post-web-guest?`;

  // Communities
  static getFriendUser = `${baseURL}/get-friend-user?`;
  static getFollowerUser = `${baseURL}/get-follower-user?`;
  static getFollowingUser = `${baseURL}/get-following-user?`;
  // post
  static uploadData = `${baseURL}/upload-data?`;
  static submitRepost = `${baseURL}/submit-re-post?`;
  // comment
  static getComment = `${baseURL}/get-comment?`;
  static submitPostComment = `${baseURL}/submit-post-comment?`;
  static submitReactioncomment = `${baseURL}/submit-reaction-comment?`;
  // Upload Profile
  static getStorageHostCluster = `${baseURL}/get-storage-host-cluster?`;
}
