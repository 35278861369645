import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import BusinessSuiteContentLayout from "layout/business-suite-content/BusinessSuiteContentLayout";
import Layout from "layout/layout";
import { useAppSelector } from "store/store";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import {
  logoFacebook,
  noneProfile,
  profileVerified,
  restaurantMenu,
  statisticDashboard,
} from "assets";
import ImageUrl from "components/app/image-url/ImageUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RequestApi, RouteApi } from "utils";
import { useDispatch } from "react-redux";
import { setUserShopProfile } from "store/businessSuiteSlice";
import { AppLog } from "services";
import { StatusMsg } from "models/returnMsg";
import ImageUrlCoverProfile from "components/app/image-url/ImageUrlCoverProfile";
import { Camera, Eye, FileScan, QrCode, Settings } from "lucide-react";
import QrCodeGenerater from "components/app/qr-code-generater/QrCodeGenerater";
import ShopDetailUploadProfile from "components/app/upload-profile/ShopDetailUploadProfile";
import QrCodeGeneraterMenu from "components/app/qr-code-generater/QrCodeGeneraterMenu";

export default function BusinessSuite() {
  const { userShopProfile } = useAppSelector((state) => state.businessSuite);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shopId = searchParams.get("id");
  const [loadingShops, setLoadingShops] = useState<boolean>(false);
  const { token } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const fetchGetUserShopProfile = async () => {
    try {
      setLoadingShops(true);
      let url: string = "";
      url = RouteApi.getAccountShopDetail;
      url += `token=${token}`;

      const data = {
        shop_id: shopId,
      };

      await RequestApi(url, { data })
        .then((responses) => {
          if (responses.status === "success") {
            dispatch(setUserShopProfile(responses["user_shop"]));
          }
          setLoadingShops(false);
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });

      setLoadingShops(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoadingShops(false);
    }
  };

  useEffect(() => {
    if (shopId) {
      fetchGetUserShopProfile();
    }
  }, [shopId]);

  return (
    <Layout>
      <BusinessSuiteContentLayout>
        {userShopProfile ? (
          <>
            {/* CoverUserProfile */}
            <div className="">
              <div className="relative flex justify-center rounded-3xl w-full h-[200px] sm:h-[300px] md:h-[400px]">
                <Carousel className="h-full border-none">
                  <CarouselContent className="w-full h-[200px] sm:h-[300px] md:h-[400px] border-none rounded-b-3xl aspect-[16/9] bg-slate-400">
                    {userShopProfile.user_shop_cover_pictures.length > 0 ? (
                      <>
                        {userShopProfile.user_shop_cover_pictures.map(
                          (coverPicture: any, coverIndex: number) => {
                            return (
                              <CarouselItem
                                key={coverIndex}
                                className="rounded-3xl cursor-grab"
                              >
                                <ImageUrlCoverProfile
                                  src={coverPicture.picture}
                                  className="w-full h-[200px] sm:h-[300px] md:h-[400px] object-cover rounded-b-3xl aspect-[9/16]"
                                  alt="cover"
                                />
                              </CarouselItem>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <CarouselItem className="rounded-3xl">
                        <img
                          src={noneProfile}
                          className="w-full h-[200px] sm:h-[300px] md:h-[400px] object-cover rounded-b-3xl aspect-[9/16]"
                          alt="cover"
                        />
                      </CarouselItem>
                    )}
                  </CarouselContent>
                </Carousel>

                <button className="absolute bottom-4 right-6 border p-2 rounded-full bg-slate-50">
                  <Camera />
                </button>
              </div>

              {/* UserProfile */}
              <div className="relative flex justify-start">
                <div className="absolute -top-16 left-8 flex flex-col text-center">
                  <div className="relative">
                    <ImageUrl
                      src={userShopProfile.picture}
                      className="rounded-full w-[140px] h-[140px] border-4"
                    />

                    <button className="absolute bottom-2 right-1 p-1 rounded-full z-20 bg-slate-50">
                      <ShopDetailUploadProfile
                        onUploadSuccess={(valueSuccess: any) => {
                          const updateData = {
                            ...userShopProfile,
                            picture: valueSuccess.picture,
                          };
                          dispatch(setUserShopProfile(updateData));
                        }}
                        userShop={userShopProfile}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-end me-2 mt-2 space-x-2">
                <img
                  src={statisticDashboard}
                  alt="icon"
                  className="p-1 bg-slate-50 w-9 h-9 rounded-full border cursor-pointer"
                />

                <Eye className="p-1 bg-slate-50 w-9 h-9 rounded-full border cursor-pointer" />
                <QrCodeGeneraterMenu
                  userShop={userShopProfile}
                  linkUrl={`${process.env.REACT_APP_URL}/shop/menu?id=${userShopProfile.shop_id}`}
                  className="p-1 bg-slate-50 w-9 h-9 rounded-full border cursor-pointer"
                  logoIcon={<img src={restaurantMenu} alt="icon" />}
                />

                <QrCodeGenerater
                  linkUrl={`${process.env.REACT_APP_URL}/shop-detail?id=${userShopProfile.shop_id}`}
                  className="p-1 bg-slate-50 w-9 h-9 rounded-full border cursor-pointer"
                />
              </div>
            </div>

            {/* Profile Infomation */}
            <div className="px-4 space-y-3 mt-14">
              <div>
                {/* Name & Bio */}
                <div className="text-start space-y-2 mb-3">
                  <div className=" flex justify-start">
                    <div className="flex items-center">
                      <p className="text-lg lg:text-xl font-bold line-clamp-1">
                        {userShopProfile?.shop_name}
                      </p>
                    </div>
                    {userShopProfile?.is_verified === "Yes" ? (
                      <>
                        <div className="flex items-center justify-center pt-0.5 ml-1">
                          <img
                            src={profileVerified}
                            style={{ width: 24, height: 24 }}
                            alt=""
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <h1 className="text-md lg:text-md font-semibold line-clamp-2 text-muted-foreground">
                    {userShopProfile?.description}
                  </h1>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </BusinessSuiteContentLayout>
    </Layout>
  );
}
