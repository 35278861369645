import { logoFacebook, logoFlipFlop } from "assets";
import HelmetHtml from "components/app/helmet-html/HelmetHtml";
import ImageUrl from "components/app/image-url/ImageUrl";
import { Button } from "components/ui/button";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { Link, useNavigate } from "react-router-dom";
import { RouteUrl } from "utils";

export default function WelcomePage() {
  const navigate = useNavigate();

  return (
    <SinglePageCenter>
      <HelmetHtml title="Welcome" description="Welcome to FlipFlop" />
      <div id="welcome" className="text-center">
        <div className="pb-10 flex justify-center">
          <ImageUrl src={logoFlipFlop} width={200} height={200} alt="logo" />
        </div>
        <h1 className="text-[20px] font-bold pb-2">Welcome to FlipFlop</h1>
        <p className="pb-10 font-medium">
          We are connecting you with digital life.
        </p>

        <p className="pb-2">Continue with:</p>
        <div className="flex justify-center pb-4">
          <ImageUrl
            className="py-1 border rounded-xl cursor-pointer"
            src={logoFacebook}
            width={80}
            height={80}
            alt="facebook"
          />
        </div>
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          {/* <p className={`absolute font-medium -translate-x-1/2  left-1/2 `}>
    or
  </p> */}
        </div>
        <div className="w-full flex flex-col space-y-2">
          <Button
            onClick={() => navigate(RouteUrl.signUp)}
            className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white"
          >
            Create an account
          </Button>
          <Button
            onClick={() => navigate(RouteUrl.login)}
            variant="outline"
            className="w-full rounded-xl border-[#F3BE22] text-[#F3BE22] hover:text-[#f9d977]"
          >
            Login
          </Button>
        </div>

        <div className=" text-[15px] mt-3 text-left">
          <p className="mt-3">
            By signing up, you agree to the {""}
            <Link to={RouteUrl.termOfServices} className="w-full text-blue-500">
              Term of Service
            </Link>
            {""} and
          </p>

          <Link to={RouteUrl.termOfPrivacy} className="w-full text-blue-500">
            Privacy Policy .
          </Link>
        </div>
      </div>
    </SinglePageCenter>
  );
}
