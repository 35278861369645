import { useEffect, useState } from "react";

const useVideoPlayer = () => {
  const [volume, setVolume] = useState(() => {
    // Retrieve from localStorage or default to 0.5
    const savedVolume = localStorage.getItem("videoVolume");
    return savedVolume ? parseFloat(savedVolume) : 0.5;
  });

  const [isMuted, setIsMuted] = useState(() => {
    // Retrieve from localStorage or default to false
    const savedMuted = localStorage.getItem("isMuted");
    return savedMuted === "true"; // Convert string to boolean
  });

  useEffect(() => {
    // Store the volume in localStorage whenever it changes
    localStorage.setItem("videoVolume", volume.toString());
  }, [volume]);

  useEffect(() => {
    // Store the mute state in localStorage whenever it changes
    localStorage.setItem("isMuted", isMuted.toString());
  }, [isMuted]);

  return {
    volume,
    setVolume,
    isMuted,
    setIsMuted,
  };
};

export default useVideoPlayer;
