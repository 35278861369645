import { useState, useEffect, useRef } from "react";
import { noneProfile } from "assets";
import { Service } from "utils/services";
import { useNavigate } from "react-router-dom";
import { cn } from "lib/utils";
import { RouteUrl } from "utils";

export default function ImageUrlProfile({
  width,
  height,
  user,
  className,
  alt = "",
  isThumbnail = true,
  style,
  isLinkProfile = false,
}: {
  width?: number;
  height?: number;
  user: any;
  className?: string;
  alt?: string;
  isThumbnail?: boolean;
  style?: React.CSSProperties;
  isLinkProfile?: boolean;
}) {
  const [imageSrc, setImageSrc] = useState<string>(noneProfile);
  const cursorPointer = useRef<string>();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user || user === "") {
      setImageSrc(noneProfile);
    } else {
      let updatedSrc = isThumbnail
        ? user.thumbnail ?? "null"
        : user.picture ?? "null";

      if (
        Service.applicationMode.isDebug() &&
        !updatedSrc &&
        updatedSrc !== ""
      ) {
        updatedSrc = updatedSrc.replace("https", "http");
      }
      setImageSrc(updatedSrc);
    }
  }, [user, isThumbnail]); // Dependency array to update when `src` changes

  const handleError = () => {
    // Fallback to noneProfile if image fails to load

    setImageSrc(
      !user
        ? noneProfile
        : !user.avatar || user.avatar === "" || user.avatar === "null"
        ? noneProfile
        : `/${user.avatar}`
    );
  };

  if (isLinkProfile === true) {
    cursorPointer.current = "cursor-pointer";
  } else {
    cursorPointer.current = "";
  }

  const handleClick = () => {
    if (isLinkProfile === true) {
      navigate(`${RouteUrl.profile}?id=${user?.id}`);
    }
  };

  return (
    <img
      style={style}
      width={width}
      height={height}
      className={cn(`${cursorPointer.current}`, className)}
      src={imageSrc}
      alt={alt}
      onClick={handleClick}
      onError={handleError} // Fallback on error
    />
  );
}
