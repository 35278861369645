import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import authReducer from "./authSlice";
import menuReducer from "./menuSlice";
import profilePostContentsPageViewReducer from "./profilePostContentsPageViewSlice";
import welcomePostReducer from "./welcomePostSlice";
import profileReducer from "./profileSlice";
import businessSuiteReducer from "./businessSuiteSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    profilePostContentsPageView: profilePostContentsPageViewReducer,
    welcomePost: welcomePostReducer,
    profile: profileReducer,
    businessSuite: businessSuiteReducer,
    // Add other reducers here if necessary
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
