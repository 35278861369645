import { Snackbar } from "components";
import CarouselWithIndex from "components/app/carousel-with-index/CarouselWithIndex";
import VideoHoverPlay from "components/app/video-hover-play/VideoHoverPlay";
import { StatusMsg } from "models/returnMsg";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppLog } from "services";
import { RootState, useAppSelector } from "store/store";
import { setPost } from "store/welcomePostSlice";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import Masonry from "react-masonry-css";
import { ScrollArea, ScrollBar } from "components/ui/scroll-area";
import ImageHoverPlay from "components/app/image-hover-play/ImageHoverPlay";
import { LoaderCircle } from "lucide-react";
import ContentInfo from "components/app/content-info/ContentInfo";
import StatusHoverPlay from "components/app/status-hover-play/StatusHoverPlay";
import { useNavigate } from "react-router-dom";
import HelmetHtml from "components/app/helmet-html/HelmetHtml";
import socket from "services/socket";

const Explore: React.FC = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [itemGroups, setItemGroups] = useState<any[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const logMessage = (message: string) => {
    const timestamp = new Date().toISOString();
    const logEntry = `[${timestamp}] ${message}`;
    console.log(logEntry);
  };
  const dispatch = useDispatch();
  const { token } = useAppSelector((state) => state.auth);
  const { posts } = useAppSelector((state) => state.welcomePost);
  const [selectedProductGroupCode, setSelectedProductGroupCode] =
    useState<string>("All");
  const selectedProductGroupCodeRef = useRef(selectedProductGroupCode);

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const postIdRef = useRef<string[]>([]);

  const breakpointColumnsObj = {
    default: 6,
    1536: 6,
    1280: 5,
    1024: 4,
    768: 3,
    425: 2,
  };

  const fetchPost = async ({
    isRefresh = false,
  }: { isRefresh?: boolean } = {}) => {
    try {
      setIsLoading(true);
      let url: string = "";

      if (isAuthenticated) {
        url = RouteApi.getPostWeb;
        url += `token=${token}`;
      } else {
        url = RouteApi.getPostWebGuest;
      }

      const data = {
        post_ids: postIdRef.current,
        tag: "explore",
        category: selectedProductGroupCodeRef.current,
      };

      await RequestApi(url, { data })
        .then((responses) => {
          if (responses.status === "success") {
            const tmpPosts = isRefresh
              ? [...responses["posts"]]
              : [...posts, ...responses["posts"]];

            dispatch(setPost(tmpPosts));
            postIdRef.current.push(
              ...responses["posts"].map((morePost: any) => {
                return morePost?.id.toString();
              })
            );
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.failed });
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log(
      "Connect to socket",
      localStorage.getItem("authentication_key")
    );
    socket.on("connect", () => {
      setError(null); // Clear previous errors on successful connection
      logMessage(`Socket connected with ID: ${socket.id}`);
    });

    socket.on("disconnect", () => {
      logMessage("Socket disconnected");
    });

    socket.on("connect_error", (err) => {
      const errorMessage = `Connection error: ${err.message}`;
      setError(errorMessage);
      logMessage(errorMessage);
    });

    socket.on("error", (message) => {
      const errorMessage = `Socket error: ${message}`;
      setError(errorMessage);
      logMessage(errorMessage);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
      socket.off("error");
    };
  }, []);
  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1200
      ) {
        if (!isLoading) {
          fetchPost();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  useEffect(() => {
    if (posts.length === 0) {
      fetchPost();
    }
  }, []);

  const handleClickCategory = (groupCode: string) => {
    if (selectedProductGroupCode === groupCode) return;
    setSelectedProductGroupCode(groupCode);
    postIdRef.current = [];
    selectedProductGroupCodeRef.current = groupCode;
    fetchPost({ isRefresh: true });
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("item_groups");
    if (storedUser) {
      setItemGroups(JSON.parse(storedUser));
    }
  }, []);

  const onGoToPost = (postId: string) => {
    navigate(`${RouteUrl.profilePostContentsPageView}?post_id=${postId}`, {
      state: { data: { isBackNavigation: true } },
    });
  };

  const postContents = (item: any) => {
    if (item) {
      if (item?.media_type === "photo") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative">
              <div onClick={() => onGoToPost(item.post_id)}>
                <ImageHoverPlay post={item} />
              </div>
              <ContentInfo post={item} />
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else if (item?.media_type === "album") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative">
              <div onClick={() => onGoToPost(item.post_id)}>
                <CarouselWithIndex
                  item={item}
                  imgClassName="w-full h-auto object-cover rounded-lg cursor-pointer"
                  indexClassName=""
                />
              </div>
              <ContentInfo post={item} />
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else if (item?.media_type === "video") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative">
              <div onClick={() => onGoToPost(item.post_id)}>
                <VideoHoverPlay post={item} videoClassName="rounded-xl" />
              </div>
              <ContentInfo post={item} />
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else if (item?.media_type === "status") {
        if (item?.post_attachments.length > 0) {
          return (
            <div className="relative" onClick={() => onGoToPost(item.post_id)}>
              <StatusHoverPlay post={item} />
              <ContentInfo post={item} />
            </div>
          );
        } else {
          return (
            <img
              src="https://via.placeholder.com/150x300"
              className="w-full h-auto rounded-lg"
              alt=""
            />
          );
        }
      } else {
        return (
          <img
            src="https://via.placeholder.com/150x300"
            className="w-full h-auto rounded-lg"
            alt=""
          />
        );
      }
    } else
      return (
        <img
          src="https://via.placeholder.com/150x300"
          className="w-full h-auto rounded-lg"
          alt=""
        />
      );
  };

  return (
    <div className="mx-auto px-4 py-2">
      <HelmetHtml title="Explore" description="this is explore page" />
      <ScrollArea className="w-full grid grid-cols-1 px-2 pb-3 pt-2 mb-2">
        <div className="flex">
          <p
            onClick={() => handleClickCategory("All")}
            className={`p-1 border rounded-xl px-2 mr-2 cursor-pointer font-semibold ${
              selectedProductGroupCode === "All"
                ? "bg-black text-white"
                : "bg-gray-200"
            }`}
          >
            All
          </p>
          {itemGroups?.map((group) => {
            return (
              <div
                key={group.id}
                className={`p-1 border rounded-xl px-2 mr-2 cursor-pointer font-semibold ${
                  selectedProductGroupCode === group?.code.toString()
                    ? "bg-black text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => handleClickCategory(group?.code.toString())}
              >
                <p className="text-nowrap">{group?.code}</p>
              </div>
            );
          })}
        </div>
        <ScrollBar className="cursor-grab" orientation="horizontal" />
      </ScrollArea>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {posts.map((post: any, index: number) => (
          <div
            id={`${post?.id}`}
            key={index}
            className="relative group mb-4 break-inside-avoid bg-whiterounded-lg rounded-xl bg-black"
          >
            {postContents(post)}
          </div>
        ))}
      </Masonry>

      {/* <div ref={loader} className="w-full flex justify-center">
        <LoaderCircle size={30} className="animate-spin text-yellow-400" />
      </div> */}
      {isLoading && (
        <div className="w-full flex justify-center">
          <LoaderCircle size={30} className="animate-spin text-yellow-400" />
        </div>
      )}
    </div>
  );
};

export default Explore;
