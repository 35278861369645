"use client";

import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { MapProvider } from "./map-provider/MapProvider";

export const defaultMapContainerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "15px 15px",
};

// const center = { lat: 11.527316, lng: 104.959075 };

export default function GoogleMapPreview({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}) {
  const [center, setCenter] = useState<any>({
    lat: Number(latitude),
    lng: Number(longitude),
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  return (
    <MapProvider>
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={defaultMapContainerStyle}
      >
        <Marker onClick={() => setOpen(true)} position={center} />
        {open && (
          <InfoWindow position={center} onCloseClick={() => setOpen(false)}>
            <p>This is me!</p>
          </InfoWindow>
        )}
      </GoogleMap>
    </MapProvider>
  );
}
