// ProgressBar.tsx
import "./ProgressBar.css"; // Import the CSS for custom styling
import React from "react";

interface ProgressBarProps {
  value: number; // A number between 0 and 1
  onChange: (value: number) => void; // Function to handle changes
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, onChange }) => {
  const playedColor = "rgba(224, 224, 224, 0.8)";
  const unplayedColor = "rgba(224, 224, 224, 0.3)";

  return (
    <input
      type="range"
      min={0}
      max={1}
      step={0.01}
      onClick={(e) => e.stopPropagation()}
      value={value}
      onChange={(e) => onChange(parseFloat(e.target.value))}
      className="custom-progress-bar"
      style={{
        background: `linear-gradient(to right, ${playedColor} ${
          value * 100
        }%, ${unplayedColor} ${value * 100}%)`,
      }}
    />
  );
};

export default ProgressBar;
