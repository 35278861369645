import { deleteAccountDocument } from "assets";
import { Button } from "components/ui/button";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { House } from "lucide-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store/authSlice";

export default function DeleteAccountPageStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  const btnBackToHome = () => {
    navigate("/");
  };

  return (
    <SinglePageCenter>
      <div className="max-w-md xl:max-w-lg flex justify-center flex-col items-center text-center">
        <img src={deleteAccountDocument} alt="icon" />
        <p className="text-15px md:text-base 2xl:text-[18px] leading-6 md:leading-7 font-medium">
          Your account is currently being deleted!
        </p>
        <p className="text-15px md:text-base 2xl:text-[18px] text-muted-foreground leading-6 md:leading-7 font-medium">
          This process will permanently remove your account information, data,
          and any associated content. If this deletion was not intended, please
          login again in 30 days.
        </p>
        <Button
          variant="outline"
          onClick={btnBackToHome}
          className="text-[13px] md:text-sm lg:text-[15px] leading-4 inline-flex items-center font-medium cursor-pointer transition ease-in-out duration-300 bg-brand-dark text-black px-4 md:px-6 py-2.5 lg:py-3 hover:text-black bg-opacity-90 hover:bg-opacity-100 rounded-md mt-2"
        >
          <div className="flex items-center">
            <House size={18} className="" />
            <span className="ps-2">Back to Home</span>
          </div>
        </Button>
      </div>
    </SinglePageCenter>
  );
}
