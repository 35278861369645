import { AppOTPFunction, AppOTPProvider } from "models/appOTPFunction";
import { useState } from "react";
import OtpPage from "../otp/OtpPage";
import { RouteUrl } from "utils";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import ResetForm from "../reset_password/ResetForm";
import ForgotPasswordEmail from "./ForgotPasswordEmail";
type dataForm = {
  otp_receiver: string;
  otp_provider_type: AppOTPProvider;
  otp_function: AppOTPFunction;
  token: string;
};
interface SubmitProps {
  isback: boolean;
  onSubmit: (value: string) => void;
}
export default function AlertForgotPasswordEmailPage({
  isback,
  onSubmit,
}: SubmitProps) {
  const [showAlert, setAlert] = useState(false);
  const [isRests, setIsReset] = useState(false);
  const [otpData, setOTPData] = useState<dataForm>();
  const [dataReset, setDataReset] = useState<any>({});
  const onForgotPassword = async (dataForm: any) => {
    console.log(`---reset ${JSON.stringify(dataForm)}`);
    const dataOtp = {
      otp_provider_type: AppOTPProvider.email,
      otp_receiver: dataForm["password_reset"]["reset_by"],
      otp_function: AppOTPFunction.resetPassword,
      token: dataForm["password_reset"]["token"],
    };
    onSubmit("otp");
    setOTPData(dataOtp);
    setAlert(true);
  };
  // do after otp
  const onReset = async (data: any) => {
    setDataReset(data);
    console.log(JSON.stringify(dataReset));
    onSubmit(RouteUrl.resetPassword);
    setAlert(false);
    setIsReset(true);
  };
  return (
    <div className="flex justify-center items-center px-4 relative">
      <div className="text-center">
        {showAlert && isback ? (
          <>
            <OtpPage
              showAlert={false}
              setShowAlert={() => showAlert}
              dataForm={otpData}
              onReset={(value) => onReset(value)}
            />
          </>
        ) : isRests ? (
          <div className="flex justify-center items-center px-4 relative">
            <div className="text-center">
              <div className="pb-5 flex justify-center">
                <ImageUrlProfile
                  isLinkProfile={false}
                  user={dataReset.user}
                  className="rounded-full object-cover border shadow-md shadow-gray-600"
                  alt="user"
                  style={{ width: 100, height: 100 }}
                />
              </div>
              <h1 className="font-semibold text-2xl">{dataReset.user.name}</h1>
              <p className="pt-5 pb-5">
                Input the new password and confirmation password.
              </p>
              <ResetForm token={dataReset.token} />
            </div>
          </div>
        ) : (
          <>
            <p className="font-normal pt-5 pb-5">
              Input your email address to receive the OTP code.
            </p>
            <ForgotPasswordEmail onSubmit={(data) => onForgotPassword(data)} />
          </>
        )}
      </div>
    </div>
  );
}
