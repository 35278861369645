import React from "react";
import "./Marquee.css"; // Import your CSS file

interface MarqueeProps {
  text: string;
}

const Marquee: React.FC<MarqueeProps> = ({ text }) => {
  return (
    <div className="marquee-container">
      <div className="marquee">{text}</div>
    </div>
  );
};

export default Marquee;
