import { ChevronDown, ChevronUp } from "lucide-react";

interface ArrowUpDownProps {
  value: number;
  isDown: boolean;
  handleDown: () => void;
  handleUp: () => void;
}
export default function ArrowUpDown({
  value,
  isDown,
  handleDown,
  handleUp,
}: ArrowUpDownProps) {
  return (
    <div className="absolute right-8 max-sm:right-3  items-center select-none z-50">
      <ChevronUp
        className={`size-12 max-sm:size-8 p-1 cursor-pointer rounded-full bg-opacity-10 bg-black   ${
          value === 0 ? "text-gray-400" : "text-white"
        }`}
        onClick={handleDown}
      />
      <ChevronDown
        className={`size-12 p-1 max-sm:size-8 cursor-pointer rounded-full bg-opacity-10 bg-black mt-2 ${
          isDown ? "text-gray-400" : "text-white"
        }`}
        onClick={handleUp}
      />
    </div>
  );
}
