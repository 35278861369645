import {
  MaximizeIcon,
  Minimize,
  Play,
  Volume2,
  VolumeOffIcon,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import useVideoPlayer from "./useVideoPlayer";
import ProgressBar from "./ProgressBar";
export default function VideoPostContent({
  postAttchment,
}: {
  postAttchment: any;
}) {
  const [aspectRatio, setAspectRatio] = useState(16 / 9);
  const [isPlaying, setPlaying] = useState(true);
  const [isLandscape, setIsLandscape] = useState(true);
  const playerRef = useRef<ReactPlayer>(null);
  const { volume, setVolume, isMuted, setIsMuted } = useVideoPlayer();
  const [showVolumeInput, setShowVolumeInput] = useState(false);
  const [played, setPlayed] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  let isLargeScreen = window.innerWidth >= 1024;
  const handleReady = (player: any) => {
    const videoWidth = player.getInternalPlayer().videoWidth;
    const videoHeight = player.getInternalPlayer().videoHeight;
    setAspectRatio(videoWidth / videoHeight);
    setIsLandscape(videoWidth >= videoHeight);
  };

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
    if (isMuted && volume === 0) {
      setVolume(0.5); // Set a default volume when unmuting
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      videoContainerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const onSeekVolume = (volume: number) => {
    setVolume(volume);
    if (volume === 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  };

  const handleSeekChange = (e: number) => {
    setPlayed(e);
    playerRef.current?.seekTo(e);
  };

  const handleProgress = (progress: {
    played: number;
    playedSeconds: number;
  }) => {
    setPlayed(progress.played);
    setCurrentTime(progress.playedSeconds); // Update current time
  };

  const handlePlayPause = () => {
    setPlaying(!isPlaying);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <div
      ref={videoContainerRef}
      className={` flex items-center relative w-full lg:h-screen h-[60vh] ${
        isFullscreen ? "fixed inset-0 w-screen h-screen z-[9999] bg-black" : ""
      }`}
    >
      <div
        style={{ aspectRatio: `${aspectRatio}` }}
        className={`${
          isLargeScreen || isLandscape ? "" : "video-container"
        }  relative ${
          isFullscreen
            ? "flex w-full h-screen items-center justify-center"
            : isLandscape
            ? "flex items-center justify-center aspect-w-16 aspect-h-9"
            : "flex items-center justify-center object-cover   lg:h-screen h-[60vh]"
        }`}
        onClick={handlePlayPause}
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        <ReactPlayer
          url={
            postAttchment?.post_attachments[0]?.attachment_media_image_file_src
          }
          controls={false}
          ref={playerRef}
          playing={isPlaying}
          muted={isMuted}
          volume={volume}
          width="100%"
          height={`${isLandscape ? "100%" : "100%"}`}
          onReady={handleReady}
          loop={true}
          onProgress={handleProgress}
          onDuration={handleDuration}
          style={{ objectFit: isFullscreen ? "contain" : "cover" }}
        />

        {/* Play/Pause Icon */}
        <div className="absolute inset-0 flex items-center justify-center text-white text-6xl">
          {!isPlaying && (
            <Play
              className="text-white shadow-sm size-16"
              onClick={handlePlayPause}
            />
          )}
        </div>

        {showControls && (
          <div className={`absolute inset-0 flex items-end justify-between `}>
            <div className="flex flex-col w-full mx-3 space-y-2">
              <ProgressBar value={played} onChange={handleSeekChange} />
              <div
                onMouseMove={() => setShowVolumeInput(true)}
                onMouseLeave={() => setShowVolumeInput(false)}
                className="flex items-center justify-between"
              >
                <div className="flex items-center">
                  <button
                    className="text-white"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {isMuted ? (
                      <VolumeOffIcon
                        className="rounded-full bg-opacity-10 bg-black p-1 max-sm:size-6 size-8"
                        onClick={toggleMute}
                      />
                    ) : (
                      <Volume2
                        className="rounded-full bg-opacity-10 bg-black p-1 max-sm:size-6 size-8"
                        onClick={toggleMute}
                      />
                    )}
                  </button>

                  {showVolumeInput && (
                    <input
                      type="range"
                      min={0}
                      max={1}
                      onClick={(e) => e.stopPropagation()}
                      step={0.01}
                      value={volume}
                      onChange={(e) => onSeekVolume(parseFloat(e.target.value))}
                      className="max-sm:w-20 w-24 ml-3"
                    />
                  )}
                  <div className="text-white rounded-full bg-opacity-10 bg-black pl-1 pr-1 ml-3 max-sm:text-sm">
                    {formatDuration(currentTime)} / {formatDuration(duration)}
                  </div>
                </div>

                {/* Fullscreen Control */}
                <button
                  className="text-white"
                  onClick={(e) => e.stopPropagation()}
                >
                  {isFullscreen ? (
                    <Minimize
                      className="rounded-full bg-opacity-10 bg-black max-sm:size-6 p-1 size-8"
                      onClick={toggleFullscreen}
                    />
                  ) : (
                    <MaximizeIcon
                      className="rounded-full bg-opacity-10 bg-black p-1 max-sm:size-6 size-8"
                      onClick={toggleFullscreen}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
