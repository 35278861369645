import { cn } from "lib/utils";
import ImageUrlThumbnail from "../image-url/ImageUrlThumbnail";

export default function ImageHoverPlay({ post }: { post: any }) {
  return (
    <>
      <div
        key={post?.post_attachments[0].id}
        style={{
          aspectRatio: `${
            post?.post_attachments[0]?.attachment_media_width ?? 16
          } / ${post?.post_attachments[0]?.attachment_media_height ?? 9}`,
        }}
        className={cn(`w-full flex h-full rounded-xl cursor-pointer`)}
      >
        <div className="">
          <ImageUrlThumbnail
            thumbnail={
              post?.post_attachments[0]?.attachment_thumbnail_image_file_src
            }
            src={post?.post_attachments[0]?.attachment_media_image_file_src}
            alt=""
            className={`absolute flex w-full h-full top-0 left-0 object-cover rounded-xl`}
          />
        </div>
      </div>
    </>
  );
}
