import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from "react";
import { Eye, EyeOff } from "lucide-react";
import { Input } from "components/ui/input";

export default function InputHideShow({
  value,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  disabled,
  maxLength,
  minLength,
  ...props
}: {
  maxLength?: number;
  minLength?: number;
  name?: string;
  value?: string | number | readonly string[] | undefined;
  placeholder?: string | undefined;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  disabled?: boolean | undefined;
}) {
  const [showPassword, setShowPassword] = useState("password");

  const toggleShowPassword = () => {
    if (showPassword === "text") {
      setShowPassword("password");
    } else {
      setShowPassword("text");
    }
  };

  return (
    <div className="relative">
      <Input
        type={showPassword}
        minLength={minLength}
        maxLength={maxLength}
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        {...props}
      />
      {showPassword === "password" ? (
        <Eye
          onClick={() => toggleShowPassword()}
          className={`absolute right-3 top-2 cursor-pointer `}
        />
      ) : (
        <EyeOff
          onClick={() => toggleShowPassword()}
          className={`absolute right-3 top-2 cursor-pointer`}
        />
      )}
    </div>
  );
}
