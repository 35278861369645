import { Label } from "components/ui/label";
import { Switch } from "components/ui/switch";
import { useEffect, useState } from "react";

interface SwitchYesNoProps {
  id: string;
  value: string;
  label: string;
  icon: React.ReactNode;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export default function SwitchYesNoWithLabel({
  id,
  value,
  label,
  icon,
  disabled,
  onChange,
}: SwitchYesNoProps) {
  const [selected, setSelected] = useState<boolean>(
    value === "Yes" ? true : false
  );

  const handleToggle = () => {
    if (selected === true) {
      setSelected(false);
      onChange("No");
    } else {
      setSelected(true);
      onChange("Yes");
    }
  };

  useEffect(() => {
    setSelected(value === "Yes" ? true : false);
  }, [value]);

  return (
    <div className="flex justify-between items-center w-full hover:bg-slate-100 p-2 rounded-md">
      <Label
        htmlFor={id}
        className="font-medium cursor-pointer flex items-center space-x-2"
      >
        <div>{icon}</div>
        <p>{label}</p>
      </Label>
      <Switch
        id={id}
        checked={selected === true}
        onClick={() => handleToggle()}
        disabled={disabled}
      />
    </div>
  );
}
