import { StatusMsg } from "models/returnMsg";
import { AppLog } from "services";
import { RequestApi, RouteApi } from "utils";

type dataSignUp = {
  phone_no: string;
  password: string;
  full_name: string;
  app_key: "LyCqMkT2uxL1JAy9UofOmhCnBeJCEblWYOrfHWCL";
  country_code: "+855";
  signature: "";
};

export async function singup({ data }: any) {
  try {
    const result = await RequestApi(RouteApi.signUp, {
      data: data,
    });
    return result;
  } catch (err) {
    AppLog({ message: ` catch on signup ${err}` });
    return { status: StatusMsg.failed, message: `${err}` };
  }
}
