import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import { useEffect, useState } from "react";
import PhotoPostContent from "../photo-post-content/PhotoPostContent";
import { ZoomInIcon, ZoomOutIcon } from "lucide-react";

export default function AlbumPostContent({ post }: { post: any }) {
  const [count, setCount] = useState(0);
  const [current, setCurrent] = useState(0);
  const [api, setApi] = useState<CarouselApi>();
  const [scale, setScale] = useState(1);
  const maxZoom = 1.8;
  const minZoom = 1;

  const handleZoomIn = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    if (scale < maxZoom) {
      setScale((prev) => Math.min(prev + 0.1, maxZoom)); // Increment zoom
    }
  };

  const handleZoomOut = (event: React.MouseEvent<SVGElement>) => {
    event.preventDefault();
    if (scale > minZoom) {
      setScale((prev) => Math.max(prev - 0.1, minZoom)); // Decrement zoom
    }
  };

  const handleOnChange = (index: number) => {
    setCurrent(index + 1);
  };

  useEffect(() => {
    if (!api) {
      return;
    }
    setCount(post?.post_attachments.length);
  }, [api, post]);
  return (
    <>
      <Carousel setApi={setApi} onSlideChange={handleOnChange}>
        <CarouselContent>
          {post?.post_attachments.map((item: any, index: number) => {
            return (
              <CarouselItem
                className="flex justify-center items-center"
                key={index}
              >
                <div
                  key={item?.id}
                  style={{
                    aspectRatio: `${item?.attachment_media_width ?? 16} / ${
                      item?.attachment_media_height ?? 9
                    }`,
                  }}
                >
                  <div className="flex justify-center items-center  ">
                    <PhotoPostContent
                      post={item}
                      isAlbum={true}
                      scaleAlbum={scale}
                    />
                  </div>
                </div>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>

      <div className="absolute max-sm:top-3 max-sm:right-3 top-5 right-8 flex space-x-2 z-50 select-none">
        <div
          className={`font-semibold text-xs bg-gray-600 text-white opacity-70 max-sm:p-1 p-2    rounded-full `}
        >
          {current}/{count}
        </div>
        <ZoomInIcon
          onClick={handleZoomIn}
          className={`w-8 h-8 max-sm:w-6 max-sm:h-6 bg-gray-600 text-white opacity-70 rounded-full p-1 transition-opacity duration-200 cursor-pointer ${
            scale >= maxZoom
              ? "opacity-50 cursor-not-allowed"
              : "hover:opacity-100"
          }`}
        />
        <ZoomOutIcon
          onClick={handleZoomOut}
          className={`w-8 h-8 max-sm:w-6 max-sm:h-6 bg-gray-600 text-white opacity-70 rounded-full p-1 transition-opacity duration-200 cursor-pointer ${
            scale <= minZoom
              ? "opacity-50 cursor-not-allowed"
              : "hover:opacity-100"
          }`}
        />
      </div>
    </>
  );
}
