import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { Loader, Mail, Phone } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AppLog } from "services";
import { RequestApi, RouteApi } from "utils";
import { z } from "zod";
interface SubmitProps {
  onSubmit: (value: any) => void;
}
export default function ForgotPasswordEmail({ onSubmit }: SubmitProps) {
  const [loading, setLoading] = useState<boolean>();
  const [errorState, setErrorState] = useState<string>("");
  const FormSchema = z.object({
    email: z.string().min(1, {
      message: "Email number is required*",
    }),
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
    },
  });
  const onForgotPassword = async (data: z.infer<typeof FormSchema>) => {
    try {
      setErrorState("");
      setLoading(true);
      await RequestApi(RouteApi.resetPassword, {
        data: {
          reset_option: "EMAIL",
          reset_by: data.email,
          signature: "",
        },
      }).then((result) => {
        if (result.status === "success") {
          onSubmit(result);
        } else {
          console.log(`error ${result.msg}`);
          setErrorState(result.msg);
        }
        setLoading(false);
      });
    } catch (err) {
      AppLog({ message: `Catch on submit ${err}` });
      setLoading(false);
    }
  };
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onForgotPassword)}
        className="text-start space-y-4"
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  <div
                    className={`absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none`}
                  >
                    <Mail />
                  </div>
                  <Input
                    type="email"
                    className="rounded-xl pl-12 text-black"
                    placeholder="Email"
                    {...field}
                  />
                </div>
              </FormControl>
              <div className="text-red-600">
                <FormMessage />
              </div>
            </FormItem>
          )}
        />

        <div className="text-red-600">{errorState}</div>

        <div className="w-full flex flex-col">
          <Button className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white">
            {loading ? <Loader className="animate-spin" /> : "Send"}
          </Button>
        </div>
      </form>
    </Form>
  );
}
