import { logoFlipFlop, qrFlipFlopAndroid, qrFlipFlopIOS } from "assets";
import { Snackbar } from "components";
import ImageUrl from "components/app/image-url/ImageUrl";
import SearchParamsNotFound from "components/app/search-params-not-found/SearchParamsNotFound";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppLog } from "services";
import { RequestApi, RouteApi } from "utils";

export default function ViewShop() {
  const userAgent = navigator.userAgent;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [userShop, setUserShop] = useState<any>();
  const [searchID, setSearchID] = useState<boolean>(false);
  const fetchProfile = async ({
    isRefresh = false,
  }: { isRefresh?: boolean; id?: string } = {}) => {
    try {
      let url: string = "";

      if (isRefresh) {
        url = RouteApi.getViewShop;
        url += `id=${id}`;
      }

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            setUserShop(responses["user_shop"]);
            if (responses["user_shop"] === null) {
              setSearchID(true);
            }
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    }
  };

  useEffect(() => {
    if (id) {
      fetchProfile({ isRefresh: true, id });
    }
    // eslint-disable-next-line
  }, []);

  const qrDetecting = () => {
    // IOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl width={100} height={100} src={qrFlipFlopIOS} alt="logo" />
          </div>

          <Link to="https://apps.apple.com/us/app/flipflop-social-network/id6465576581">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
        </div>
      );
    }
    // ANDROID
    else if (/android/i.test(userAgent)) {
      return (
        <div className="mt-2">
          <div className="flex justify-center mb-2">
            <ImageUrl
              width={100}
              height={100}
              src={qrFlipFlopAndroid}
              alt="logo"
            />
          </div>
          <Link to="https://play.google.com/store/apps/details?id=com.maxx4business.flip_flop&hl=en">
            <Button className="font-semibold text-sm">
              Yes, I want to download!
            </Button>
          </Link>
        </div>
      );
    }
    // Other Device
    else {
      return (
        <div className="mt-2 w-full">
          <div className="flex justify-evenly w-full mb-2">
            <div>
              <p className="font-semibold text-sm">App Store</p>
              <ImageUrl
                width={100}
                height={100}
                src={qrFlipFlopIOS}
                alt="ios-qr"
              />
            </div>
            <div>
              <p className="font-semibold text-sm">Google Play</p>
              <ImageUrl
                width={100}
                height={100}
                src={qrFlipFlopAndroid}
                alt="android-qr"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {!id || id === "" || searchID ? (
        <SearchParamsNotFound params="id" />
      ) : (
        <SinglePageCenter>
          <div className="w-full flex flex-col justify-center items-center text-center">
            <div className="w-full flex justify-center mb-2">
              <ImageUrl
                width={120}
                height={120}
                className="rounded-full border drop-shadow-lg"
                src={userShop?.thumbnail ? userShop?.thumbnail : logoFlipFlop}
                alt="logo"
              />
            </div>
            <p className="font-semibold text-2xl">{userShop?.shop_name}</p>
            <p className="line-clamp-3 text-sm text-muted-foreground">
              {userShop?.shop_category}
            </p>
            <div className="container">
              <Separator className="my-1" />
            </div>

            <div className="mt-2 text-center">
              <p className="">សូមទាញយកកមម្មវិធីបណ្តាញសង្គម FlipFlop</p>
              <p>ដើម្បីទទួលពិនិត្យមើលទំនិញថ្មីៗរបស់ហាងយើងខ្ញុំ!</p>
              <p className="mt-1">
                Download FlipFlop Social Media and follow us to view our
                products and services!
              </p>

              <p className="mt-1 text-sm text-muted-foreground">
                Scan QR code below to download
              </p>

              <div className="">{qrDetecting()}</div>
            </div>
          </div>
        </SinglePageCenter>
      )}
    </>
  );
}
