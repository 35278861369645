export default function StatusPostContent({ post }: { post: any }) {
  let textPost = JSON.parse(post?.media_style);

  if (!textPost) return <></>;

  let subStringBackgroundColor: string = textPost?.background_color;
  let subStringColor: string = textPost?.text_style?.color;

  if (subStringBackgroundColor.length && subStringColor.length === 8) {
    subStringBackgroundColor = subStringBackgroundColor.substring(2);
    subStringColor = subStringColor.substring(2);
  } else {
    subStringColor = "ffffff";
    subStringBackgroundColor = "000000";
  }

  const textSize = textPost?.text_style?.font_size * 0.65;

  return (
    <div
      style={{
        aspectRatio: `9 / 16`,
        overflow: "hidden",
        backgroundColor: `#${subStringBackgroundColor}`,
      }}
      className="flex  max-w-[720px]   lg:h-screen h-[60vh] items-center text-center justify-center"
    >
      <p
        style={{
          color: `#${subStringColor}`,
          fontSize: textSize,
        }}
        className="text-center whitespace-pre-line"
      >
        {textPost?.text}
      </p>
    </div>
  );
}
