import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Snackbar } from "components";
import SwitchYesNoWithLabel from "components/app/function/SwitchYesNoWithLabel";
import Layout from "layout/layout";
import SettingContentLayout from "layout/setting-content/SettingContentLayout";
import { Loader } from "lucide-react";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useState } from "react";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { useDispatch } from "react-redux";
import SwitchValueWithLabelAndSameAPIKey from "components/app/function/SwitchValueWithLabelAndSameAPIKey";
import {
  faPeopleArrows,
  faHeart,
  faBriefcase,
  faPersonWalkingLuggage,
  faUserTie,
  faMagnifyingGlass,
  faPeopleGroup,
  faArrowsDownToPeople,
  faEye,
  faEnvelope,
  faLock,
  faCommentSms,
  faEnvelopesBulk,
  faMessage,
  faBookmark,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "components/ui/button";

export default function SettingAndSecurity() {
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const { token } = useAppSelector((state) => state.auth);
  const [recordUserSetting, setRecordUserSetting] = useState<any>(null);

  const fetchGetProfile = async () => {
    try {
      let url: string = "";

      url = RouteApi.getUserSetting;
      url += `token=${token}`;

      await RequestApi(url)
        .then((responses) => {
          if (responses.status === "success") {
            setRecordUserSetting(responses["user"]);
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });

      // setLoadingGetProfile(false);
    }
  };

  const onSubmitUserSetting = async () => {
    try {
      let url: string = "";
      setLoadingSubmit(true);

      url = RouteApi.submitUpdateUserSetting;
      url += `token=${token}`;

      await RequestApi(url, { data: { user: recordUserSetting } })
        .then((responses) => {
          if (responses.status === "success") {
            setRecordUserSetting(responses["user"]);
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }

          setLoadingSubmit(false);
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
        });

      setLoadingSubmit(false);
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });

      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    fetchGetProfile();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <SettingContentLayout>
        <div>
          <h1 className="text-lg font-semibold">
            Setting, Secuity, and Privacy
          </h1>
          <p className="text-muted-foreground text-sm">
            Manage and customize your account preferences to enhance your
            experience, Protect your account with tools and options to keep your
            information safe, Control who can see your information and manage
            your data-sharing preferences.
          </p>
          {recordUserSetting ? (
            <>
              <div className="grid grid-cols-1 mt-4 gap-4">
                {/* I am looking for partners */}
                <div className="space-y-2 border p-2 rounded-lg">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon fontSize={22} icon={faHandshake} />
                    <h1 className="font-semibold">I am looking for partner</h1>
                  </div>
                  <SwitchValueWithLabelAndSameAPIKey
                    value={recordUserSetting?.looking_for_partner}
                    datas={[
                      {
                        id: "Friends",
                        icon: (
                          <FontAwesomeIcon
                            fontSize={22}
                            icon={faPeopleArrows}
                          />
                        ),
                        label: "Friends",
                        isCheck: false,
                      },
                      {
                        id: "Love",
                        icon: <FontAwesomeIcon fontSize={22} icon={faHeart} />,
                        label: "Love",
                        isCheck: false,
                      },
                      {
                        id: "Business",
                        icon: (
                          <FontAwesomeIcon fontSize={22} icon={faBriefcase} />
                        ),
                        label: "Business",
                        isCheck: false,
                      },
                      {
                        id: "Job",
                        icon: (
                          <FontAwesomeIcon
                            fontSize={22}
                            icon={faPersonWalkingLuggage}
                          />
                        ),
                        label: "Job",
                        isCheck: false,
                      },
                      {
                        id: "Staff",
                        icon: (
                          <FontAwesomeIcon fontSize={22} icon={faUserTie} />
                        ),
                        label: "Staff",
                        isCheck: false,
                      },
                    ]}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.looking_for_partner = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                </div>

                {/* Who can search my profile */}
                <div className="space-y-2 border p-2 rounded-lg">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon fontSize={22} icon={faMagnifyingGlass} />
                    <h1 className="font-semibold">Who can search my profile</h1>
                  </div>
                  <SwitchValueWithLabelAndSameAPIKey
                    value={recordUserSetting?.allow_search_privacy}
                    datas={[
                      {
                        id: "Everyone",

                        icon: (
                          <FontAwesomeIcon
                            fontSize={22}
                            icon={faPeopleArrows}
                          />
                        ),
                        label: "Everyone",
                        isCheck: false,
                      },
                      {
                        id: "Friends",

                        icon: (
                          <FontAwesomeIcon fontSize={22} icon={faPeopleGroup} />
                        ),
                        label: "My friends",
                        isCheck: false,
                      },
                      {
                        id: "Followers",

                        icon: (
                          <FontAwesomeIcon
                            fontSize={22}
                            icon={faArrowsDownToPeople}
                          />
                        ),
                        label: "My followers",
                        isCheck: false,
                      },
                      {
                        id: "Following",
                        icon: (
                          <FontAwesomeIcon
                            fontSize={22}
                            icon={faArrowsDownToPeople}
                          />
                        ),
                        label: "My following",
                        isCheck: false,
                      },
                    ]}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.allow_search_privacy = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                </div>

                {/* Who can see my post */}
                <div className="space-y-2 border p-2 rounded-lg">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon fontSize={22} icon={faEye} />
                    <h1 className="font-semibold">Who can see my post</h1>
                  </div>
                  <SwitchValueWithLabelAndSameAPIKey
                    value={recordUserSetting?.who_can_see_my_post}
                    datas={[
                      {
                        id: "Everyone",
                        icon: (
                          <FontAwesomeIcon
                            fontSize={22}
                            icon={faPeopleArrows}
                          />
                        ),
                        label: "Everyone",
                        isCheck: false,
                      },
                      {
                        id: "Friends",
                        icon: (
                          <FontAwesomeIcon fontSize={22} icon={faPeopleGroup} />
                        ),
                        label: "My friends",
                        isCheck: false,
                      },
                      {
                        id: "Follower",

                        icon: (
                          <FontAwesomeIcon
                            fontSize={22}
                            icon={faArrowsDownToPeople}
                          />
                        ),
                        label: "My follower",
                        isCheck: false,
                      },
                    ]}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.who_can_see_my_post = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                </div>

                {/* Enable Login 2 Factors */}
                <div className="space-y-2 border p-2 rounded-lg">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon fontSize={22} icon={faEnvelopesBulk} />
                    <h1 className="font-semibold">Enable Login 2 Factors</h1>
                  </div>
                  <SwitchValueWithLabelAndSameAPIKey
                    value={recordUserSetting?.is_enable_two_factor_login}
                    datas={[
                      {
                        id: "None",
                        icon: <FontAwesomeIcon fontSize={22} icon={faLock} />,
                        label: "None",
                        isCheck: false,
                      },
                      {
                        id: "SMS",
                        icon: (
                          <FontAwesomeIcon fontSize={22} icon={faCommentSms} />
                        ),
                        label: "SMS",
                        isCheck: false,
                      },
                      {
                        id: "Email",

                        icon: (
                          <FontAwesomeIcon fontSize={22} icon={faEnvelope} />
                        ),
                        label: "Email",
                        isCheck: false,
                      },
                    ]}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_enable_two_factor_login = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                </div>

                {/* Notification Alert Settings */}
                <div className="space-y-2 border p-2 rounded-lg">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon fontSize={22} icon={faEnvelopesBulk} />
                    <h1 className="font-semibold">
                      Notification Alert Settings
                    </h1>
                  </div>
                  <SwitchYesNoWithLabel
                    icon={<FontAwesomeIcon fontSize={22} icon={faEye} />}
                    id="is_notify_view_profile"
                    label="User view my profile"
                    value={recordUserSetting?.is_notify_view_profile}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_view_profile = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                  <SwitchYesNoWithLabel
                    icon={
                      <FontAwesomeIcon fontSize={22} icon={faPeopleGroup} />
                    }
                    id="is_notify_user_following_post"
                    label="User following post a content"
                    value={recordUserSetting?.is_notify_user_following_post}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_user_following_post = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                  <SwitchYesNoWithLabel
                    icon={<FontAwesomeIcon fontSize={22} icon={faMessage} />}
                    id="is_notify_user_comment_post"
                    label="User comment on my post"
                    value={recordUserSetting?.is_notify_user_comment_post}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_user_comment_post = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                  <SwitchYesNoWithLabel
                    icon={<FontAwesomeIcon fontSize={22} icon={faHeart} />}
                    id="is_notify_user_like_post"
                    label="User like on my post"
                    value={recordUserSetting?.is_notify_user_like_post}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_user_like_post = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                  <SwitchYesNoWithLabel
                    icon={<FontAwesomeIcon fontSize={22} icon={faBookmark} />}
                    id="is_notify_user_save_post"
                    label="User save on my post"
                    value={recordUserSetting?.is_notify_user_save_post}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_user_save_post = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                  <SwitchYesNoWithLabel
                    icon={<FontAwesomeIcon fontSize={22} icon={faShare} />}
                    id="is_notify_user_share_post"
                    label="User share on my post"
                    value={recordUserSetting?.is_notify_user_share_post}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_user_share_post = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                  <SwitchYesNoWithLabel
                    icon={
                      <FontAwesomeIcon fontSize={22} icon={faPeopleGroup} />
                    }
                    id="is_notify_user_start_following"
                    label="User start following me"
                    value={recordUserSetting?.is_notify_user_start_following}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_user_start_following = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />

                  <SwitchYesNoWithLabel
                    icon={<FontAwesomeIcon fontSize={22} icon={faUserTie} />}
                    id="is_notify_user_new_join"
                    label="New user join FlipFlop"
                    value={recordUserSetting?.is_notify_user_new_join}
                    onChange={(value) => {
                      const tmpUserSetting = { ...recordUserSetting };
                      tmpUserSetting.is_notify_user_new_join = value;
                      setRecordUserSetting(tmpUserSetting);
                    }}
                  />
                </div>
              </div>
              <Button
                onClick={onSubmitUserSetting}
                className="mt-4 w-full bg-destructive text-white bg-blue-600 hover:bg-blue-500"
              >
                {loadingSubmit ? (
                  <Loader className="animate-spin text-white" />
                ) : (
                  "Save"
                )}
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
      </SettingContentLayout>
    </Layout>
  );
}
