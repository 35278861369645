import React from "react";

export default function SinglePageCenter({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col justify-center items-center h-[calc(100vh-7rem)]">
      <div className="invisible" />
      <div>{children}</div>
      <div className="invisible" />
    </div>
  );
}
