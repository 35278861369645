import { useState, useEffect } from "react";
import { iconNoProduct } from "assets";
import { Service } from "utils/services";

export default function ImageUrl({
  width,
  height,
  src,
  className,
  alt = "",
  style,
}: {
  width?: number;
  height?: number;
  src: any;
  className?: string;
  alt?: string;
  style?: React.CSSProperties;
}) {
  const [imageSrc, setImageSrc] = useState<string>(iconNoProduct);

  useEffect(() => {
    if (!src || src === "") {
      setImageSrc(iconNoProduct);
    } else {
      let updatedSrc = src;
      if (Service.applicationMode.isDebug()) {
        updatedSrc = src.replace("https", "http");
      }
      setImageSrc(updatedSrc);
    }
  }, [src]); // Dependency array to update when `src` changes

  const handleError = () => {
    // Fallback to iconNoProduct if image fails to load
    setImageSrc(iconNoProduct);
  };

  return (
    <img
      style={style}
      width={width}
      height={height}
      className={className}
      src={imageSrc}
      alt={alt}
      onError={handleError} // Fallback on error
    />
  );
}
