import axios from "axios";
import { Label } from "components/ui/label";
import { Cropper, CropperRef } from "react-advanced-cropper";
import imageCompression from "browser-image-compression";
import {
  Camera,
  FlipHorizontal2,
  FlipVertical2,
  Loader,
  Maximize,
  Plus,
  RotateCcw,
  RotateCw,
  ScanEye,
  X,
} from "lucide-react";
import { ChangeEvent, useRef, useState } from "react";
import "react-advanced-cropper/dist/themes/compact.css";
import "react-advanced-cropper/dist/style.css";

import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { cn } from "lib/utils";
import { compressFileWithURL } from "utils/comppressionHelper";
import { Service } from "utils/services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { Snackbar } from "components/Snackbar";
import { StatusMsg } from "models/returnMsg";
import { RequestApiDisableHead } from "utils/requestApi";
import { AppLog } from "services";

export default function ShopDetailUploadProfile({
  userShop,
  onUploadSuccess,
}: {
  userShop: any;
  onUploadSuccess: any;
}) {
  const [image, setImage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cropperRef = useRef<CropperRef>(null);
  const [onOpen, setOnOpen] = useState<boolean>(false);
  const inputFile = useRef<any>(null);
  const [fileValue, setFileValue] = useState<any>();
  const [showCropImage, setShowCropImage] = useState("");
  const { token } = useAppSelector((state) => state.auth);

  let base64 = useRef("");

  const onChangeCropper = (cropper: CropperRef) => {
    // console.log(cropper.getCoordinates(), cropper.getCanvas());
    // setImageCrop(cropper);
    // setImage(cropper.getCanvas()?.toDataURL());
  };

  const onChangeInputFile = (event: ChangeEvent<HTMLInputElement>) => {
    setFileValue(event.target.files && event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setImage(
        URL.createObjectURL(event.target.files && event.target.files[0])
      );
    }
  };

  const onFlip = (horizontal: boolean, vertical: boolean) => {
    const cropper = cropperRef.current;
    if (cropper) {
      cropper.flipImage(horizontal, vertical);
    }
  };

  const onRotate = (angle: number) => {
    const cropper = cropperRef.current;
    if (cropper) {
      cropper.rotateImage(angle);
    }
  };

  const onResize = (
    width: number,
    height: number,
    left: number,
    top: number
  ) => {
    if (cropperRef.current) {
      cropperRef.current.setCoordinates({
        width: width,
        height: height,
        left: left,
        top: top,
      });
    }
  };

  const defaultSize = ({
    imageSize,
    visibleArea,
  }: {
    imageSize: any;
    visibleArea: any;
  }) => {
    return {
      width: (visibleArea || imageSize).width,
      height: (visibleArea || imageSize).height,
    };
  };

  const onPreview = () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const canvas = cropper.getCanvas();
      setShowCropImage(canvas?.toDataURL() || "");
    }
  };

  const onCrop = async ({ fileValue }: { fileValue: any }) => {
    setIsLoading(true);
    const cropper = cropperRef.current;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 200,
      useWebWorker: true,
    };

    let pictureUrl = "";
    let pictureSrc = "";
    let thumbnialUrl = "";
    let thumbnialSrc = "";

    let uploadFile: any = "";

    if (cropper) {
      const canvas = cropper.getCanvas();
      base64.current = canvas?.toDataURL() || "";

      const compressedFile = await compressFileWithURL(
        base64.current,
        fileValue.name,
        fileValue.lastModified
      );

      // APIRequest
      let storageHostClusterId = "";
      let hostName = "";
      let url: string = "";

      if (!token) {
        throw new Error("Invalid or missing token");
      }

      url = RouteApi.getStorageHostCluster;
      url += `token=${token}`;

      await RequestApi(url, {
        data: {
          token,
          file_size: compressedFile.size,
        },
      })
        .then((responses) => {
          if (responses.status === "success") {
            storageHostClusterId = responses.storage_host_cluster.id;
            hostName = responses.storage_host_cluster.host_name;
          }
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });

      let url1 = `${hostName}/api-mobile-flipflop/v1/submit-upload-files?`;
      url1 += `token=${token}`;
      url1 += `&storage_host_cluster_id=${storageHostClusterId}`;

      const myPictureFile = new File(
        [compressedFile],
        `${compressedFile.name}`,
        {
          type: "files",
        }
      );

      const compressedFileThumnail = await imageCompression(
        compressedFile,
        options
      );

      const myThumnailFile = new File(
        [compressedFileThumnail],
        `${compressedFileThumnail.name}`,
        {
          type: "files",
        }
      );

      const formdata = new FormData();
      formdata.append("profile_picture", myPictureFile);
      formdata.append("thubmnail_picture", myThumnailFile);

      const reqOptions = {
        url: url1,
        method: "POST",
        data: formdata,
      };

      await axios
        .request(reqOptions)
        .then((res) => {
          uploadFile = res.data.upload_files;
          return res;
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });

      let url2 = `${hostName}/api-mobile-flipflop/v1/submit-update-shop-picture?`;
      url2 += `token=${token}`;

      pictureUrl = uploadFile[0]["attachment_url"];
      pictureSrc = uploadFile[0]["attachment_source"];
      thumbnialUrl = uploadFile[1]["attachment_url"];
      thumbnialSrc = uploadFile[1]["attachment_source"];

      await RequestApiDisableHead(url2, {
        data: {
          shop_id: userShop.shop_id,
          picture: pictureUrl,
          picture_src: pictureSrc,
          provider_storage_host_id: storageHostClusterId,
          thumbnail: thumbnialUrl,
          thumbnail_src: thumbnialSrc,
        },
      })
        .then((res) => {
          if (res.data.status === "success") {
            onUploadSuccess(res.data.user_shop);
            setShowCropImage("");
            setImage("");
            setOnOpen(false);
          } else {
            Snackbar({
              message: res["msg"],
              status: StatusMsg.getType(res["status"]),
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
          setIsLoading(false);
        });
    }
  };

  return (
    <Dialog open={onOpen}>
      <DialogTrigger onClick={() => setOnOpen(true)} asChild>
        <Camera className="z-20" />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[1020px]">
        <DialogHeader>
          <DialogTitle>Upload Profile</DialogTitle>
        </DialogHeader>
        <X
          className="absolute right-3 top-3 z-40 cursor-pointer"
          onClick={() => setOnOpen(false)}
        />
        {!image && (
          <Label
            htmlFor="file"
            className="flex border items-center justify-center cursor-pointer rounded-lg py-10"
          >
            <Plus />
            <p>Select Photo</p>
          </Label>
        )}

        <div className="grid grid-cols-2">
          <div>
            {image && (
              <Cropper
                className={cn("default-cropper__cropper cropper h-[375px]")}
                stencilProps={{
                  previewClassName: "preview-cropper",
                  aspectRatio: 12 / 12,
                }}
                ref={cropperRef}
                src={image}
                // defaultSize={defaultSize}
                onChange={onChangeCropper}
              />
            )}

            {image && (
              <div className="flex space-x-2 justify-center mt-6">
                <Button onClick={() => onFlip(true, false)} className="">
                  <FlipHorizontal2 />
                </Button>
                <Button onClick={() => onFlip(false, true)} className="">
                  <FlipVertical2 />
                </Button>
                <Button onClick={() => onRotate(90)} className="">
                  <RotateCcw />
                </Button>
                <Button onClick={() => onRotate(-90)} className="">
                  <RotateCw />
                </Button>

                <Button
                  onClick={() => onResize(100000, 100000, 0, 0)}
                  className=""
                >
                  <Maximize />
                </Button>

                {image && (
                  <Button
                    onClick={() => onPreview()}
                    className="hover:text-gray-500 text-md"
                  >
                    <ScanEye />
                  </Button>
                )}
              </div>
            )}
          </div>

          <input
            id="file"
            type="file"
            ref={inputFile}
            onChange={onChangeInputFile}
            hidden
            accept=".png, .jpg, .jpeg"
          />

          {!showCropImage && <div className="h-[375px] w-[375px]" />}

          <div className="flex justify-center">
            {showCropImage && (
              <img className="h-[375px] w-[375px]" src={showCropImage} alt="" />
            )}
          </div>
        </div>

        <DialogFooter>
          <Button
            variant="destructive"
            onClick={() => {
              setShowCropImage("");
              setImage("");
            }}
          >
            Clear
          </Button>
          <Button
            onClick={() => {
              onCrop({ fileValue });
            }}
          >
            {isLoading ? <Loader className="animate-spin" /> : "Upload"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
