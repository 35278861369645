import TextInput from "components/app/function/TextInput";
import { StatusMsg } from "models/returnMsg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppLog } from "services";
import { useAppSelector } from "store/store";
import { RequestApi, RouteApi } from "utils";
import { setUserShopProfile } from "store/businessSuiteSlice";
import SwitchYesNoWithLabel from "components/app/function/SwitchYesNoWithLabel";
import InputHideShow from "components/app/function/InputHideShow";
import { SelectScrollable } from "components/app/function/SelectScrollable";
import InputSelectAutoSuggest from "components/app/function/InputSelectAutoSuggest";

export default function SettingShopDetail({ shopId }: { shopId: any }) {
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { userShopProfile, isSaveSetting } = useAppSelector(
    (state) => state.businessSuite
  );

  const submitUserShopSetting = async () => {
    try {
      let url: string = "";
      url = RouteApi.submitUpdateShopSetting;
      url += `token=${token}`;

      const data = {
        shop_id: shopId,
        shop: userShopProfile,
      };

      await RequestApi(url, { data })
        .then((responses) => {
          if (responses.status === "success") {
          }
        })
        .catch((err) => {
          AppLog({ message: err.toString(), status: StatusMsg.error });
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
    }
  };

  useEffect(() => {
    if (isSaveSetting) {
      submitUserShopSetting();
    }
  }, [isSaveSetting]);

  return (
    <>
      {userShopProfile ? (
        <div className="p-2 space-y-4">
          <div className="space-y-2">
            <p className="font-semibold text-lg">Number Precision</p>
            <div>
              <p className="mb-1">Quntity</p>
              <TextInput
                value={userShopProfile?.quantity_decimal}
                onChange={(event) => {
                  const tmpUserShop = {
                    ...userShopProfile,
                    quantity_decimal: event.target.value,
                  };
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
            <div>
              <p className="mb-1">Price</p>
              <TextInput
                value={userShopProfile?.price_decimal}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };
                  tmpUserShop.price_decimal = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div>
              <p className="mb-1">Amount</p>
              <TextInput
                value={userShopProfile?.amount_decimal}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };
                  tmpUserShop.amount_decimal = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Currency</p>
            <div>
              <p className="mb-1">Code</p>
              <InputSelectAutoSuggest
                value={userShopProfile?.currency_code}
                onChange={(answer: any) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.currency_code = answer.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
                datas={[
                  { value: "AED", label: "AED", symbol: "د.إ" },
                  { value: "AFN", label: "AFN", symbol: "؋" },
                  { value: "ALL", label: "ALL", symbol: "L" },
                  { value: "AMD", label: "AMD", symbol: "֏" },
                  { value: "ANG", label: "ANG", symbol: "ƒ" },
                  { value: "AOA", label: "AOA", symbol: "Kz" },
                  { value: "ARS", label: "ARS", symbol: "$" },
                  { value: "AUD", label: "AUD", symbol: "$" },
                  { value: "AWG", label: "AWG", symbol: "ƒ" },
                  { value: "AZN", label: "AZN", symbol: "₼" },
                  { value: "BAM", label: "BAM", symbol: "KM" },
                  { value: "BBD", label: "BBD", symbol: "$" },
                  { value: "BDT", label: "BDT", symbol: "৳" },
                  { value: "BGN", label: "BGN", symbol: "лв" },
                  { value: "BHD", label: "BHD", symbol: ".د.ب" },
                  { value: "BIF", label: "BIF", symbol: "FBu" },
                  { value: "BMD", label: "BMD", symbol: "$" },
                  { value: "BND", label: "BND", symbol: "$" },
                  { value: "BOB", label: "BOB", symbol: "Bs." },
                  { value: "BRL", label: "BRL", symbol: "R$" },
                  { value: "BSD", label: "BSD", symbol: "$" },
                  { value: "BTN", label: "BTN", symbol: "Nu." },
                  { value: "BWP", label: "BWP", symbol: "P" },
                  { value: "BYN", label: "BYN", symbol: "Br" },
                  { value: "BZD", label: "BZD", symbol: "$" },
                  { value: "CAD", label: "CAD", symbol: "$" },
                  { value: "CDF", label: "CDF", symbol: "FC" },
                  { value: "CHF", label: "CHF", symbol: "CHF" },
                  { value: "CLP", label: "CLP", symbol: "$" },
                  { value: "CNY", label: "CNY", symbol: "¥" },
                  { value: "COP", label: "COP", symbol: "$" },
                  { value: "CRC", label: "CRC", symbol: "₡" },
                  { value: "CUP", label: "CUP", symbol: "$" },
                  { value: "CVE", label: "CVE", symbol: "$" },
                  { value: "CZK", label: "CZK", symbol: "Kč" },
                  { value: "DJF", label: "DJF", symbol: "Fdj" },
                  { value: "DKK", label: "DKK", symbol: "kr" },
                  { value: "DOP", label: "DOP", symbol: "$" },
                  { value: "DZD", label: "DZD", symbol: "دج" },
                  { value: "EGP", label: "EGP", symbol: "£" },
                  { value: "ERN", label: "ERN", symbol: "Nfk" },
                  { value: "ETB", label: "ETB", symbol: "Br" },
                  { value: "EUR", label: "EUR", symbol: "€" },
                  { value: "FJD", label: "FJD", symbol: "$" },
                  { value: "FKP", label: "FKP", symbol: "£" },
                  { value: "FOK", label: "FOK", symbol: "kr" },
                  { value: "GBP", label: "GBP", symbol: "£" },
                  { value: "GEL", label: "GEL", symbol: "₾" },
                  { value: "GGP", label: "GGP", symbol: "£" },
                  { value: "GHS", label: "GHS", symbol: "₵" },
                  { value: "GIP", label: "GIP", symbol: "£" },
                  { value: "GMD", label: "GMD", symbol: "D" },
                  { value: "GNF", label: "GNF", symbol: "FG" },
                  { value: "GTQ", label: "GTQ", symbol: "Q" },
                  { value: "GYD", label: "GYD", symbol: "$" },
                  { value: "HKD", label: "HKD", symbol: "$" },
                  { value: "HNL", label: "HNL", symbol: "L" },
                  { value: "HRK", label: "HRK", symbol: "kn" },
                  { value: "HTG", label: "HTG", symbol: "G" },
                  { value: "HUF", label: "HUF", symbol: "Ft" },
                  { value: "IDR", label: "IDR", symbol: "Rp" },
                  { value: "ILS", label: "ILS", symbol: "₪" },
                  { value: "IMP", label: "IMP", symbol: "£" },
                  { value: "INR", label: "INR", symbol: "₹" },
                  { value: "IQD", label: "IQD", symbol: "ع.د" },
                  { value: "IRR", label: "IRR", symbol: "﷼" },
                  { value: "ISK", label: "ISK", symbol: "kr" },
                  { value: "JEP", label: "JEP", symbol: "£" },
                  { value: "JMD", label: "JMD", symbol: "$" },
                  { value: "JOD", label: "JOD", symbol: "د.ا" },
                  { value: "JPY", label: "JPY", symbol: "¥" },
                  { value: "KES", label: "KES", symbol: "Sh" },
                  { value: "KGS", label: "KGS", symbol: "лв" },
                  { value: "KHR", label: "KHR", symbol: "៛" },
                  { value: "KID", label: "KID", symbol: "$" },
                  { value: "KMF", label: "KMF", symbol: "CF" },
                  { value: "KRW", label: "KRW", symbol: "₩" },
                  { value: "KWD", label: "KWD", symbol: "د.ك" },
                  { value: "KYD", label: "KYD", symbol: "$" },
                  { value: "KZT", label: "KZT", symbol: "₸" },
                  { value: "LAK", label: "LAK", symbol: "₭" },
                  { value: "LBP", label: "LBP", symbol: "ل.ل" },
                  { value: "LKR", label: "LKR", symbol: "Rs" },
                  { value: "LRD", label: "LRD", symbol: "$" },
                  { value: "LSL", label: "LSL", symbol: "L" },
                  { value: "LYD", label: "LYD", symbol: "ل.د" },
                  { value: "MAD", label: "MAD", symbol: "د.م." },
                  { value: "MDL", label: "MDL", symbol: "L" },
                  { value: "MGA", label: "MGA", symbol: "Ar" },
                  { value: "MKD", label: "MKD", symbol: "ден" },
                  { value: "MMK", label: "MMK", symbol: "K" },
                  { value: "MNT", label: "MNT", symbol: "₮" },
                  { value: "MOP", label: "MOP", symbol: "P" },
                  { value: "MRU", label: "MRU", symbol: "UM" },
                  { value: "MUR", label: "MUR", symbol: "₨" },
                  { value: "MVR", label: "MVR", symbol: "Rf" },
                  { value: "MWK", label: "MWK", symbol: "MK" },
                  { value: "MXN", label: "MXN", symbol: "$" },
                  { value: "MYR", label: "MYR", symbol: "RM" },
                  { value: "MZN", label: "MZN", symbol: "MT" },
                  { value: "NAD", label: "NAD", symbol: "$" },
                  { value: "NGN", label: "NGN", symbol: "₦" },
                  { value: "NIO", label: "NIO", symbol: "C$" },
                  { value: "NOK", label: "NOK", symbol: "kr" },
                  { value: "NPR", label: "NPR", symbol: "₨" },
                  { value: "NZD", label: "NZD", symbol: "$" },
                  { value: "OMR", label: "OMR", symbol: "ر.ع." },
                  { value: "PAB", label: "PAB", symbol: "B/." },
                  { value: "PEN", label: "PEN", symbol: "S/." },
                  { value: "PGK", label: "PGK", symbol: "K" },
                  { value: "PHP", label: "PHP", symbol: "₱" },
                  { value: "PKR", label: "PKR", symbol: "₨" },
                  { value: "PLN", label: "PLN", symbol: "zł" },
                  { value: "PYG", label: "PYG", symbol: "₲" },
                  { value: "QAR", label: "QAR", symbol: "ر.ق" },
                  { value: "RON", label: "RON", symbol: "lei" },
                  { value: "RSD", label: "RSD", symbol: "дин" },
                  { value: "RUB", label: "RUB", symbol: "₽" },
                  { value: "RWF", label: "RWF", symbol: "FRw" },
                  { value: "SAR", label: "SAR", symbol: "ر.س" },
                  { value: "SBD", label: "SBD", symbol: "$" },
                  { value: "SCR", label: "SCR", symbol: "₨" },
                  { value: "SDG", label: "SDG", symbol: "£" },
                  { value: "SEK", label: "SEK", symbol: "kr" },
                  { value: "SGD", label: "SGD", symbol: "$" },
                  { value: "SHP", label: "SHP", symbol: "£" },
                  { value: "SLL", label: "SLL", symbol: "Le" },
                  { value: "SOS", label: "SOS", symbol: "Sh" },
                  { value: "SRD", label: "SRD", symbol: "$" },
                  { value: "SSP", label: "SSP", symbol: "£" },
                  { value: "STN", label: "STN", symbol: "Db" },
                  { value: "SYP", label: "SYP", symbol: "£" },
                  { value: "SZL", label: "SZL", symbol: "L" },
                  { value: "THB", label: "THB", symbol: "฿" },
                  { value: "TJS", label: "TJS", symbol: "ЅМ" },
                  { value: "TMT", label: "TMT", symbol: "T" },
                  { value: "TND", label: "TND", symbol: "د.ت" },
                  { value: "TOP", label: "TOP", symbol: "T$" },
                  { value: "TRY", label: "TRY", symbol: "₺" },
                  { value: "TTD", label: "TTD", symbol: "$" },
                  { value: "TVD", label: "TVD", symbol: "$" },
                  { value: "TWD", label: "TWD", symbol: "NT$" },
                  { value: "TZS", label: "TZS", symbol: "Sh" },
                  { value: "UAH", label: "UAH", symbol: "₴" },
                  { value: "UGX", label: "UGX", symbol: "Sh" },
                  { value: "USD", label: "USD", symbol: "$" },
                  { value: "UYU", label: "UYU", symbol: "$U" },
                  { value: "UZS", label: "UZS", symbol: "лв" },
                  { value: "VES", label: "VES", symbol: "Bs.S" },
                  { value: "VND", label: "VND", symbol: "₫" },
                  { value: "VUV", label: "VUV", symbol: "VT" },
                  { value: "WST", label: "WST", symbol: "T" },
                  { value: "XAF", label: "XAF", symbol: "FCFA" },
                  { value: "XCD", label: "XCD", symbol: "$" },
                  { value: "XOF", label: "XOF", symbol: "CFA" },
                  { value: "XPF", label: "XPF", symbol: "₣" },
                  { value: "YER", label: "YER", symbol: "﷼" },
                  { value: "ZAR", label: "ZAR", symbol: "R" },
                  { value: "ZMW", label: "ZMW", symbol: "ZK" },
                  { value: "ZWL", label: "ZWL", symbol: "$" },
                ]}
              />
            </div>
            <div>
              <p className="mb-1">Symbol</p>
              <TextInput
                value={userShopProfile?.currency_synbol}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.currency_synbol = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div>
              <p className="mb-1">Exchange Code</p>
              <TextInput
                value={userShopProfile?.exchange_currency_code}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.exchange_currency_code = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
            <div>
              <p className="mb-1">Exchange Symbol</p>
              <TextInput
                value={userShopProfile?.exchange_currency_symbol}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.exchange_currency_symbol = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
            <div>
              <p className="mb-1">Exchange Rate</p>
              <TextInput
                value={Number(userShopProfile?.exchange_rate).toFixed(0)}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.exchange_rate = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Tax</p>
            <div>
              <p className="mb-1">Registration No</p>
              <TextInput
                value={userShopProfile?.tax_registration_no}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.tax_registration_no = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div>
              <p className="mb-1">Percentage</p>
              <TextInput
                value={Number(userShopProfile?.tax_percentage).toFixed(2)}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.tax_percentage = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Payment Method</p>
            <div>
              <p className="mb-1">Bank account</p>
              <TextInput
                value={userShopProfile?.bank_account}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.bank_account = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="bank_payment"
                label="Bank Payment"
                value={userShopProfile?.bank_payment}
                onChange={(answer: string) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.tax_percentage = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="credit-card-payment"
                label="Credit Card Payment"
                value={userShopProfile?.credit_card_payment}
                onChange={(answer: string) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.credit_card_payment = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="currency-payment"
                label="Currency Payment"
                value={userShopProfile?.currency_payment}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.currency_payment = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Delivery Agency</p>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="food-panda"
                label="Food Panda"
                value={userShopProfile?.foodpanda}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.foodpanda = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="nham24"
                label="Nham24"
                value={userShopProfile?.nham24}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.nham24 = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="meal-temple"
                label="Meal Temple"
                value={userShopProfile?.meal_temple}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.meal_temple = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="egets"
                label="Egets"
                value={userShopProfile?.e_gets}
                onChange={(answer) => {
                  console.log("answer");
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.e_gets = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="muuve"
                label="Muuve"
                value={userShopProfile?.muuve}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.muuve = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="instafood"
                label="Instafood"
                value={userShopProfile?.instafood}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.instafood = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="the-speed-delivery"
                label="The Speed Delivery"
                value={userShopProfile?.the_speed_delivery}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.the_speed_delivery = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="bloc"
                label="Bloc"
                value={userShopProfile?.bloc}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.bloc = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="grabfood"
                label="Grabfood"
                value={userShopProfile?.grabfood}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.grabfood = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="wownow"
                label="Wownow"
                value={userShopProfile?.wownow}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.wownow = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Booking</p>
            <div>
              <p className="mb-1">Max No of Pax</p>
              <TextInput
                value={userShopProfile?.max_no_of_pax}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.max_no_of_pax = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="allow-booking"
                label="Allow Booking"
                value={userShopProfile?.allow_booking}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.allow_booking = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Permission</p>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="allow-cashier-voic-invoice"
                label="Allow Cashier Voic invoice"
                value={userShopProfile?.allow_cashier_void_invoice}
                onChange={(answer: string) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.allow_cashier_void_invoice = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Passcode</p>

            <div>
              <p className="mb-1">Admin passcode</p>
              <InputHideShow
                maxLength={5}
                minLength={5}
                value={userShopProfile?.admin_passcode}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.admin_passcode = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
            <div>
              <p className="mb-1">Cashier Passcode</p>
              <InputHideShow
                maxLength={5}
                minLength={5}
                value={userShopProfile?.cashier_passcode}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.cashier_passcode = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
                placeholder=""
              />
            </div>

            <div>
              <p className="mb-1">Order Passcode</p>
              <InputHideShow
                maxLength={5}
                minLength={5}
                value={userShopProfile?.order_passcode}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.order_passcode = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
                placeholder=""
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Telegram Report</p>

            <div>
              <p className="mb-1">Telegram Group ID</p>
              <TextInput
                value={userShopProfile?.telegram_group_id}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.telegram_group_id = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
                placeholder=""
              />
            </div>

            <div>
              <p className="mb-1">Telegram Channel ID</p>
              <TextInput
                value={userShopProfile?.telegram_channel_id}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.telegram_channel_id = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
                placeholder=""
              />
            </div>

            <div>
              <p className="mb-1">Telegram User ID</p>
              <TextInput
                value={userShopProfile?.telegram_user_id}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.telegram_user_id = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
                placeholder=""
              />
            </div>

            <div>
              <p className="mb-1">Shift Close Summary</p>
              <SelectScrollable
                datas={[
                  { value: "Group", label: "Group" },
                  { value: "Item", label: "Item" },
                ]}
                value={userShopProfile?.shift_close_summary_by}
                onChange={(answer: string) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.shift_close_summary_by = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Layout</p>

            <div>
              <p className="mb-1">No. of Table Layout</p>
              <TextInput
                value={userShopProfile?.no_of_table_layout}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.no_of_table_layout = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
            <div>
              <p className="mb-1">No. of Item Layout</p>
              <TextInput
                value={userShopProfile?.no_of_item_layout}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.no_of_item_layout = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div>
              <p className="mb-1">No. of Item Display Layout</p>
              <TextInput
                value={userShopProfile?.no_of_item_display_layout}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.no_of_item_display_layout = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="popup_input_quantity"
                label="Popup Input Qauntity"
                value={userShopProfile?.popup_input_quantity}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.popup_input_quantity = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="customer_display"
                label="Customer Display"
                value={userShopProfile?.customer_display}
                onChange={(answer) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.customer_display = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>

          <div className="space-y-2">
            <p className="font-semibold text-lg">Printing</p>

            <div>
              <p className="mb-1">Invoice Printer IP</p>
              <TextInput
                value={userShopProfile?.invoice_printer_ip}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.invoice_printer_ip = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
            <div>
              <p className="mb-1">Invoice Printer Delay</p>
              <TextInput
                value={userShopProfile?.invoice_printer_delay}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.invoice_printer_delay = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div>
              <p className="mb-1">Invoice Layout Quality</p>
              <TextInput
                value={userShopProfile?.invoice_layout_quality}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.invoice_layout_quality = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div>
              <p className="mb-1">Invoice Layout</p>
              <SelectScrollable
                datas={[{ value: "Default", label: "Default" }]}
                value={userShopProfile?.invoice_layout}
                onChange={(answer: string) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.invoice_layout = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="invoice_printer"
                label="Invoice Printer"
                value={userShopProfile?.invoice_printer}
                onChange={(answer: string) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.invoice_printer = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div className="flex justify-between items-center">
              <SwitchYesNoWithLabel
                icon={""}
                id="invoice_preview"
                label="Invoice Preview"
                value={userShopProfile?.invoice_preview}
                onChange={(answer: string) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.invoice_preview = answer;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>

            <div>
              <p className="mb-1">Invoice Prefix</p>
              <TextInput
                value={userShopProfile?.invoice_prefix}
                onChange={(event) => {
                  const tmpUserShop = { ...userShopProfile };

                  tmpUserShop.invoice_prefix = event.target.value;
                  dispatch(setUserShopProfile(tmpUserShop));
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
