import LoadingPage from "assets/icons/loading-page";
import { Snackbar } from "components";
import AlbumPostContent from "components/app/album-post-content/AlbumPostContent";
import PhotoPostContent from "components/app/photo-post-content/PhotoPostContent";
import SearchParamsNotFound from "components/app/search-params-not-found/SearchParamsNotFound";
import StatusPostContent from "components/app/status-post-content/StatusPostContent";

import { SendHorizontalIcon, X } from "lucide-react";
import { AppRecord, AppRecordStatus } from "models/appRecord";
import { StatusMsg } from "models/returnMsg";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceSmileBeam,
  faHeart as solidHeart,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as outlineHeart } from "@fortawesome/free-regular-svg-icons";

import { AppLog } from "services";
import {
  setLoading,
  setPosts,
  setPost,
  setCurrentIdex,
  setComments,
  setCommentLoading,
} from "store/profilePostContentsPageViewSlice";
import { RootState, useAppSelector } from "store/store";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import VideoPostContent from "components/app/video-post-content/VideoPostContent";
import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";
import { comment, favorite, profileVerified, share } from "assets";
import { postedTimeAgo } from "utils/formatTimeSecondToHour";
import LinkifyText from "components/app/linkify/LinkifyText";
import ArrowUpDown from "./ArrowUpDown";
import AudioPlayer from "components/app/post-content-audio-player/AudioPlayer ";
import {
  requestSubmitFollowingUser,
  requestSubmitUnFollowinguUser,
} from "api-function/requestFriendStatusApi";
import BtnFriendStatus from "components/app/btn-friend-status/BtnFriendStatus";
import HelmetHtml from "components/app/helmet-html/HelmetHtml";

import { Textarea } from "components/ui/textarea";
import { PostReaction } from "models/postReaction";
import {
  getComments,
  getSubComments,
  onPostReaction,
  postReactionComment,
  postSubmitComment,
  submitRepostContent,
} from "api-function/postContentApi";
import { any } from "zod";
import DialogShare from "components/app/dialog-share/DialogShare";
import TextRection from "./post-component/TextReaction";
import Comment from "./post-component/Comment";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import { Service } from "utils/services";
export default function ProfilePostContentsPageView() {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const location = useLocation();
  const [shareText, setShareText] = useState("");
  const [user, setUser] = useState<any>(localStorage.getItem("user"));
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("post_id");
  const [direction, setDirection] = useState<string>("");
  const dispatch = useDispatch();
  const [searchID, setSearchID] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const {
    post,
    posts,
    postsState,
    prePostId,
    currentIndex,
    comments,
    commentState,
  } = useAppSelector((state) => state.profilePostContentsPageView);
  const { token } = useAppSelector((state) => state.auth);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const [textComment, setTextComment] = useState("");
  /* ========== ui request function ==========*/
  const onEmojiClick = (emojiData: EmojiClickData) => {
    setTextComment((prev) => prev + emojiData.emoji);
    console.log("reply");
    setShowEmojiPicker(false);
  };
  const onHandleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default Enter behavior (e.g., adding a newline)
      if (textComment) requestSubmitComment();
    }
  };
  const onClickFriend = () => {
    let user = post?.re_post_user !== null ? post?.re_post_user : post?.user;
    if (
      user?.friend_status === "Friend" ||
      user?.friend_status === "Following"
    ) {
      requestUnFollowing();
    } else {
      requestFollowing();
    }
  };
  const onUpdatePosts = (result: any, userId: string) => {
    try {
      const tmpPosts = posts.map((post) => {
        const postCopy = { ...post }; // Shallow copy of the post

        if (
          post["re_post_user"] !== null &&
          post["re_post_user"] !== undefined
        ) {
          if (post["re_post_user"]["id"]?.toString() === userId) {
            postCopy.re_post_user = { ...post.re_post_user };
            postCopy.re_post_user.friend_status =
              result?.user?.friend_status || null;
          }
        } else if (post["user"] !== null && post["user"] !== undefined) {
          if (post["user_id"]?.toString() === userId) {
            console.log("user");
            postCopy.user = { ...post.user };
            postCopy.user.friend_status = result?.user?.friend_status || null;
          }
        }

        return postCopy;
      });
      const tmpPost = tmpPosts.find((post) => post.post_id === id);
      dispatch(setPost(tmpPost));
      dispatch(setPosts(tmpPosts));
      console.log("succe");
    } catch (err) {
      console.log("errr", err);
    }
  };
  const onFindPost = () => {
    try {
      console.log("==========fin post");

      const tmpPost = posts.find((post) => post.post_id === id);

      if (tmpPost) {
        const stateRecord: AppRecord = {
          currentPage: 1,
          currentIndex: 0,
          nextPageUrl: tmpPost.comments["next_page_url"] ?? "",
          totalRecord: 0,
          loadingStatus:
            tmpPost.comments["next_page_url"] != null
              ? AppRecordStatus.loaded
              : AppRecordStatus.noLoadMore,
        };
        console.log(` scroll post ${stateRecord.nextPageUrl}`);
        dispatch(setCommentLoading(stateRecord));
        dispatch(setPost(tmpPost));
      }
    } catch (err) {
      AppLog({ message: `catch findPost ${err}` });
    }
  };
  const onChangeShare = (value: string) => {
    setShareText(value);
  };
  const onHandleScroll = async () => {
    console.log(` scroll ${commentState.loadingStatus}`);
    if (
      !scrollRef.current ||
      commentState.loadingStatus === AppRecordStatus.loadingMore
    )
      return;

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      commentState.loadingStatus !== AppRecordStatus.noLoadMore
    ) {
      console.log(` fetch ${commentState.loadingStatus}`);
      fetchComments();
    }
  };
  const onHandleUp = () => {
    if (currentIndex < posts.length - 1) {
      let tmpIndex = currentIndex + 1;
      dispatch(setCurrentIdex(tmpIndex));
      setDirection("down");

      navigate(
        `${RouteUrl.profilePostContentsPageView}?post_id=${posts[tmpIndex].post_id}`,
        { replace: true }
      );
      if (tmpIndex === posts.length - 1) {
        const postId = posts[posts.length - 1].post_id;

        fetchProfilePostContent({
          isRefresh: false,
          postId: postId,
          direction: direction,
        });
      }
    }
  };
  const onHandleDown = () => {
    if (currentIndex > 0) {
      let tmpIndex = currentIndex - 1;
      dispatch(setCurrentIdex(tmpIndex));
      setDirection("up");

      navigate(
        `${RouteUrl.profilePostContentsPageView}?post_id=${posts[tmpIndex].post_id}`,
        { replace: true }
      );
    }
  };
  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const onOpenDialog = () => {
    setIsDialogOpen(true);
  };
  /* ========== API request function ==========*/
  const requestReactionComment = async ({ comment }: { comment: any }) => {
    try {
      let tmpComment = comment;
      let newComments = [...comments];
      const isLike = tmpComment["is_like"]?.toString() === "0" ? "1" : "0";
      const data = {
        comment_id: tmpComment["comment_id"]?.toString(),
        is_like: isLike,
        key_remove_id: await Service.getSynchID(),
      };

      const result = await postReactionComment({ data: data });
      if (result.status === StatusMsg.success.value) {
        const resposeComment = result["post_comments"][0];
        const index = newComments.findIndex(
          (item) =>
            item["comment_id"].toString() ===
            tmpComment["comment_id"].toString()
        );
        if (index !== -1) {
          const updatedComment = {
            ...newComments[index],
            is_like: isLike,
            key_remove_id: data["key_remove_id"],
          };
          newComments[index] = updatedComment;
          dispatch(setComments(newComments));
        } else {
          const updatedComments = onUpdateReactionLike(
            comments,
            tmpComment["id"].toString(),
            resposeComment
          );
          console.log("response", resposeComment);
          dispatch(setComments(updatedComments));
        }
      } else {
        AppLog({
          message: `Error ${result["msg"]}`,
          status: StatusMsg.success,
        });
      }
    } catch (err) {
      AppLog({
        message: `Error Catch requestReactionComment ${err}`,
        status: StatusMsg.failed,
      });
    }
  };
  const requestSubmitComment = async () => {
    try {
      const comment = {
        user_id: post?.user_id,
        reply_comment_id: "",
        comment: textComment,
        post_id: post?.provider_post_id.toString(),
        attachment_id: post?.post_attachments[0].attachment_id.toString(),
        key_remove_id: await Service.getSynchID(),
      };
      console.log("key", comment["key_remove_id"]);
      const tmpComment = {
        id: 0,
        user_id: user["id"].toString(),
        sender: {
          id: user["id"].toString(),
          email: user["email"].toString(),
          username: user["username"].toString(),
          name: user["name"].toString(),
          gender: user["gender"].toString(),
          picture: user["picture"].toString(),
          thumbnail: user["thumbnail"].toString(),
        },
        reply_comment_id: comment["reply_comment_id"],
        comment: comment["comment"],
        comment_datetime: new Date(),
        sending: true,
        key_remove_id: comment["key_remove_id"],
      };
      let newComments = [...comments];
      newComments.unshift(tmpComment);

      dispatch(setComments(newComments));

      setTextComment("");
      const result = await postSubmitComment({ comment: comment });
      if (result.status === StatusMsg.success.value) {
        let newCommentsRepponse = [...newComments];
        const resposeComment = result["post_comments"][0];

        const index = newCommentsRepponse.findIndex(
          (item) =>
            item["key_remove_id"]?.toString() ===
            resposeComment["key_remove_id"]
        );
        if (index !== -1) {
          newCommentsRepponse[index] = { ...resposeComment };

          dispatch(setComments(newCommentsRepponse));
        }
        AppLog({
          message: `result ${JSON.stringify(result)}`,
          status: StatusMsg.success,
        });
      } else {
        AppLog({
          message: `Error ${result["msg"]}`,
          status: StatusMsg.success,
        });
      }
    } catch (err) {
      AppLog({
        message: `Error Catch postSubmitcomment ${err}`,
        status: StatusMsg.failed,
      });
    }
  };
  function onUpdateReactionLike(
    comments: any,
    targetId: string,
    newSubComment: any
  ) {
    return comments.map((comment: any) => {
      // Check if the current comment matches the target ID
      if (comment.id.toString() === targetId) {
        console.log("have", comment);

        // If sub_comments exist, append the newSubComment to the data array
        return {
          ...comment,
          is_like: newSubComment["is_like"],
        };
      }

      // If sub_comments exist, process them recursively
      if (comment.sub_comments?.data?.length) {
        return {
          ...comment,
          sub_comments: {
            ...comment.sub_comments,
            data: onUpdateReactionLike(
              comment.sub_comments.data,
              targetId,

              newSubComment
            ),
          },
        };
      }

      // If no match is found, return the comment as is
      return comment;
    });
  }
  function addOrUpdateSubComment(
    comments: any,
    targetId: string,
    headerId: string,
    newSubComment: any
  ) {
    return comments.map((comment: any) => {
      // Check if the current comment matches the target ID
      if (comment.id.toString() === targetId) {
        console.log("have", comment);
        // If sub_comments doesn't exist, initialize it with the newSubComment
        if (!comment.sub_comments || !comment.sub_comments.data) {
          return {
            ...comment,
            sub_comments: {
              data: [newSubComment],
            },
          };
        }

        // If sub_comments exist, append the newSubComment to the data array
        return {
          ...comment,
          sub_comments: {
            ...comment.sub_comments,
            data: [newSubComment, ...comment.sub_comments.data],
          },
        };
      }

      // If sub_comments exist, process them recursively
      if (comment.sub_comments?.data?.length) {
        return {
          ...comment,
          sub_comments: {
            ...comment.sub_comments,
            data: addOrUpdateSubComment(
              comment.sub_comments.data,
              targetId,
              headerId,
              newSubComment
            ),
          },
        };
      }

      // If no match is found, return the comment as is
      return comment;
    });
  }

  const requestReplyComment = async ({
    replyId,
    replyText,
    headerId,
  }: {
    replyId: string;
    replyText: string;
    headerId: string;
  }) => {
    try {
      console.log("Replot to ", replyId);
      const comment = {
        user_id: post?.user_id,
        reply_comment_id: replyId,
        comment: replyText,
        post_id: post?.provider_post_id.toString(),
        attachment_id: post?.post_attachments[0].attachment_id.toString(),
        key_remove_id: await Service.getSynchID(),
      };

      // Prepare tmpSubComment with serializable data only
      const tmpSubComment = {
        id: 0,
        user_id: user["id"].toString(),
        sender: {
          id: user["id"].toString(),
          email: user["email"].toString(),
          username: user["username"].toString(),
          name: user["name"].toString(),
          gender: user["gender"].toString(),
          picture: user["picture"].toString(),
          thumbnail: user["thumbnail"].toString(),
        },
        reply_comment_id: comment["reply_comment_id"],
        comment: replyText,
        comment_datetime: new Date().toISOString(), // Convert date to string
        sending: true,
        key_remove_id: comment["key_remove_id"],
      };
      const updatedComments = addOrUpdateSubComment(
        comments,
        replyId,
        headerId,
        tmpSubComment
      );
      dispatch(setComments(updatedComments));
      const result = await postSubmitComment({ comment: comment });
      if (result.status === StatusMsg.success.value) {
        const resposeComment = result["post_comments"][0];
        const updateResponseComment = addOrUpdateSubComment(
          comments,
          replyId,
          headerId,
          resposeComment
        );
        dispatch(setComments(updateResponseComment));
      } else {
      }
    } catch (err) {
      AppLog({ message: `ERROR CATCH ${err}` });
    }
  };
  const fetchProfilePostContent = async ({
    isRefresh = false,
    direction = "",
    postId,
  }: { isRefresh?: boolean; direction?: string; postId?: string } = {}) => {
    try {
      if (isRefresh) {
        dispatch(
          setLoading({ ...postsState, loadingStatus: AppRecordStatus.loading })
        );
      } else {
        dispatch(
          setLoading({
            ...postsState,
            loadingStatus: AppRecordStatus.loadingMore,
          })
        );
      }
      let url: string = "";

      if (isAuthenticated) {
        url = RouteApi.getHomeProfilePost;
        url += `token=${token}`;
      } else {
        url = RouteApi.getProfilePostContentsPageView;
      }
      await RequestApi(url, {
        data: { post_id: postId ?? id, direction: direction },
      })
        .then((responses) => {
          if (responses.status === "success") {
            let tmpPosts = [];
            if (responses["post"]) {
              tmpPosts[0] = responses["post"];
            } else {
              tmpPosts = isRefresh
                ? [...responses["posts"]]
                : [...posts, ...responses["posts"]];
            }

            if (isRefresh) {
              const tmpPost = tmpPosts.find((post) => post.post_id === id);

              if (tmpPost) {
                const stateRecord: AppRecord = {
                  currentPage: 1,
                  currentIndex: 0,
                  nextPageUrl: tmpPost.comments["next_page_url"] ?? "",
                  totalRecord: 0,
                  loadingStatus:
                    tmpPost.comments["next_page_url"] != null
                      ? AppRecordStatus.loaded
                      : AppRecordStatus.noLoadMore,
                };
                console.log(` scroll post ${stateRecord.nextPageUrl}`);
                dispatch(setCommentLoading(stateRecord));
                dispatch(setPost(tmpPost));
              }
            }

            dispatch(setPosts(tmpPosts));

            if (responses["posts"] === null && responses["post"] === null) {
              setSearchID(true);
            }
            AppLog({ message: `post Length ${tmpPosts.length}` });
          } else {
            Snackbar({
              message: responses["msg"],
              status: StatusMsg.getType(responses["status"]),
            });
          }
          setLoading(false);
          dispatch(
            setLoading({ ...postsState, loadingStatus: AppRecordStatus.loaded })
          );
        })
        .catch((err) => {
          Snackbar({
            message: err.toString(),
            status: StatusMsg.failed,
          });
          setLoading(false);
          dispatch(
            setLoading({ ...postsState, loadingStatus: AppRecordStatus.loaded })
          );
        });
    } catch (ex: any) {
      AppLog({ message: ex.toString(), status: StatusMsg.error });
      setLoading(false);
      dispatch(
        setLoading({ ...postsState, loadingStatus: AppRecordStatus.loaded })
      );
    }
  };
  const requestUnFollowing = async () => {
    const userId =
      post?.re_post_user !== null
        ? post?.re_post_user_id?.toString()
        : post?.user_id?.toString();
    const result = await requestSubmitUnFollowinguUser({ userId: userId });
    console.log("----stat");
    if (result.status === "success") {
      onUpdatePosts(result, userId);
    }
  };
  const requestFollowing = async () => {
    const userId =
      post?.re_post_user !== null
        ? post?.re_post_user_id?.toString()
        : post?.user_id?.toString();
    const result = await requestSubmitFollowingUser({ userId: userId });
    if (result.status === "success") {
      onUpdatePosts(result, userId);
    }
  };

  const requestPostReactionClicked = async ({
    liked,
    shared,
    content,
    attachment,
    postReaction,
  }: {
    liked?: boolean;
    shared?: boolean;
    content: any;
    attachment: any;
    postReaction: PostReaction;
  }) => {
    try {
      if (user != null) {
        console.log(user);
        if (postReaction === PostReaction.like) {
          if (liked !== null) {
            const result = await onPostReaction({
              content: content,
              reaction: PostReaction.like,
              isLike: liked,
              isFavorite: false,
              attachment: any,
            });
            if (result.status === "success") {
              const tmposts = { ...content };
              if (tmposts && tmposts.post_attachments) {
                const updatedTmposts = { ...tmposts };
                const updatedAttachments = [...updatedTmposts.post_attachments];
                const firstAttachment = { ...updatedAttachments[0] }; // Copy the first attachment
                const likeUsers = [...tmposts["like_users"]];

                firstAttachment["is_reaction_like"] = liked ? "1" : "0";
                updatedAttachments[0] = firstAttachment; // Reassign the modified attachment
                updatedTmposts.post_attachments = updatedAttachments;

                const numberLike = liked ? 1 : -1;
                updatedTmposts.reaction_like_count =
                  (Number(updatedTmposts.reaction_like_count) || 0) +
                  numberLike;
                let tmpLikeUsers = [];
                if (liked) {
                  const userAuth = {
                    id: user["id"]?.toString(),
                    picture: user["picture"]?.toString(),
                    thumbnail: user["thumbnail"]?.toString(),
                    avatar: user["avatar"]?.toString(),
                  };

                  tmpLikeUsers = [...likeUsers, ...[userAuth]];
                  updatedTmposts.like_users = tmpLikeUsers;
                } else {
                  tmpLikeUsers = [...likeUsers];
                  const index = tmpLikeUsers.findIndex(
                    (item) => item["id"]?.toString() === user["id"]?.toString()
                  );
                  console.log(`---index ${index}`);

                  if (index !== -1) {
                    tmpLikeUsers.splice(index);
                    updatedTmposts.like_users = tmpLikeUsers;
                  }
                }
                dispatch(setPost(updatedTmposts));
              }

              console.log("success");
            } else {
              console.log("message error :" + result["msg"]);
            }
          }
        } else if (postReaction === PostReaction.favorite) {
          let favorite =
            attachment["is_reaction_favorite"].toString() === "1"
              ? false
              : true;
          const result = await onPostReaction({
            content: content,
            reaction: PostReaction.favorite,
            isLike: false,
            isFavorite: favorite,
            attachment: any,
          });
          if (result.status === "success") {
            const tmposts = { ...content };
            if (tmposts && tmposts.post_attachments) {
              const updatedTmposts = { ...tmposts };
              const updatedAttachments = [...updatedTmposts.post_attachments];
              const firstAttachment = { ...updatedAttachments[0] }; // Copy the first attachment

              firstAttachment["is_reaction_favorite"] = favorite ? "1" : "0";
              updatedAttachments[0] = firstAttachment; // Reassign the modified attachment
              updatedTmposts.post_attachments = updatedAttachments;

              const favoriteCount = favorite ? 1 : -1;
              updatedTmposts.reaction_favorite_count =
                (Number(updatedTmposts.reaction_favorite_count) || 0) +
                favoriteCount;

              dispatch(setPost(updatedTmposts));
            }

            console.log("success");
          } else {
            console.log("message error :" + result["msg"]);
          }
        }
      }
    } catch (err) {
      AppLog({ message: `catch onPosreactionClikc ${err}` });
    }
  };
  const requestShare = async () => {
    const result = await submitRepostContent({
      providerPostId: post?.provider_post_id.toString(),
      medaiTitle: shareText,
    });
    if (result.status === "success") {
      const tmpost = { ...post };
      if (tmpost && tmpost.post_attachments) {
        tmpost.reaction_share_count =
          (Number(tmpost.reaction_share_count) || 0) + 1;
        dispatch(setPost(tmpost));
      }
      Snackbar({
        message: result["msg"],
        status: StatusMsg.success,
      });
    } else {
      Snackbar({
        message: result["msg"],
        status: StatusMsg.warning,
      });
    }
    onCloseDialog();
  };
  const fetchSubComments = async (commentId: string) => {
    try {
      console.log("---Click to fetch sub-comments");

      const result = await getSubComments({
        postId: post?.provider_post_id,
        attachmentId: post?.post_attachments[0]["attachment_id"],
        replyCommentId: commentId,
      });

      if (result.status === "success") {
        console.log("---Fetch success");

        const fetchedSubComments = {
          data: [...result["comments"]["data"]],
          next_page_url: result["comments"]["next_page_url"],
        };

        // Function to recursively update comments and their sub-comments
        const updateCommentWithSubComments = (
          comments: any,
          targetId: string,
          subComments: any
        ) => {
          return comments.map((comment: any) => {
            // Check if the current comment matches the target ID
            if (comment.id.toString() === targetId) {
              console.log("id", commentId);

              // If sub_comments already exist, append new data to the existing array
              const updatedSubComments = comment.sub_comments
                ? {
                    ...comment.sub_comments,
                    data: [
                      ...comment.sub_comments.data,
                      ...subComments.data, // Add fetched sub-comments to existing ones
                    ],
                    next_page_url: subComments.next_page_url,
                  }
                : subComments; // If no sub-comments exist, initialize with the new data
              console.log("have", comment);
              return { ...comment, sub_comments: updatedSubComments };
            }

            // If the comment has nested sub-comments, recursively update them
            if (comment.sub_comments?.data?.length) {
              return {
                ...comment,
                sub_comments: {
                  ...comment.sub_comments,
                  data: updateCommentWithSubComments(
                    comment.sub_comments.data,
                    targetId,
                    subComments
                  ),
                },
              };
            }

            return comment; // If no match, return the comment as is
          });
        };

        // Update the comments array
        const updatedComments = updateCommentWithSubComments(
          comments,
          commentId,
          fetchedSubComments
        );
        console.log("Updated comments", updatedComments);
        dispatch(setComments(updatedComments));
      } else {
        AppLog({ message: `Failed to fetch sub-comments: ${result["msg"]}` });
      }
    } catch (err) {
      AppLog({ message: `Error in fetchSubComments: ${err}` });
    }
  };

  const fetchComments = async () => {
    try {
      dispatch(
        setCommentLoading({
          ...commentState,
          loadingStatus: AppRecordStatus.loadingMore,
        })
      );
      const nextPage = comments.length === 10 ? "" : commentState.nextPageUrl;

      const result = await getComments({
        postId: post?.provider_post_id,
        attachmentId: post?.post_attachments[0]["attachment_id"],
        nextPage: nextPage,
      });
      if (result.status === "success") {
        const tmpComments = [...comments, ...result["comments"]["data"]];

        const stateRecord: AppRecord = {
          currentPage: 1,
          currentIndex: 0,
          nextPageUrl: result["comments"]["next_page_url"] ?? "",
          totalRecord: 0,
          loadingStatus:
            result["comments"]["next_page_url"] != null
              ? AppRecordStatus.loaded
              : AppRecordStatus.noLoadMore,
        };

        dispatch(setComments(tmpComments));
        dispatch(setCommentLoading(stateRecord));
      } else {
        AppLog({ message: `fail to fetch comment ${result["msg"]}` });
        dispatch(
          setCommentLoading({
            ...commentState,
            loadingStatus: AppRecordStatus.loaded,
          })
        );
      }
    } catch (err) {
      AppLog({ message: `catch fetchComments ${err}` });
      dispatch(
        setCommentLoading({
          ...commentState,
          loadingStatus: AppRecordStatus.loaded,
        })
      );
    }
  };
  /* ========== Component function ==========*/
  const postContents = () => {
    if (post) {
      if (post?.media_type === "photo") {
        return (
          <div
            key={post?.post_attachments[0].id}
            style={{
              aspectRatio: `${
                post?.post_attachments[0]?.attachment_media_width ?? 16
              } / ${post?.post_attachments[0]?.attachment_media_height ?? 9}`,
            }}
          >
            <div className="flex justify-center items-center">
              <ArrowUpDown
                value={currentIndex}
                handleDown={onHandleDown}
                handleUp={onHandleUp}
                isDown={currentIndex === posts.length - 1 ? true : false}
              />
              {post?.sound_url && post?.sound_url !== "" ? (
                <div className="absolute max-sm:bottom-3 max-sm:right-3 bottom-5 right-8  space-x-2  text-white select-none z-50">
                  <AudioPlayer
                    src={post?.sound_url}
                    title={post?.sound_title ?? ""}
                  />
                </div>
              ) : (
                <></>
              )}
              <PhotoPostContent post={post?.post_attachments[0]} />
            </div>
          </div>
        );
      } else if (post?.media_type === "album") {
        return (
          <div className="flex justify-center items-center ">
            <ArrowUpDown
              value={currentIndex}
              handleDown={onHandleDown}
              handleUp={onHandleUp}
              isDown={currentIndex === posts.length - 1 ? true : false}
            />
            {post?.sound_url && post?.sound_url !== "" ? (
              <div className="absolute max-sm:bottom-3 max-sm:right-3 bottom-5 right-8  space-x-2  text-white select-none z-50">
                <AudioPlayer
                  src={post?.sound_url}
                  title={post?.sound_title ?? ""}
                />
              </div>
            ) : (
              <></>
            )}
            <AlbumPostContent post={post} />
          </div>
        );
      } else if (post?.media_type === "video") {
        return (
          <div className="flex justify-center items-center">
            <ArrowUpDown
              value={currentIndex}
              handleDown={onHandleDown}
              handleUp={onHandleUp}
              isDown={currentIndex === posts.length - 1 ? true : false}
            />
            <VideoPostContent postAttchment={post} />
          </div>
        );
      } else if (post?.media_type === "status") {
        return (
          <div className="flex  justify-center items-center ">
            <ArrowUpDown
              value={currentIndex}
              handleDown={onHandleDown}
              handleUp={onHandleUp}
              isDown={currentIndex === posts.length - 1 ? true : false}
            />
            <StatusPostContent post={post} />
          </div>
        );
      }
    } else {
      return <></>;
    }
  };
  /* ========== useEffect ==========*/
  useEffect(() => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    if (id !== prePostId) {
      console.log("find curr on back");
      onFindPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (prePostId !== id && id) {
      console.log("Fetching profile post content for ID:", id);
      dispatch(setCurrentIdex(0));
      fetchProfilePostContent({ isRefresh: true, postId: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prePostId]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      {!id || id === "" || searchID ? (
        <SearchParamsNotFound params="id" />
      ) : (
        <>
          {postsState.loadingStatus === AppRecordStatus.loading ? (
            <LoadingPage />
          ) : (
            // <Layout>
            <div className="flex flex-col lg:grid lg:grid-cols-3  overflow-hidden h-[100vh]">
              <HelmetHtml
                title="Post-Content"
                description="This is PostContent"
              />
              <div className="  bg-black lg:col-span-2 md:h-[60vh] lg:h-auto items-center text-center">
                <div
                  className="absolute inset-0 bg-cover bg-center bg-black opacity-50 blur-3xl "
                  style={{
                    backgroundImage: `url("${post?.post_attachments[0]?.attachment_thumbnail_image_file_src}")`,
                  }}
                ></div>

                <div className="relative z-50  select-none">
                  <X
                    className="absolute max-sm:top-3 top-5 max-sm:left-3 left-5  rounded-full max-sm:size-8 size-11 max-sm:p-1 p-2 bg-opacity-10 text-white cursor-pointer bg-black shadow-md"
                    onClick={() => navigate(-1)}
                  />
                </div>

                <div className={`relative flex items-center justify-center `}>
                  {post?.media_type === "status" ? (
                    <>{postContents()}</>
                  ) : post?.media_type === "video" ? (
                    <>{postContents()}</>
                  ) : (
                    <>{postContents()}</>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-screen relative justify-between bg-white   pt-4  ">
                <div className="flex flex-col relative space-y-2 pl-4 pr-4">
                  <div className="flex flex-row items-start space-x-2 w-full  ">
                    <ImageUrlProfile
                      user={post?.user}
                      style={{ width: 50, height: 50 }}
                      className="rounded-full object-cover border shadow-md shadow-gray-300"
                      isLinkProfile={true}
                    />
                    <div className="flex flex-row justify-between items-center  w-full">
                      <div className="flex flex-col ">
                        <div className="flex items-center space-x-2">
                          <p className=" text-xl font-semibold">
                            {post?.user?.name}
                          </p>
                          {post?.user?.is_verified === "Yes" ? (
                            <img
                              src={profileVerified}
                              alt=""
                              style={{ width: 12, height: 12 }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="flex items-center space-x-2">
                          <p>
                            {post?.is_sponsored === "Yes"
                              ? "Sponsored"
                              : post?.is_available_suggestion === "Yes"
                              ? "Suggestion for you"
                              : `${postedTimeAgo(
                                  post?.provider_created_datetime
                                )}`}
                          </p>
                        </div>
                      </div>
                      <BtnFriendStatus
                        friendStatus={
                          post?.re_post_user !== null
                            ? post?.re_post_user?.friend_status
                            : post?.user?.friend_status
                        }
                        userType={post?.user?.user_type}
                        onClick={onClickFriend}
                      />
                    </div>
                  </div>
                  {/* Post description */}
                  <LinkifyText
                    text={post?.post_attachments[0]?.description ?? ""}
                  />
                  <div className="flex-1 flex-row justify-between w-full">
                    <div className="flex flex-row justify-start w-full space-x-2">
                      <div
                        className="flex w-7 h-7  rounded-full bg-gray-200 text-center items-center justify-center"
                        onClick={() =>
                          requestPostReactionClicked({
                            liked:
                              post?.post_attachments[0][
                                "is_reaction_like"
                              ]?.toString() === "1"
                                ? false
                                : true,
                            shared: false,
                            content: post,
                            attachment: post?.post_attachments[0],
                            postReaction: PostReaction.like,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            post?.post_attachments[0][
                              "is_reaction_like"
                            ]?.toString() === "1"
                              ? solidHeart
                              : outlineHeart
                          }
                          size="sm"
                          className={` ${
                            post?.post_attachments[0][
                              "is_reaction_like"
                            ]?.toString() === "1"
                              ? "heart-icon text-red-600 heart-fill"
                              : "heart-outline outline-black"
                          }`}
                        />
                      </div>
                      <div
                        className="flex w-7 h-7  rounded-full pb-1 pl-1 pr-1 bg-gray-200 text-center items-center justify-center"
                        onClick={onOpenDialog}
                      >
                        <img src={share} alt="" width={25} height={25} />
                      </div>
                      <DialogShare
                        isOpen={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        onShare={requestShare}
                        content={post}
                        user={user}
                        onChange={onChangeShare}
                      />
                      <div
                        className="flex w-7 h-7 p-1 rounded-full bg-gray-200 text-center items-center justify-center"
                        onClick={() =>
                          requestPostReactionClicked({
                            liked: false,
                            shared: false,
                            content: post,
                            attachment: post?.post_attachments[0],
                            postReaction: PostReaction.favorite,
                          })
                        }
                      >
                        {post?.post_attachments[0][
                          "is_reaction_favorite"
                        ]?.toString() === "1" ? (
                          <img
                            src={favorite}
                            alt=""
                            width={25}
                            height={25}
                            style={{
                              filter:
                                "invert(70%) sepia(82%) saturate(500%) hue-rotate(10deg) brightness(95%) contrast(100%)",
                            }}
                          />
                        ) : (
                          <img src={favorite} alt="" width={25} height={25} />
                        )}
                      </div>
                    </div>
                  </div>

                  <TextRection post={post} />
                </div>
                {/* Comment */}
                <div className="flex flex-row   justify-center items-start space-x-2 w-full bg-gray-100 pt-2 mt-4 px-4">
                  <ImageUrlProfile
                    isLinkProfile={true}
                    user={user}
                    className="cursor-pointer rounded-full w-7 h-7"
                    alt="logo"
                  />
                  <div className="flex flex-col space-y-2  w-full items-start justify-start">
                    <div className="border  relative p-2 rounded-2xl w-full bg-white">
                      <div className="flex flex-col">
                        <Textarea
                          value={textComment}
                          onKeyDown={onHandleKeyDown}
                          maxLength={100}
                          onChange={(e) => setTextComment(e.target.value)}
                          className="border border-none min-h-[10px]  focus-visible:ring-0 focus-visible:ring-offset-0 w-full  px-0 py-0 rounded-none "
                          placeholder="Add comment"
                        />
                        <div className="flex justify-between items-center">
                          <div className="flex space-x-2">
                            <FontAwesomeIcon
                              icon={faFaceSmileBeam}
                              className="text-gray-500 cursor-pointer"
                              onClick={() =>
                                setShowEmojiPicker((prev) => !prev)
                              }
                            />
                            {showEmojiPicker && (
                              <div
                                ref={emojiPickerRef}
                                className={`absolute   top-16 left-5 `}
                                spellCheck={false}
                              >
                                <EmojiPicker
                                  reactionsDefaultOpen={false}
                                  onEmojiClick={onEmojiClick}
                                  emojiStyle={EmojiStyle.FACEBOOK}
                                  searchDisabled={true}
                                  skinTonesDisabled={true}
                                />
                              </div>
                            )}

                            {/* Add more icons for stickers or other options as needed */}
                          </div>
                          <SendHorizontalIcon
                            className={`cursor-pointer ${
                              textComment ? "text-yellow-400" : "text-gray-500"
                            }`}
                            onClick={
                              !textComment ? () => {} : requestSubmitComment
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  ref={scrollRef}
                  onScroll={onHandleScroll}
                  className="flex-1 bg-gray-100 px-4 overflow-y-auto"
                >
                  {[...comments].map((comment, index) => (
                    <Comment
                      key={comment.id}
                      comment={comment}
                      index={index}
                      submitReply={requestReplyComment}
                      onClikcReact={() => requestReactionComment({ comment })}
                      fetchSubComments={fetchSubComments}
                    >
                      {comment.sub_comments?.data?.length > 0 && (
                        <div className="">
                          {comment.sub_comments.data.map(
                            (subComment: any, subIndex: number) => (
                              <Comment
                                key={subComment.id}
                                comment={subComment}
                                index={subIndex}
                                submitReply={requestReplyComment}
                                onClikcReact={() =>
                                  requestReactionComment({
                                    comment: subComment,
                                  })
                                }
                                fetchSubComments={fetchSubComments}
                                isSubComment={true}
                              >
                                {/* Recursively render deeper sub-comments */}
                                {subComment.sub_comments?.data?.length > 0 && (
                                  <div className="">
                                    {subComment.sub_comments.data.map(
                                      (
                                        nestedComment: any,
                                        nestedIndex: number
                                      ) => (
                                        <Comment
                                          key={nestedComment.id}
                                          comment={nestedComment}
                                          index={nestedIndex}
                                          submitReply={requestReplyComment}
                                          onClikcReact={() =>
                                            requestReactionComment({
                                              comment: nestedComment,
                                            })
                                          }
                                          fetchSubComments={fetchSubComments}
                                          isSubComment={true}
                                        >
                                          {nestedComment.sub_comments?.data
                                            ?.length > 0 && (
                                            <div className="">
                                              {nestedComment.sub_comments.data.map(
                                                (
                                                  nestedComment_f: any,
                                                  nestedIndex_f: number
                                                ) => (
                                                  <Comment
                                                    key={nestedComment_f.id}
                                                    comment={nestedComment_f}
                                                    index={nestedIndex_f}
                                                    submitReply={
                                                      requestReplyComment
                                                    }
                                                    onClikcReact={() =>
                                                      requestReactionComment({
                                                        comment:
                                                          nestedComment_f,
                                                      })
                                                    }
                                                    isSubComment={true}
                                                  />
                                                )
                                              )}
                                            </div>
                                          )}
                                        </Comment>
                                      )
                                    )}
                                  </div>
                                )}
                              </Comment>
                            )
                          )}
                        </div>
                      )}
                    </Comment>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
