import { logoFlipFlop, otp } from "assets";
import ImageUrl from "components/app/image-url/ImageUrl";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { ChevronLeft, Loader, Mail, Smartphone, X } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RequestApi, RouteApi, RouteUrl } from "utils";
import AlertLoginPage from "../login/AlertLoginPage";
import AlertSigUpPage from "../sign-up/AlertSignUpPage";
import AlertForgotPasswordPhonePage from "../forgot-password-phone/AlertForgotPasswordPhonePage";
import AlertForgotPasswordEmailPage from "../forgot-password-email/AlertForgotPasswordEmailPage";
import FacebookLogin from "react-facebook-login";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { AppLog } from "services";
import OneSignal from "react-onesignal";
import { StatusMsg } from "models/returnMsg";
import { jwtDecode } from "jwt-decode";
interface AlertWelcomeProps {
  showAlert: boolean;
  setShowAlert: (value: boolean) => void;
}

export default function AlertWellcome({
  showAlert,
  setShowAlert,
}: AlertWelcomeProps) {
  const [page, setPage] = useState("/");
  const [isEmail, setIsEmail] = useState(false);
  const [isSingUp, setIsSignup] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  const onSetAlert = () => {
    setIsEmail(true);
    setIsSignup(false);
    setPage(RouteUrl.forgotPasswordEmail);
  };
  const onDisable = () => {
    setIsEmail(false);
    setIsSignup(false);
    setPage(RouteUrl.forgotPasswordPhone);
  };
  const onSignUp = () => {
    setIsEmail(false);
    setIsSignup(true);
    setPage(RouteUrl.signUp);
  };
  const handleOnClose = () => {
    setShowAlert(false);
    setPage("/");
  };
  const responseFacebook = async (response: any) => {
    try {
      if (response.accessToken) {
        console.log(`Login facebook ${JSON.stringify(response)}`);
        setLoading(true);
        const data = {
          facebook_user: response,
          device_data: "",
          one_signal_id: OneSignal.User.PushSubscription.id ?? "",
          one_signal_token: OneSignal.User.PushSubscription.token ?? "",
          platform: "",
        };
        await RequestApi(RouteApi.loginByFacebook, { data: data }).then(
          (result) => {
            if (result.status === "success") {
              const user = result.user;
              user["username"] = result.username;
              user["token"] = result.token;
              localStorage.setItem("jwt", result.token);
              localStorage.setItem("user", JSON.stringify(user));
              localStorage.setItem(
                "authentication_key",
                user["authentication_key"]
              );
              console.log(` respose ${JSON.stringify(result)}`);
              console.log(JSON.stringify(user));
              window.location.href = "/";
            } else {
              AppLog({
                message: `Warning on request loginByFacebook ${JSON.stringify(
                  result
                )}`,
              });
            }
            setLoading(false);
          }
        );
      } else {
        console.error("Facebook login failed:", response);
        setLoading(false);
      }
    } catch (err) {
      console.error("Facebook login failed:", err);
      setLoading(false);
    }
  };

  const responseGoogle = async (credentialResponse: CredentialResponse) => {
    try {
      if (credentialResponse.credential) {
        setLoading(true);

        const token = `${credentialResponse.credential}`;
        const decoded = jwtDecode<any>(token);
        if (decoded) {
          const data = {
            google_id: token,
            google_user: decoded.email,
            google_name: decoded.name,
            google_picture: decoded.picture,
            device_data: "",
            one_signal_id: OneSignal.User.PushSubscription.id ?? "",
            one_signal_token: OneSignal.User.PushSubscription.token ?? "",
            platform: "",
          };
          await RequestApi(RouteApi.loginByGmail, { data: data }).then(
            (result) => {
              if (result.status === "success") {
                const user = result.user;
                user["username"] = result.username;
                user["token"] = result.token;
                localStorage.setItem("jwt", result.token);
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem(
                  "authentication_key",
                  user["authentication_key"]
                );
                console.log(` respose ${JSON.stringify(result)}`);
                console.log(JSON.stringify(user));
                window.location.href = "/";
              } else {
                AppLog({
                  message: `Warning on request loginByGmail ${JSON.stringify(
                    result
                  )}`,
                });
              }
              setLoading(false);
            }
          );
        } else {
          setLoading(true);
          AppLog({
            message: `Google login failed : Can not decode!`,
            status: StatusMsg.warning,
          });
        }
      } else {
        AppLog({
          message: `Google login failed`,
          status: StatusMsg.warning,
        });
        setLoading(false);
      }
    } catch (ex: any) {
      setLoading(false);
      AppLog({
        message: `Google login failed : ${ex.toString()}`,
        status: StatusMsg.error,
      });
    }
  };

  const onClick = (data: any) => {
    console.warn(data);
  };
  return (
    <>
      <AlertDialog open={showAlert}>
        <AlertDialogContent>
          <AlertDialogHeader className="relative items-center">
            <X
              onClick={handleOnClose}
              className="absolute -top-2 -right-2 cursor-pointer"
            />
            {page === "otp" ? (
              <ChevronLeft
                onClick={() => setPage(RouteUrl.signUp)}
                className="absolute -top-2 -left-2 cursor-pointer"
              />
            ) : (
              <></>
            )}
            {page === RouteUrl.resetPassword ? (
              <></>
            ) : (
              <div className="pb-5 flex justify-center">
                <ImageUrl
                  src={page === "otp" ? otp : logoFlipFlop}
                  width={100}
                  height={100}
                  alt="logo"
                />
              </div>
            )}

            <AlertDialogTitle className="text-center font-bold text-[20px]">
              {page === RouteUrl.signUp
                ? "Registration"
                : page === RouteUrl.forgotPasswordPhone ||
                  page === RouteUrl.forgotPasswordEmail
                ? "Reset Password"
                : page === RouteUrl.resetPassword
                ? ""
                : page === "otp"
                ? "OTP verifiction"
                : "Welcome to FlipFlop"}
            </AlertDialogTitle>

            {page === RouteUrl.login ||
            page === RouteUrl.signUp ||
            page === RouteUrl.forgotPasswordPhone ||
            page === RouteUrl.resetPassword ||
            page === RouteUrl.forgotPasswordEmail ||
            page === "otp" ? (
              <></>
            ) : (
              <>
                <p className="pb-10 font-medium">
                  We are connecting you with digital life.
                </p>
                <AlertDialogDescription className="font-semibold text-black">
                  Continue with:
                </AlertDialogDescription>
                <div className="flex justify-center flex-col pb-4 space-y-2">
                  <FacebookLogin
                    appId="856620359424999"
                    icon={
                      <FontAwesomeIcon
                        icon={faFacebook}
                        size="lg"
                        className="text-blue-600 mr-4"
                      />
                    }
                    textButton="Continue with Facebook"
                    fields="name,email,picture"
                    onClick={onClick}
                    cssClass="border px-3 py-2.5 rounded-md text-sm"
                    callback={responseFacebook}
                  />

                  <GoogleLogin
                    text="continue_with"
                    onSuccess={responseGoogle}
                    onError={() => {
                      AppLog({
                        message: `onError : Google login failed`,
                        status: StatusMsg.error,
                      });
                    }}
                  />
                </div>
                {loading ? (
                  <Loader className="animate-spin text-[#F3BE22]" />
                ) : (
                  <></>
                )}
                <div className="inline-flex items-center justify-center w-full">
                  <hr className="w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                </div>
                <div className="w-full flex flex-col space-y-2">
                  <Button
                    onClick={onSignUp}
                    className="bg-[#F3BE22] hover:bg-[#fad56d] rounded-xl text-white mb-3"
                  >
                    Create an account
                  </Button>
                  <Button
                    onClick={() => setPage(RouteUrl.login)}
                    variant="outline"
                    className="w-full rounded-xl border-[#F3BE22] text-[#F3BE22] hover:text-[#f9d977]"
                  >
                    Login
                  </Button>
                </div>
                <div className="text-[15px] mt-3 text-justify ">
                  <p>
                    By signing up, you agree to the {""}
                    <Link
                      to={RouteUrl.termOfServices}
                      className="w-full text-blue-500"
                    >
                      Term of Service
                    </Link>
                    {""} and
                  </p>
                  <Link
                    to={RouteUrl.termOfPrivacy}
                    className="w-full text-blue-500"
                  >
                    Privacy Policy.
                  </Link>
                </div>
              </>
            )}
          </AlertDialogHeader>
          {page === RouteUrl.login ? (
            <>
              <AlertLoginPage />
              <p className=" text-left">
                Not yet sign up FlipFlop account? {""}
                <Link
                  to="#"
                  onClick={() => setPage("/")}
                  className="w-full text-blue-500"
                >
                  Sign up
                </Link>
              </p>
              <div className="flex w-full">
                Forgot password with
                <Mail
                  onClick={onSetAlert}
                  className="ml-5 mr-5 text-blue-500"
                />{" "}
                OR
                <Smartphone
                  onClick={onDisable}
                  className="ml-5 text-blue-500"
                />
              </div>
            </>
          ) : (page === RouteUrl.forgotPasswordPhone ||
              page === "otp" ||
              page === RouteUrl.resetPassword) &&
            page !== RouteUrl.signUp &&
            !isEmail &&
            !isSingUp &&
            page !== RouteUrl.forgotPasswordEmail ? (
            <>
              <AlertForgotPasswordPhonePage
                isback={page === "otp" ? true : false}
                onSubmit={(value) => setPage(value)}
              />
              {page === RouteUrl.resetPassword ? (
                <></>
              ) : (
                <p className=" text-center">
                  Not yet sign up FlipFlop account? {""}
                  <Link
                    to="#"
                    onClick={() => setPage("/")}
                    className="w-full text-blue-500"
                  >
                    Sign up
                  </Link>
                </p>
              )}
            </>
          ) : (page === RouteUrl.forgotPasswordEmail ||
              page === "otp" ||
              page === RouteUrl.resetPassword) &&
            page !== RouteUrl.signUp &&
            isEmail &&
            !isSingUp &&
            page !== RouteUrl.forgotPasswordPhone ? (
            <>
              <AlertForgotPasswordEmailPage
                isback={page === "otp" ? true : false}
                onSubmit={(value) => setPage(value)}
              />
              {page === RouteUrl.resetPassword ? (
                <></>
              ) : (
                <p className=" text-center">
                  Not yet sign up FlipFlop account? {""}
                  <Link
                    to="#"
                    onClick={() => setPage("/")}
                    className="w-full text-blue-500"
                  >
                    Sign up
                  </Link>
                </p>
              )}
            </>
          ) : page === RouteUrl.signUp || page === "otp" ? (
            <>
              <AlertSigUpPage
                isback={page === "otp" ? true : false}
                onSubmit={(value) => setPage(value)}
              />{" "}
              {page === "otp" ? (
                <></>
              ) : (
                <p className="mt-3 text-center">
                  Already have account? {""}
                  <Link
                    to="#"
                    onClick={() => setPage(RouteUrl.login)}
                    className="w-full text-blue-500"
                  >
                    Login
                  </Link>
                </p>
              )}
            </>
          ) : (
            <></>
          )}
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
