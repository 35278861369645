import { profileVerified } from "assets";
import { Play } from "lucide-react";
import ImageUrlProfile from "../image-url/ImageUrlProfile";
import { Link } from "react-router-dom";
import { RouteUrl } from "utils";

export default function ContentInfo({ post }: { post: any }) {
  return (
    <div className="absolute bottom-0 left-0 w-full">
      <div className="flex flex-col w-full font-sans text-white absolute bottom-0 left-0 p-2 z-20">
        <div className="absolute flex w-full h-full bottom-0 left-0 p-2 bg-opacity-20 z-10 bg-gradient-to-t from-black rounded-b-xl" />
        <Link
          to={`${RouteUrl.profile}?id=${post?.user?.id}&posts`}
          className="relative flex items-center gap-1 cursor-pointer z-50"
        >
          <ImageUrlProfile
            isLinkProfile={true}
            className="rounded-full object-cover border shadow-md shadow-gray-600"
            user={post?.user}
            style={{ width: 30, height: 30 }}
            alt="user"
          />
          <div className="flex flex-col items-start justify-center">
            <div className="flex">
              <p className="text-xs font-semibold line-clamp-1">
                {post?.user?.name}
              </p>
              {post?.user?.is_verified === "Yes" ? (
                <>
                  <div className="flex items-center justify-center pt-0.5 ml-1">
                    <img
                      src={profileVerified}
                      style={{ width: 12, height: 12 }}
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <p className="text-xs text-gray-100 line-clamp-1">
              {post?.user?.bio}
            </p>
          </div>
        </Link>

        <p className={`relative z-20 text-xs my-1 line-clamp-2`}>
          {post?.post_attachments[0]?.description}
        </p>

        <div className="flex justify-start z-20">
          {post?.re_post_user ? (
            <div className="flex justify-center items-center p-0.5 pr-2 rounded-xl bg-gray-600 mr-1 max-w-[150px]">
              <ImageUrlProfile
                isLinkProfile={true}
                className="rounded-full object-cover"
                user={post?.re_post_user}
                style={{ width: 17, height: 17 }}
                alt="user"
              />
              <p className="text-xs line-clamp-1 ml-1">
                {post?.re_post_user?.name}
              </p>
            </div>
          ) : (
            <></>
          )}

          <span className="flex items-center justify-center px-1.5 rounded-xl bg-gray-600 space-x-0.5">
            <Play size={10} /> <p className="text-xs">{post?.view_count}</p>
          </span>
        </div>
      </div>
      {/* <div className="w-full h-full flex flex-col justify-between text-white p-2">
        <p className="invisible">invisible text</p>
        <div className="flex">
          <div className="grow invisible">
            <p>invisible</p>
          </div>
          <div className="flex-none">
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <Heart />
                <p>{post?.reaction_like_count}</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
