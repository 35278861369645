import ImageUrlProfile from "components/app/image-url/ImageUrlProfile";

export default function TextRection({ post }: { post: any }) {
  return (
    <div className="flex flex-row items-center  justify-between w-full">
      <div className="flex ">
        {post?.like_users && post?.like_users.length > 0 ? (
          <>
            <div className="flex justify-center -space-x-3 rtl:space-x-reverse ">
              {post?.like_users.map((likeUser: any, indexFriUser: number) => {
                return (
                  <ImageUrlProfile
                    key={indexFriUser}
                    isLinkProfile={true}
                    user={likeUser}
                    className="w-6 h-6 border-2 border-white rounded-full dark:border-gray-800"
                    alt=""
                  />
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
        <p className="font-normal">{`${
          post?.reaction_like_count === 0 || post?.reaction_like_count === "0"
            ? ""
            : post?.reaction_like_count
        } ${
          post?.reaction_like_count === 0 || post?.reaction_like_count === "0"
            ? ""
            : post?.reaction_like_count > 1
            ? "Likes "
            : "Like "
        }${
          post?.comment_count === 0 || post?.comment_count === "0"
            ? ""
            : post?.comment_count
        } ${
          post?.comment_count === 0 || post?.comment_count === "0"
            ? ""
            : post?.comment_count > 1
            ? "Comments "
            : "Comment "
        }   ${
          post?.reaction_share_count === "0" || post?.reaction_share_count === 0
            ? ""
            : post?.reaction_share_count
        } ${
          post?.reaction_share_count === "0" || post?.reaction_share_count === 0
            ? ""
            : post?.reaction_share_count !== "1"
            ? "Shares "
            : "Share "
        }`}</p>
      </div>
      {post?.reach_users && post?.reach_users.length > 0 ? (
        <div className="flex justify-end items-end ">
          <div className="flex justify-center -space-x-4 rtl:space-x-reverse ">
            {post?.reach_users.map((viewUser: any, indexFriUser: number) => {
              return (
                <ImageUrlProfile
                  key={indexFriUser}
                  isLinkProfile={true}
                  user={viewUser}
                  className="w-6 h-6 border-2 border-white rounded-full dark:border-gray-800"
                  alt=""
                />
              );
            })}
          </div>
          <p className="font-normal ">
            {post?.reach_count > 1
              ? `${post?.reach_count} Views`
              : `${post?.reach_count} View`}
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
