import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "components/app/dialog/dialog";
import { QrCode } from "lucide-react";
import { QRCode } from "react-qrcode-logo";
import { cn } from "lib/utils";
import {
  backgroundMenu,
  logoMaxxBlack,
  logoMoowResturant,
  logoQrDefaultMenu,
  noImageAvialable,
} from "assets";

export default function QrCodeGeneraterMenu({
  className,
  linkUrl,
  userShop,
  logoIcon = null,
}: {
  className?: string;
  linkUrl: string;
  userShop?: any;
  logoIcon?: any;
}) {
  return (
    <Dialog>
      {/* ratio Qr: 1454 × 2048 */}
      <DialogTrigger className={cn(`cursor-pointer`, className)} asChild>
        {logoIcon === null ? <QrCode /> : logoIcon}
      </DialogTrigger>
      <DialogContent className="aspect-[1454/2048] flex flex-col overflow-hidden">
        <DialogTitle></DialogTitle>
        <div
          style={{ backgroundImage: `url("${backgroundMenu}")` }}
          className={`fixed top-0 left-0 bg-cover h-full w-full rounded-md`}
        />
        <div className="z-50 text-md italic">
          Member of
          <img
            className="h-[30px] w-auto filter grayscale contrast-200 brightness-0"
            src={logoMaxxBlack}
            alt="Logo of Maxx Black"
          />
        </div>
        <div className="text-center font-medium z-50">
          <h1 className="text-lg">QR Menu</h1>
          <p>Scan. Order. Done.</p>
        </div>

        <div className="relative flex justify-center items-center">
          <div className="relative inline-block">
            <QRCode
              value={linkUrl}
              size={200}
              ecLevel="Q"
              fgColor="#1D1615"
              bgColor="transparent"
              qrStyle="squares" // Makes the QR code dots rounded
              quietZone={10}
              eyeRadius={[
                [50, 50, 50, 50],
                [50, 50, 50, 50],
                [50, 50, 50, 50],
              ]}
            />
            <div className="absolute top-0 left-0 pointer-events-none">
              {/* Top-left corner */}
              <div className="w-[25px] h-[25px] border-t-2 border-l-2 border-[#1D1615] rounded-tl-2xl"></div>
            </div>
            <div className="absolute top-0 right-0 pointer-events-none">
              {/* Top-right corner */}
              <div className="w-[25px] h-[25px] border-t-2 border-r-2 border-[#1D1615] rounded-tr-2xl"></div>
            </div>
            <div className="absolute bottom-0 left-0 pointer-events-none">
              {/* Bottom-left corner */}
              <div className="w-[25px] h-[25px] border-b-2 border-l-2 border-[#1D1615] rounded-bl-2xl"></div>
            </div>
            <div className="absolute bottom-0 right-0 pointer-events-none">
              {/* Bottom-right corner */}
              <div className="w-[25px] h-[25px] border-b-2 border-r-2 border-[#1D1615] rounded-br-2xl"></div>
            </div>
          </div>
          {/* Centered Circular Logo */}
          <div
            className="absolute flex items-center justify-center"
            style={{
              width: 50,
              height: 50,
              borderRadius: "50%",
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            <img
              src={
                userShop?.picture === null
                  ? noImageAvialable
                  : userShop?.picture
              }
              alt="Logo"
              className="border p-0.5 rounded-full"
              style={{
                width: "100%", // Ensure image covers the circle
                height: "100%",
                objectFit: "cover", // Maintain aspect ratio
              }}
            />
          </div>
        </div>
        <div className="flex justify-center z-50">
          <p>{userShop?.shop_name}</p>
        </div>
        <div className="absolute -bottom-8 -right-8">
          <img
            className="w-[120px] h-[120px]"
            src={
              ["Club", "Dining Restaurant", "Lunch Restaurant"].includes(
                userShop?.shop_group
              )
                ? logoMoowResturant
                : logoQrDefaultMenu
            }
            alt=""
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
