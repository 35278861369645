export enum AppRecordStatus {
  loading, // Initial loading state
  loadingMore, // Loading more data (e.g., in infinite scroll)
  noLoadMore, // No more data to load
  loaded, // Data fully loaded
}

export interface AppRecord {
  loadingStatus: AppRecordStatus; // Current loading status
  currentPage: number; // Current page in pagination
  currentIndex: number; // Current index of loaded items
  nextPageUrl: string; // URL for the next page of data (for pagination)
  totalRecord: number; // Total number of records available
}
