import { deleteAccountDocument } from "assets";
import { Button } from "components/ui/button";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { House } from "lucide-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "store/authSlice";

export default function DisactivateAccountPageStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  const btnBackToHome = () => {
    navigate("/");
  };

  return (
    <SinglePageCenter>
      <div className="max-w-md xl:max-w-lg flex justify-center flex-col items-center text-center">
        <img src={deleteAccountDocument} alt="icon" />
        <p className="text-15px md:text-base 2xl:text-[18px] leading-6 md:leading-7 font-medium">
          Your account is currently being deactivated.
        </p>
        <p className="text-15px md:text-base 2xl:text-[18px] text-muted-foreground leading-6 md:leading-7 font-medium">
          During this process, you may lose access to certain features, and your
          data may be archived according to our policies. Your account will
          automatic activate when login into FlipFlop again in 30days.
        </p>
        <Button
          variant="outline"
          onClick={btnBackToHome}
          className="text-[13px] md:text-sm lg:text-[15px] leading-4 inline-flex items-center font-medium cursor-pointer transition ease-in-out duration-300 bg-brand-dark text-black px-4 md:px-6 py-2.5 lg:py-3 hover:text-black bg-opacity-90 hover:bg-opacity-100 rounded-md mt-2"
        >
          <div className="flex items-center">
            <House size={18} className="" />
            <span className="ps-2">Back to Home</span>
          </div>
        </Button>
      </div>
    </SinglePageCenter>
  );
}
