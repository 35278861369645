import {
  changePassword,
  checkSuccess,
  checkSuccessAnimation,
  deleteAccountDocument,
} from "assets";
import { Button } from "components/ui/button";
import SinglePageCenter from "layout/single-page-center/SinglePageCenter";
import { Check, House } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ChangePasswordPageStatus() {
  const navigate = useNavigate();

  const [showGif, setShowGif] = useState<boolean>(true);
  const gifDuration = 2150; // Duration of the GIF in milliseconds (adjust as needed)

  useEffect(() => {
    // Hide the GIF after it has played once
    const timer = setTimeout(() => {
      setShowGif(false);
    }, gifDuration);

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, []);

  const btnBackToHome = () => {
    navigate("/");
  };

  return (
    <div className="relative flex justify-center items-center h-screen">
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat bg-[#063240] -z-50 w-screen h-screen"
        style={{ backgroundImage: "url(path_to_your_image.jpg)" }}
      />
      <div className=" max-w-md xl:max-w-lg flex justify-center flex-col items-center text-center">
        {showGif ? (
          <img src={checkSuccessAnimation} alt="icon" />
        ) : (
          <img src={checkSuccess} alt="icon" />
        )}

        <p className="text-15px md:text-base 2xl:text-[18px] text-white leading-6 md:leading-7 font-medium">
          Password Already Changed!
        </p>
        <p className="text-15px md:text-base 2xl:text-[18px] text-white leading-6 md:leading-7 font-medium">
          The password for your account has already been updated. If you did not
          make this change, please contact our support team immediately to
          secure your account.
        </p>
        <Button
          variant="outline"
          onClick={btnBackToHome}
          className="text-[13px] md:text-sm lg:text-[15px] leading-4 inline-flex items-center font-medium cursor-pointer transition ease-in-out duration-300 bg-brand-dark  text-white px-4 md:px-6 py-2.5 lg:py-3 hover:text-black bg-opacity-90 hover:bg-opacity-100 rounded-md mt-2"
        >
          <div className="flex items-center">
            <House size={18} className="" />
            <span className="ps-2">Back to Home</span>
          </div>
        </Button>
      </div>
    </div>
  );
}
