import { StatusMsg } from "../models/returnMsg"; // Adjust path if needed
import { toast } from "react-toastify";

export const Snackbar = ({
  message = "Information",
  status = StatusMsg.info, // Default status is "info"
}: {
  message: string;
  status?: StatusMsg;
}) => {
  // Use .value to map the StatusMsg to the appropriate toast and console color
  const statusToToastMap: Record<
    string, // Use string as the key type
    { toastFunc: (msg: string, options?: any) => void; consoleColor: string }
  > = {
    [StatusMsg.info.value]: { toastFunc: toast.info, consoleColor: "\x1b[34m" }, // Blue for info
    [StatusMsg.success.value]: {
      toastFunc: toast.success,
      consoleColor: "\x1b[32m",
    }, // Green for success
    [StatusMsg.warning.value]: {
      toastFunc: toast.warn,
      consoleColor: "\x1b[33m",
    }, // Yellow for warning
    [StatusMsg.error.value]: {
      toastFunc: toast.error,
      consoleColor: "\x1b[31m",
    }, // Red for error
    [StatusMsg.failed.value]: {
      toastFunc: toast.error,
      consoleColor: "\x1b[31m",
    }, // Red for failed
  };

  const { toastFunc, consoleColor } = statusToToastMap[status.value] || {
    toastFunc: toast,
    consoleColor: "\x1b[0m",
  };

  // Display the toast notification
  toastFunc(message, {
    className:
      status.value === StatusMsg.success.value
        ? "success"
        : status.value === StatusMsg.error.value
        ? "danger"
        : "",
  });

  // Log the message with the appropriate color
  console.log(
    `${consoleColor} status: ${status.value}, message: ${message} \x1b[0m`
  );
};
