import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { DialogOverlay } from "../dialog/dialog";
import { X } from "lucide-react";
import ImageUrlProfile from "../image-url/ImageUrlProfile";
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";

interface DialogShareProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  content: any;
  user: any;
  onShare: () => void;
  onChange: (value: string) => void;
}

const DialogShare: React.FC<DialogShareProps> = ({
  isOpen,
  onOpenChange,
  content,
  user,
  onShare,
  onChange,
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogOverlay />
      <Dialog.Content className="fixed z-50 left-[50%] top-[50%]  grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-2 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg">
        <Dialog.Title className="text-2xl font-medium  text-center">
          Share
        </Dialog.Title>
        <Dialog.Close className="absolute top-2 right-2" asChild>
          <X className="size-8" />
        </Dialog.Close>
        <hr />
        <Dialog.Description className=""></Dialog.Description>
        <div className="flex flex-col items-end justify-end pl-2 pr-2 space-y-4">
          <div className="flex flex-row items-start w-full space-x-2">
            <ImageUrlProfile
              isLinkProfile={false}
              user={user}
              width={50}
              height={50}
              className=" border-2 border-white rounded-full dark:border-gray-800"
              alt=""
            />
            <p className="font-medium text-xl">{user?.name ?? ""}</p>
          </div>
          <div className="w-full">
            <Textarea
              onChange={(event) => onChange(event.target.value)}
              placeholder="Write somthing here"
              className="border border-input  focus-visible:ring-0 focus-visible:ring-offset-0"
            />
          </div>
          <Button className=" right-2  text-xl font-normal" onClick={onShare}>
            Share now
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default DialogShare;
