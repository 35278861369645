import imageCompression from "browser-image-compression";

const defaultOptions = {
  maxSizeMB: 500,
  maxWidthOrHeight: 1920,
};

export function compressFileWithURL(
  dataUrl: any,
  filename: any,
  lastModified: any,
  options = defaultOptions
) {
  return imageCompression.getFilefromDataUrl(dataUrl, filename, lastModified);
}
