import React, { useState, useRef, useEffect } from "react";
import Linkify from "react-linkify";

interface LinkifyTextProps {
  text: string;
}

const LinkifyText: React.FC<LinkifyTextProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to handle newline replacement with <br />
  const formatTextWithNewLines = (text: string) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        // Check if the scrollHeight (total content height) is greater than the clientHeight (visible area)
        const isOverflowing =
          textRef.current.scrollHeight > textRef.current.clientHeight;
        setIsTextOverflowing(isOverflowing);
      }
    };

    checkTextOverflow(); // Check on mount

    // Optional: check again if the window is resized (to handle responsiveness)
    window.addEventListener("resize", checkTextOverflow);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", checkTextOverflow);
    };
  }, [text]);
  const customDecorator = (href: string) => {
    let className = "text-blue-500 underline"; // Default link style for URLs
    let text = href;
    // Determine if it's an email, phone number, or hashtag based on the href
    if (href.startsWith("mailto:")) {
      text = href.replace("mailto:", ""); // Remove 'mailto:' prefix from email
    }

    return (
      <a href={href} className={className}>
        {text}
      </a>
    );
  };
  return (
    <div className=" inline-flex self-start w-auto">
      <span className="text-gray-800">
        <Linkify componentDecorator={customDecorator}>
          <div
            ref={textRef}
            className={`${isExpanded ? "" : "overflow-hidden"}`}
            style={{
              whiteSpace: "pre-wrap", // Preserve line breaks
              maxHeight: isExpanded ? "none" : "calc(5 * 1.2em)", // Limit to 5 lines height
              transition: "max-height 0.3s ease-in-out", // Smooth transition for expansion
            }}
          >
            {/* Format the text with newlines replaced by <br /> */}
            {formatTextWithNewLines(text)}
          </div>
        </Linkify>

        {/* Show "Show More" button only if the text exceeds 5 lines */}
        {isTextOverflowing && !isExpanded && (
          <button
            onClick={toggleExpand}
            className="ml-2 text-blue-500 underline"
          >
            Show More
          </button>
        )}

        {/* Show "Show Less" button if text is expanded */}
        {isTextOverflowing && isExpanded && (
          <button
            onClick={toggleExpand}
            className="ml-2 text-blue-500 underline"
          >
            Show Less
          </button>
        )}
      </span>
    </div>
  );
};

export default LinkifyText;
